<template>
    <base-dialog
        v-model="isDialogOpen"
        :should-dismiss-on-save="false"
        :should-show-overlay="isEditing"
        :on-cancel="close"
        :min-width="900"
        :max-width="900"
        :on-close="close">
        <template #title>
            <div class="font-weight-light text-h4 pb-6">
                {{ isEditing ? $t('copilot.editAgent') : $t('copilot.createAgent') }}
            </div>
        </template>
        <template #body>
            <component
                :is="currentStep.component"
                ref="currentStepComponent"
                :agent-id="agentId"
                :is-editing="isEditing"
                @update:isValid="updateIsValid"
                @update:actionLabel="updateActionLabel" />
        </template>
        <template #actions="{ cancel }">
            <v-switch
                v-if="currentStep.name === 'guide'"
                v-model="markGuideAsRead"
                :label="$t('dontShowAgain')"
                color="primary"
                class="ma-0 pa-0"
                hide-details
                small />
            <v-spacer />

            <v-btn
                v-if="isFirstStep"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                v-else-if="!isLastStep"
                text
                rounded
                class="cancel"
                @click="previousStep">
                <span class="text-none">{{ $t('back') }} </span>
            </v-btn>

            <v-btn
                v-if="isLastStep"
                color="primary"
                class="save"
                style="min-width: 100px;"
                :loading="isSaving"
                depressed
                rounded
                @click="cancel">
                <span class="text-none"> {{ $t('ok') }}</span>
            </v-btn>
            <v-btn
                v-else
                color="primary"
                class="save"
                style="min-width: 100px;"
                :loading="isLoading"
                :disabled="!canContinue"
                depressed
                rounded
                @click="nextStep">
                <span class="text-none">{{ $t(actionLabel) }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { to } from 'await-to-js'
    import { rebuildAgentContext } from 'services/copilot.js'
    import CopilotAgentModalGuide from 'components/copilot/AgentModalGuide.vue'
    import CopilotAgentModalDetails from 'components/copilot/AgentModalDetails.vue'
    import CopilotAgentModalTables from 'components/copilot/AgentModalTables.vue'
    import CopilotAgentModalTablesContext from 'components/copilot/AgentModalTablesContext.vue'
    import CopilotAgentModalInfo from 'components/copilot/AgentModalInfo.vue'

    export default {
        name: 'CopilotAgentModal',
        components: {
            CopilotAgentModalGuide,
            CopilotAgentModalDetails,
            CopilotAgentModalTables,
            CopilotAgentModalTablesContext,
            CopilotAgentModalInfo,
        },
        data() {
            return {
                isDialogOpen: false,
                step: 1,
                steps: [
                    {
                        name: 'guide',
                        component: 'CopilotAgentModalGuide',
                        enabled: true,
                    },
                    {
                        name: 'details',
                        component: 'CopilotAgentModalDetails',
                        enabled: true,
                    },
                    {
                        name: 'tables',
                        component: 'CopilotAgentModalTables',
                        enabled: true,
                    },
                    {
                        name: 'context',
                        component: 'CopilotAgentModalTablesContext',
                        enabled: true,
                    },
                    {
                        name: 'info',
                        component: 'CopilotAgentModalInfo',
                        enabled: true,
                    }
                ],
                stepsData: {},
                isEditing: false,
                agentId: null,
                markGuideAsRead: false,
                isLoading: false,
                isSaving: false,
                canContinue: false,
                actionLabel: 'next',
            }
        },
        computed: {
            ...mapGetters([
                'selectedDatasource',
                'selectedAgent'
            ]),
            enabledSteps() {
                return this.steps.filter(step => step.enabled)
            },
            isFirstStep() {
                return this.step === 1
            },
            isLastStep() {
                return this.step === this.enabledSteps.length
            },
            currentStep() {
                return this.enabledSteps[this.step - 1]
            },
        },
        methods: {
            ...mapActions([
                'selectAgent',
                'selectDatasource',
                'fetchDatasource',
                'refreshAgents',
                'startPollingAgentsIndexStatus'
            ]),
            updateIsValid(isValid) {
                this.canContinue = isValid
            },
            updateActionLabel(label) {
                this.actionLabel = label
            },
            async nextStep() {
                this.isLoading = true
                if (this.$refs?.currentStepComponent?.save) {
                    const savedValue = await this.$refs.currentStepComponent.save()
                    if (savedValue) {
                        if (savedValue.length) {
                            this.stepsData[this.currentStep.name] = savedValue
                            const [, savedAgentId] = this.stepsData['details'] || []
                            if (savedAgentId) {
                                this.agentId = savedAgentId
                            }
                        }
                        this.step++
                    }
                    const hasSavedValues = Object.values(this.stepsData).some(value => value.length)
                    if (this.isLastStep) {
                        if (hasSavedValues) {
                            this.syncContext()
                        } else {
                            this.close()
                        }
                    }
                } else {
                    this.step++
                }
                this.isLoading = false
            },
            previousStep() {
                if (this.$refs?.currentStepComponent?.back) {
                    const canGoBack = this.$refs.currentStepComponent.back()
                    if (canGoBack) {
                        this.step--
                    }
                } else {
                    this.step--
                }
            },
            open(agentId, stepName) {
                this.agentId = agentId ? agentId : null
                this.stepsData = {}
                if (agentId) {
                    this.isEditing = true
                    this.steps[0].enabled = false
                } else {
                    this.isEditing = false
                    if (localStorage.getItem('copilot-create-agent-guide-read') !== 'yes') {
                        this.steps[0].enabled = true
                    }
                }
                if (stepName) {
                    this.step = this.enabledSteps.findIndex(step => step.name === stepName) + 1 || 1
                } else {
                    this.step = 1
                }
                this.isDialogOpen = true
            },
            async syncContext() {
                await to(rebuildAgentContext(this.agentId))
                await this.refreshAgents()
                this.startPollingAgentsIndexStatus()
            },
            async close() {
                const hasSavedValues = Object.values(this.stepsData).some(value => value.length)
                const [datasourceId, agentId] = this.stepsData['details'] || []
                if (datasourceId && datasourceId !== this.selectedDatasource?.id) {
                    await this.selectDatasource(datasourceId)
                }
                if (agentId && agentId !== this.selectedAgent?.id) {
                    this.selectAgent(agentId)
                }
                if (hasSavedValues && !this.isLastStep) {
                    this.syncContext()
                }
                this.isDialogOpen = false
            },
            acknowledge() {
                if (this.markGuideAsRead) {
                    localStorage.setItem('copilot-create-agent-guide-read', 'yes')
                } else {
                    localStorage.removeItem('copilot-create-agent-guide-read')
                }
            },
        },
    }
</script>
