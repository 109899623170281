<style lang="scss">
@import "styles/main";

.old-portal-margin{
    .main-nav{
        margin-top: 120px;
        height: calc(100vh - 120px)!important;
    }

    .v-main {
        padding-top: 130px!important
    }
}

.no-banner-margin{
    .main-nav{
        margin-top: 64px;
        height: calc(100vh - 64px)!important;
    }
}

.text-body-1 {
    color: #000;
    font-family: "Azo Sans", sans-serif;
    line-height: 1.5;
}

.impersonation-mode {
    .launch-button::after,
    .v-btn.v-btn--rounded.primary::after {
        content: '!';
        color: $error;
        font-size: 1.5rem;
        font-weight: bold;
        box-shadow: none;
    }
}
</style>

<template>
    <v-app
        class="no-banner-margin"
        :class="{ 'impersonation-mode': impersonatedUser }">
        <div
            v-if="!isPageLoading && authorized"
            id="appHeaderWrap">
            <app-header
                :user="user"
                :tier="currentTier"
                :impersonated-user="impersonatedUser"
                @logoutUser="logoutUser" />
            <AnnouncementBanner
                v-if="isShowAnnouncementBanner"
                :message="$t('announcementBanner')"
                :color="'warning'" />
            <FreeTrialBanner
                v-if="isFreeTrialActive"
                :credit-left="freeCreditLeft"
                :scu-hours-left="freeSCUHoursLeft" />
            <navigation />
            <snackbars />
        </div>
        <app-header v-else-if="isAuthOError">
            <template #right>
                <span></span>
            </template>
        </app-header>
        <overlay />

        <v-main>
            <v-container
                :class="['fill-height py-0', { 'pa-0 ma-0': !authorized }]"
                fluid>
                <v-row
                    v-if="!isPageLoading && authorized"
                    class="fill-height">
                    <router-view />
                    <v-dialog
                        v-model="isDialogOpen"
                        persistent
                        max-width="700px"
                        scrollable>
                        <v-card>
                            <v-card-title class="pt-9 pb-5 px-9">
                                <span
                                    class="text-h4 font-weight-medium">
                                    {{ 'Error' }}</span>
                            </v-card-title>
                            <v-card-text class="px-6 text-center">
                                <span
                                    class="text-body-1 red--text">
                                    {{ orgErrorMessage }}</span>
                                <a
                                    rel="noopener noreferrer"
                                    class="text-body-1 text-decoration-none"
                                    target="_blank"
                                    href="https://support.skysql.com/support/home">{{ ' here.' }}</a>
                            </v-card-text>
                            <v-divider />
                            <v-card-actions class="py-4 px-9">
                                <v-spacer />
                                <v-btn
                                    class="text-none"
                                    color="primary"
                                    rounded
                                    outlined
                                    :ripple="false"
                                    @click="logoutUser">
                                    {{ 'Log Out' }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
                <v-row v-else>
                    <Initializing
                        v-if="redirectSSO"
                        :msg="$t('ssoRedirect')" />
                    <Initializing v-else-if="isFetchingToken" />
                    <Unauthorized v-else-if="!authorized" />
                    <v-col v-else-if="isAuthOError">
                        <div class="text-subtitle-1 text-center mt-3">
                            <img
                                src="@/assets/logo-symbol.avif"
                                height="160"
                                style="margin-top: -20px;"
                                alt="SkySQL Logo" />
                            <div>{{ authOErrorMsg }}</div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <app-footer />
    </v-app>
</template>

<script>
    import 'overlayscrollbars/css/OverlayScrollbars.css'
    import { mapGetters, mapActions } from 'vuex'
    import Layouts from 'layouts'
    import Overlay from 'components/overlay/Index'
    import Unauthorized from 'pages/Unauthorized.vue'
    import Initializing from 'components/messages/Initializing.vue'
    import FreeTrialBanner from 'layouts/FreeTrialBanner.vue'
    import AnnouncementBanner from 'layouts/AnnouncementBanner.vue'
    import { setupEventSource } from 'services/serverSentEvents'

    const redirectURI = `${window.location.origin}/login/oauth2/code/grant`

    export default {
        name: 'Stardust',
        components: {
            ...Layouts,
            overlay: Overlay,
            Unauthorized,
            Initializing,
            FreeTrialBanner,
            AnnouncementBanner,
        },
        data(){
            return {
                isPageLoading: true,
                isAuthOError: false,
                authOErrorMsg: '',
                isDialogOpen: false,
                isShowAnnouncementBanner: false,
            }
        },
        computed: {
            ...mapGetters([
                'authToken',
                'user',
                'userData',
                'currentTier',
                'isFetchingToken',
                'redirectSSO',
                'authorized',
                'orgData',
                'impersonatedUser',
                'orgError',
                'isFreeTrialActive',
                'freeCreditLeft',
                'freeSCUHoursLeft'
            ]),
            orgErrorMessage() {
                switch(this.orgError?.type) {
                    case 'ORG_NOT_FOUND':
                        return this.$t('errors.orgNotFound')
                    case 'ORG_DELETED':
                        return this.$t('errors.orgDeleted')
                    case 'ORG_INACTIVE':
                        return this.$t('errors.orgInactive')
                    default: return ''
                }
            },
        },
        watch: {
            authorized(isAuth){
                if (!isAuth) {
                    this.stopServicesInterval()
                    this.stopRefreshingAccessToken()
                    this.logoutUser()
                }
            },
            orgError(error) {
                if (error?.type) {
                    this.isDialogOpen = true
                    this.stopServicesInterval()
                    this.stopRefreshingAccessToken()
                }
            },
        },
        methods: {
            ...mapActions([
                'startServicesInterval',
                'stopServicesInterval',
                'getRefreshToken',
                'refreshAccessTokenRecursively',
                'stopRefreshingAccessToken',
                'getToken',
                'fetchOrgDetails',
                'setUser',
                'logoutUser',
                'ssoRedirect',
                'fetchServiceTypes',
                'fetchTopologyDetails',
                'getInstanceSizes',
                'getMaxScaleInstanceSizes',
                'fetchAvailabilityZones',
                'fetchBillingRecord',
                'fetchOfferings'
            ]),
            shouldRedirectForAuth(code, state) {
                return (!code || !state) && !this.authToken
            },
            initAppDependencies(){
                let dependencies = []
                // Prefetching Launch flow deps to speed up
                // the launch flow
                // This hook can be used to pre fetch other
                // services and cache
                if (this.$unleash.isFeatureEnabled('enable-portal-single-zone')) dependencies.push(this.fetchAvailabilityZones())
                dependencies.push(this.fetchOfferings())
                dependencies.push(this.fetchBillingRecord())
                dependencies.push(this.fetchServiceTypes())
                dependencies.push(this.fetchTopologyDetails())
                dependencies.push(this.getInstanceSizes())

                dependencies.push(this.getMaxScaleInstanceSizes(
                    { type: this.$config.INSTANCE_SIZE_TYPE.PROXY,}
                ))

                Promise.all(dependencies).then(() => {
                    // adjust main content height as per presence of alerts and notifications
                    setTimeout(() => {
                        let otherBannersHeight = 0
                        const banners = document.querySelectorAll('.v-alert')
                        banners?.forEach(banner => {
                            otherBannersHeight += banner.clientHeight
                        })
                        if (otherBannersHeight > 0) {
                            const mainEl = document.querySelector('.v-application--wrap .v-main')
                            if (mainEl) mainEl.style.maxHeight = `calc(100% - ${otherBannersHeight}px)`
                        }
                    }, 100)
                })
                if (this.$unleash.isFeatureEnabled('enable-portal-sse-for-service-progress')) this.connectSSE()
            },
            connectSSE() {
                setupEventSource(this.authToken)
            },
            async setupUnleash(){
                await this.$unleash.start()

                this.isPageLoading = false

                this.$unleash.on('update', () => {})

                // fallback when error returned from Unleash server
                // will rely on bootstrap or last fetched feature flags
                this.$unleash.on('error', () => {})
            },
            checkForTierUpgrade(){
                const isTierUpgrade = localStorage.getItem('tierUpgrade')
                if (isTierUpgrade === 'true') {
                    this.$store.commit('showMessage', { text: this.$t('success.tierUpgraded'), type: this.$config.messageType.SUCCESS, })
                }
                localStorage.removeItem('tierUpgrade')
            },
        },
        mounted() {
            document.title = this.$config.productName
        },
        async created() {
            window.appVersion = `v${process.env.VUE_APP_VERSION}`
            // eslint-disable-next-line no-console
            console.info(window.appVersion)

            if (this.$unleash.isFeatureEnabled('enable-portal-sentry-monitoring') && !this.$help.isDevEnv()) {
                this.$sentry.init()
            }
            const { code, state, error, error_description: errorDescription,} = this.$route.query
            if (error) {
                this.authOErrorMsg = errorDescription ? errorDescription : this.$t('errors.unknown')
                this.isAuthOError = true
                this.$router.push('/login/auth/dispatcher')
                return
            }

            if (code && state) {
                // If code and state are available
                // it means it has been redirected from ID server
                // using them get the token and proceed
                const tokenOptions = {}
                if (this.$route.name === 'auth-impersonation') {
                    tokenOptions.app = 'impersonation'
                } else if (this.$help.isDevEnv()) {
                    tokenOptions.app = 'localhost'
                }
                await this.getToken({
                    code,
                    state,
                    options: tokenOptions,
                })

                // If able to get auth token
                // the user is marked authorized, allow
                // user to check portal
                if (this.authorized) {
                    this.$router.push('/')
                } else {
                    // If the we're not able to get token
                    // from the state and then some issue from GW
                    // need to redirect to unauthorized view
                    this.authOErrorMsg = this.$t('errors.unauthorized')
                    this.isAuthOError = true
                    this.$router.push('/login/auth/dispatcher')
                    return
                }
            } else {
                // When a user hits portal url a regular
                // refreshToken call will be made to
                // update authToken into store as its in
                // browser memory
                // Also, In case a regular refresh done by user
                // just make a call to get new auth token
                await this.getRefreshToken()
            }

            // When a user hits the portal url first time no authToken present.
            // Also no redirection happened from ID server so no code and state
            // In such scenario redirect the user to id server
            if (this.shouldRedirectForAuth(code, state)) {
                this.ssoRedirect(true)
                const params = new URLSearchParams({
                    /* eslint-disable camelcase */
                    response_type: 'code',
                    state: this.$help.getRandomString(18),
                    client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
                    scope: 'email profile openid offline_access',
                    nonce: this.$help.getRandomString(32),
                    redirect_uri: redirectURI,
                    /* eslint-enable */
                })
                window.location.href = `${process.env.VUE_APP_ID_URL}?${params}`
                return
            }

            if (this.authToken) {
                this.setupUnleash()
                this.checkForTierUpgrade()
                this.initAppDependencies()
                this.startServicesInterval(this.$config.fetchServicesInterval)
                this.refreshAccessTokenRecursively(this.$config.refreshTokenInterval)
                this.$sentry.setUser({ email: this.user.email, id: this.user.id, name: this.user.name, })
            }
        },
        destroy () {
            this.stopServicesInterval()
            this.stopRefreshingAccessToken()
            this.$unleash.stop()
        },
    }
</script>
