<style lang="scss" scoped>
    .selection-entity-box {
        width: 250px;
        flex-shrink: 0;
        align-self: flex-start;
    }

    .compare-wrap {
        padding-left: 12px;
        padding-right: 12px;
        gap: 12px;
    }

    .computed-wrapper {
        background-color: $estimate-calculations;
        padding-left: 12px;
        padding-right: 12px;
    }
</style>

<template>
    <v-row justify="center">
        <v-dialog
            v-model="isDialogOpen"
            persistent
            :max-width="isInstant ? '1000px' : '1200px'"
            scrollable>
            <v-card>
                <v-card-title class="pt-7 px-10">
                    <span class="text-h4 font-weight-medium">
                        {{ isInstant ? $t('backups.createNewInstantBackup') : $t('backups.createNewSchedule') }}
                    </span>
                </v-card-title>
                <v-card-text class="overflow-visible">
                    <v-container style="min-height: 200px;">
                        <div class="d-flex">
                            <v-row>
                                <v-col cols="6">
                                    <div>
                                        <label class="text-uppercase required">
                                            {{ $tc('service') }}
                                        </label>
                                        <div>
                                            <v-select
                                                v-model="selectedServiceId"
                                                :items="serviceItems"
                                                class="std std--dropdown"
                                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list', maxHeight: '200px' }"
                                                height="36"
                                                outlined
                                                item-value="id"
                                                attach
                                                required
                                                hide-details>
                                                <template #item="{ item }">
                                                    {{ item.name }}
                                                </template>
                                                <template #selection="{ item }">
                                                    {{ item.name }}
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="6">
                                    <div>
                                        <label class="text-uppercase">
                                            {{ $t('backups.type') }}
                                        </label>
                                        <div>
                                            <v-select
                                                v-model="selectedBackupType"
                                                :items="filteredBackupTypes"
                                                class="std std--dropdown"
                                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list', maxHeight: '200px' }"
                                                height="36"
                                                outlined
                                                item-value="key"
                                                attach
                                                required
                                                hide-details>
                                                <template #item="{ item }">
                                                    {{ item.value }}
                                                </template>
                                                <template #selection="{ item }">
                                                    {{ item.value }}
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col
                                    v-if="!isInstant"
                                    cols="6">
                                    <label class="text-uppercase">
                                        {{ $t('frequency') }}
                                    </label>
                                    <v-radio-group
                                        v-model="frequency"
                                        dense
                                        hide-details
                                        row
                                        class="mt-0">
                                        <v-radio
                                            :label="$t('once')"
                                            value="once" />
                                        <v-radio
                                            :label="$t('cron')"
                                            value="cron" />
                                    </v-radio-group>
                                </v-col>
                                <template v-if="frequency === 'cron'">
                                    <v-col
                                        cols="6"
                                        class="pb-0 pt-4">
                                        <v-text-field
                                            :value="value"
                                            :label="$t('cronValue')"
                                            :error-messages="cronError"
                                            placeholder="* * * * *"
                                            outlined
                                            dense
                                            @input="debounceCronInput" />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="pt-0">
                                        <cronLight
                                            v-model="value"
                                            @error="cronError=$event" />
                                    </v-col>
                                </template>
                                <v-col
                                    cols="7"
                                    class="pb-0">
                                    <label class="text-uppercase">
                                        {{ $t('storage') }}
                                    </label>
                                    <v-radio-group
                                        v-model="storage"
                                        :disabled="!canChangeStorage"
                                        row
                                        dense
                                        hide-details
                                        class="mt-0">
                                        <v-radio
                                            label="Managed"
                                            value="managed" />
                                        <v-radio
                                            label="External"
                                            value="external" />
                                    </v-radio-group>
                                </v-col>
                                <v-col
                                    v-if="storage === 'external'"
                                    cols="5"
                                    class="px-0 pb-0">
                                    <label class="text-uppercase">
                                        {{ $t('launchService.cloudProvider') }}
                                    </label>
                                    <v-radio-group
                                        v-model="provider"
                                        row
                                        class="mt-0">
                                        <v-radio
                                            label="AWS"
                                            value="aws" />
                                        <v-radio
                                            label="GCP"
                                            value="gcp" />
                                    </v-radio-group>
                                </v-col>
                                <template v-if="storage === 'external'">
                                    <v-col
                                        cols="12"
                                        class="py-0">
                                        <label class="required text-uppercase">
                                            {{ $t('backups.storagePath') }}
                                        </label>
                                        <v-text-field
                                            v-model="path"
                                            :placeholder="provider === 'aws' ? 's3://my_backup_bucket' : 'gs://my_backup_bucket'"
                                            outlined
                                            dense />
                                    </v-col>
                                    <template v-if="provider === 'aws'">
                                        <v-col
                                            cols="7"
                                            class="py-0">
                                            <label class="required text-uppercase">
                                                {{ $t('backups.accessKeyId') }}
                                            </label>
                                            <v-text-field
                                                v-model="accessKeyId"
                                                :placeholder="$t('backups.accessKeyId')"
                                                outlined
                                                dense />
                                        </v-col>
                                        <v-col
                                            cols="5"
                                            class="pt-0">
                                            <label class="required text-uppercase">
                                                {{ $tc('region') }}
                                            </label>
                                            <div>
                                                <v-select
                                                    v-model="selectedRegion"
                                                    :items="regionList"
                                                    class="std std--dropdown"
                                                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list', maxHeight: '200px' }"
                                                    height="40"
                                                    outlined
                                                    attach
                                                    required
                                                    hide-details>
                                                    <template #item="{ item }">
                                                        {{ item }}
                                                    </template>
                                                    <template #selection="{ item }">
                                                        {{ item }}
                                                    </template>
                                                </v-select>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="py-0">
                                            <label class="required text-uppercase">
                                                {{ $t('backups.accessKey') }}
                                            </label>
                                            <v-text-field
                                                v-model="accessKey"
                                                :placeholder="$t('backups.accessKey')"
                                                outlined
                                                dense />
                                        </v-col>
                                    </template>
                                    <template v-else>
                                        <v-col
                                            cols="12"
                                            class="py-0">
                                            <label class="required text-uppercase">
                                                {{ `${$t('backups.serviceAccountKey')} (JSON)` }}
                                            </label>
                                            <v-textarea
                                                v-model="serviceAccountKey"
                                                :placeholder="'{\n  &quot;type&quot;: &quot;service_account&quot;,\n  ...\n}'"
                                                outlined
                                                dense />
                                        </v-col>
                                    </template>
                                </template>
                            </v-row>
                            <v-card class="selection-entity-box pt-4 ml-7">
                                <v-row class="pa-0">
                                    <v-col class="px-6 mb-4 font-weight-bold">
                                        {{ $t('backups.backupWarning') }}
                                        <HelpToolTip
                                            :size="16">
                                            <template #message>
                                                <p>{{ $t('backups.backupChargesInfo') }}</p>
                                            </template>
                                        </HelpToolTip>
                                    </v-col>
                                </v-row>
                                <div
                                    v-if="backupCostLoading"
                                    class="d-flex justify-center">
                                    <v-progress-circular
                                        indeterminate
                                        size="22" />
                                </div>
                                <div
                                    v-else
                                    class="compare-wrap d-flex flex-column">
                                    <div
                                        v-for="item in backupCostItems"
                                        :key="item.label"
                                        class="d-flex justify-space-between">
                                        <span>{{ item.label }}</span>
                                        <span>{{ item.rate }}</span>
                                    </div>
                                </div>
                                <div class="mt-4 pt-6 computed-wrapper text-caption"></div>
                            </v-card>
                        </div>
                    </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions class="px-9 py-4">
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="onClose">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        class="text-none"
                        color="primary"
                        style="min-width: 100px;"
                        rounded
                        depressed
                        :disabled="!isFormValid"
                        :loading="isLoading"
                        @click="onSubmit">
                        {{ isInstant ? $t('create') : $t('schedule') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import to from 'await-to-js'
    import { debounce } from 'lodash'
    import { mapGetters, mapActions } from 'vuex'
    import HelpToolTip from 'components/common/HelpTooltip.vue'
    import { isServerlessTopology } from 'utils/service'
    import { getPrices } from 'services/billings'
    import i18n from 'plugins/i18n'

    export default {
        name: 'BackupScheduleModal',
        components: { HelpToolTip, },
        props: {
            showDialog: {
                type: Boolean,
                required: true,
            },
            isLoading: {
                type: Boolean,
            },
            isInstant: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                isDialogOpen: false,
                backupTypes: [],
                selectedBackupType: 'full',
                selectedServiceId: '',
                serviceItems: [],
                frequency: 'once',
                storage: 'managed',
                provider: 'aws',
                path: '',
                accessKeyId: '',
                accessKey: '',
                selectedRegion: '',
                serviceAccountKey: '',
                value: '0 0 * * *',
                cronError: '',
                backupCostLoading: false,
                backupCostItems: [],
                backupCosting: [],
            }
        },
        computed: {
            ...mapGetters(['regions', 'services', 'currentTier', 'offerings']),
            regionList() {
                return this.regions?.filter(region => region?.provider?.toLowerCase() === 'aws')?.map(region => region?.name)
            },
            isFormValid() {
                return this.selectedServiceId !== '' &&
                    this.cronError.length <= 0 &&
                    this.isExternalStorageDetailsProvided
            },
            isExternalStorageDetailsProvided() {
                if (this.storage === 'managed') return true
                else {
                    if (this.provider === 'aws')
                        return this.path !== '' && this.accessKey !== '' && this.accessKeyId !== '' && this.selectedRegion !== ''
                    else
                        return this.path !== '' && this.serviceAccountKey !== ''
                }
            },
            canChangeStorage() {
                return this.selectedBackupType !== 'snapshot'
            },
            filteredBackupTypes() {
                if (this.isServerless || !this.isVersionValidForAllBackupTypes) {
                    return this.backupTypes.filter(type => type.key === 'snapshot')
                } else {
                    return this.backupTypes
                }
            },
            selectedService() {
                return this.selectedServiceId?.length ? this.services?.find(service => service.id === this.selectedServiceId) : null
            },
            isServerless() {
                const serviceTopology = this.selectedService?.topology
                return isServerlessTopology(serviceTopology)
            },
            isVersionValidForAllBackupTypes() {
                if (!this.selectedServiceId?.length) return true
                const serviceVersion = this.selectedService?.version
                return parseFloat(serviceVersion) < 11.5
            },
        },
        watch: {
            showDialog() {
                this.isDialogOpen = this.showDialog
                if (this.isDialogOpen) {
                    this.loadData()
                }
            },
            selectedServiceId(newVal) {
                const service = this.serviceItems.find((service) => service.id === newVal)
                if (this.isServerless) {
                    this.selectedBackupType = 'snapshot'
                }
                if (service && this.selectedBackupType === 'snapshot') {
                    this.provider = service.provider
                }
                this.getBackupCosts()
            },
            selectedBackupType(newVal) {
                if (newVal === 'snapshot') {
                    this.storage = 'managed'
                }
            },
            frequency() {
                this.prepareBackupCost()
            },
            storage() {
                this.prepareBackupCost()
            },
        },
        methods: {
            ...mapActions(['getRegions', 'fetchServices', 'fetchOfferings']),
            onSubmit() {
                this.$emit('on-schedule-submit', {
                    backupType: this.selectedBackupType,
                    serviceId: this.selectedServiceId,
                    schedule: this.frequency === 'once' ? this.frequency : this.value,
                    ...(this.storage === 'external' && { externalStorage: this.getExternalStorage(), }),
                })
            },
            onClose() {
                this.$emit('on-close')
            },
            debounceCronInput: debounce(function (value) {
                this.value = value.trim()
            }, 500),
            getExternalStorageCredentials() {
                switch(this.provider) {
                    case 'aws':
                        // New lines are needed
                        return btoa(
                            `[default]
                            aws_access_key_id = ${this.accessKeyId}
                            aws_secret_access_key = ${this.accessKey}
                            region = ${this.selectedRegion}`
                        )
                    case 'gcp':
                        return btoa(this.serviceAccountKey)
                    default:
                        return ''
                }
            },
            getExternalStorage () {
                return {
                    bucket: {
                        path: this.path,
                        credentials: this.getExternalStorageCredentials(),
                    },
                }
            },
            mapServices (services = []) {
                const validStatuses = ['ready', 'stopped', 'pendingScale', 'pendingModifying']
                return services.filter(
                    service => validStatuses.includes(service.status)
                ).map(service => {
                    return {
                        id: service.id,
                        name: service.name,
                        provider: service.provider,
                    }
                })
            },
            mapBackupTypes (types = []) {
                return types.map(type => {
                    return {
                        value: this.$help.capitalize(type),
                        key: type,
                    }
                })
            },
            async loadData () {
                if (!this.services?.length) {
                    await this.fetchServices()
                }
                if (!this.regions?.length) {
                    await this.getRegions()
                }
                this.serviceItems = this.mapServices(this.services)
                this.backupTypes = this.mapBackupTypes(this.$config.BACKUP_TYPES)
            },
            prepareBackupCost() {
                this.backupCostItems = []

                const filteredCostings =
                    this.backupCosting.filter(cost => cost.type === 'TIERED') || []

                const storageCost = this.storage === 'external' ?
                    filteredCostings.find(cost => cost.dimensions?.backupStorage === 'external-object-store') :
                    filteredCostings.find(cost => !cost.dimensions?.backupStorage)

                if (storageCost && storageCost.price?.pricePerMonth > 0) {
                    this.backupCostItems.push({
                        label: i18n.t('storage'),
                        rate: i18n.t(
                            'currency',
                            [i18n.t('perGBMonth', { month: parseFloat(storageCost.price?.pricePerMonth).toFixed(2),} )]
                        ),
                    })
                }
                if (this.frequency === 'cron') {
                    const recurringCostings =
                        this.backupCosting.filter(
                            cost => cost.type === 'PACKAGED' && cost.dimensions?.backupType === 'recurring'
                        ) || []

                    const recurringCost = this.isServerless ?
                        recurringCostings.find(cost => isServerlessTopology(cost.dimensions?.topology)) :
                        recurringCostings.find(cost => !isServerlessTopology(cost.dimensions?.topology))

                    if (recurringCost && recurringCost.price?.monthlyCommit > 0) {
                        this.backupCostItems.push({
                            label: i18n.t('backups.monthlyCommit'),
                            rate: i18n.t('currency', [recurringCost.price?.monthlyCommit?.toFixed(2)]),
                        })
                    }
                }
            },
            async getBackupCosts() {
                this.backupCostLoading = true

                await this.$lodash.isEmpty(this.offerings) && this.fetchOfferings()

                const offering = this.offerings?.find(offering => offering.name === 'backup') || {}
                const selectedService = this.services.find(service => service.id === this.selectedServiceId)

                if (!offering || !selectedService) {
                    this.backupCostLoading = false
                    return
                }

                const { topology, provider, region, } = selectedService

                const params = {
                    topology,
                    provider,
                    region,
                    tier: this.currentTier,
                }
                const [error, response] = await to(getPrices(
                    offering?.name, {
                        ...params,
                        tier: 'foundation',
                    }))

                if (error || !response) {
                    this.backupCostLoading = false
                    return
                }

                this.backupCosting = Object.values(response).flat()
                this.prepareBackupCost()

                this.backupCostLoading = false
            },
        },

    }
</script>
