export default Object.freeze({
    APP_ENV: process.env.VUE_APP_PORTAL_ENV,
    POWER_TIER: 'power',
    FOUNDATION_TIER: 'foundation',
    serviceStatus: {
        PENDING: 'pending',
        STARTING: 'starting',
        FAILED: 'failed',
        READY: 'ready',
        STOPPED: 'stopped',
        RETIRED: 'retired',
        PENDING_CREATE: 'pending_create',
        PENDING_MODIFYING: 'pending_modifying',
        PENDING_START: 'pending_start',
        PENDING_STOP: 'pending_stop',
        PENDING_SCALE: 'pending_scale',
        PENDING_DELETE: 'pending_delete',
        UNKNOWN: 'unknown',
    },
    STANDALONE_TOPOLOGY: ['standalone', 'es-single'],
    MASTERSLAVE: ['masterslave', 'es-replica'],
    SERVERLESS_STANDALONE: ['serverless-standalone'],
    SERVERLESS_REPLICATED: ['serverless-replicated'],
    XPAND: ['xpand-direct', 'xpand', 'xpand-pg'],
    COLUMNSTORE: ['columnstore', 'csdw'],
    messageType: {
        SUCCESS: 'success',
        ERROR: 'error',
    },
    TRANSACTIONAL: 'transactional',
    ANALYTICAL: 'analytical',
    ANALYTICAL_DEFAULT_STORAGE: 100,
    IOPS_DEFAULT: 2,
    DIALOG_TYPE: {
        DELETE: 'delete',
        APPLY: 'apply',
        REMOVE: 'remove',
        DUPLICATE: 'duplicate',
        INVITE: 'invite',
        DEACTIVATE: 'deactivate',
        EDIT: 'edit',
        CREATE: 'create',
        CANCEL: 'cancel',
        ACTIVATE: 'activate',
        RESEND: 'resend',
        SETUP: 'setup',
    },
    REPLICATION_DIRECTIONS: {
        UNI: 'uni-directional',
        BI: 'bi-directional',
    },
    REPLICATION_DIRECTION_OPTIONS: {
        TO: 'to',
        FROM: 'from',
        BOTH_WAYS: 'bothWays',
    },
    IOPS_MAX: 50,
    QUICK_NOTIFICATION_FETCH_INTERVAL_SECONDS: 5,
    AUTO_VERTICAL_SCALE: 'scale-up-down',
    AUTO_HORIZONTAL_SCALE: 'scale-in-out',
    AUTO_STORAGE_SCALE: 'scale-txn-storage',
    VERTICAL_SCALE_GROUP: 'autoScaleNodesVertical',
    HORIZONTAL_SCALE_GROUP: 'autoScaleNodesHorizontal',
    STORAGE_SCALE_GROUP: 'autoScaleDisk',
    USER_STATUS: {
        INVITED: 'invited',
        ACCEPTED: 'accepted',
        EXPIRED: 'expired',
        CANCELLED: 'cancelled',
        PENDING: 'pending',
        ACTIVE: 'active',
        DEACTIVATED: 'deactivated',
    },
    CONFIG_MANAGER_TITLE: 'Configuration Manager',
    REPLICATION_STATUS: {
        OK: 'OK',
        WARNING: 'WARNING',
        CRITICAL: 'CRITICAL',
        PENDING: 'PENDING',
        MODIFYING: 'MODIFYING',
    },
    REPEAT_INTERVAL_IMMEDIATELY: '0m',
    OLD_PORTAL_URL: `${process.env.VUE_APP_OLD_PORTAL_URL}/skysql`,
    CSM_URL: `${process.env.VUE_APP_OLD_PORTAL_URL}/csm`,
    SUPPORT_URL: process.env.VUE_APP_SUPPORT_URL,
    NEW_SUPPORT_TICKET_URL: process.env.VUE_APP_NEW_SUPPORT_TICKET_URL,
    CONTACT_URL: process.env.VUE_APP_CONTACT_URL,
    AI_COPILOT_URL: process.env.VUE_APP_AI_COPILOT_URL,
    SIZE_UNITS: ['b', 'kb', 'mb', 'gb'],
    ENDPOINT_MECHANISM_PRIVATELINK: 'privateconnect',
    ENDPOINT_MECHANISM_NLB: 'nlb',
    IP_WHITELIST: 'ip-whitelist',
    PSC: 'psc',
    UTC: 'UTC',
    CONFIG_COMPONENT: {
        SERVER: 'server',
        MAXSCALE: 'maxscale',
    },
    SKY1_BANNER_KEY: 'sky1-banner-visible-',
    SKY1_BANNER_HIDDEN_AFTER: '2023-07-01', // DBAAS-13824
    CONTACT_EMAIL: 'info@skysql.com',
    SKYSQL_INSTANCE_SIZES_DOCS_URL: 'https://skysqlinc.github.io/skysql-docs/Reference%20Guide/Instance%20Size%20Choices/',
    meterNames: {
        PROVISIONED_COMPUTE: 'provisioned-compute',
        STORAGE: 'storage',
        IOPS: 'iops',
        THROUGHPUT: 'throughput',
    },
})
