<template>
    <BillingSummaryV2 class="mt-8" />
</template>

<script>
    import BillingSummaryV2 from 'components/billing/BillingSummaryV2.vue'
    export default {
        name: 'Usage',
        components: { BillingSummaryV2, },
    }
</script>
