/* eslint-disable camelcase */
import { CONFIG } from 'config/index.js'
import i18n from 'plugins/i18n'
import { t } from 'typy'
import semverSort from 'semver-sort'

export function isStandAloneTopology(topology) {
    return CONFIG.STANDALONE_TOPOLOGY.includes(topology)
}

export function isSingleNodeAnalytics(topology, replicas) {
    return CONFIG.COLUMNSTORE.includes(topology) && replicas === 1
}

export function isColumnstore(topology) {
    return CONFIG.COLUMNSTORE.includes(topology)
}

export function isXpand(topology) {
    return CONFIG.XPAND.includes(topology)
}

export function isPrimaryReplica(topology) {
    return CONFIG.MASTERSLAVE.includes(topology)
}

export function isStandaloneServerless(topology) {
    return CONFIG.SERVERLESS_STANDALONE.includes(topology)
}

export function isReplicatedServerless(topology) {
    return CONFIG.SERVERLESS_REPLICATED.includes(topology)
}

export function isServerlessTopology(topology) {
    return isStandaloneServerless(topology) || isReplicatedServerless(topology)
}

export function isMultiNodeTopology(topology) {
    return (
        isPrimaryReplica(topology) ||
        isXpand(topology) ||
        isColumnstore(topology)
    )
}

export function getNodeTypeForPricingAPI(topology) {
    return isMultiNodeTopology(topology) ? 'multi' : 'single'
}

export function isSingleNodeTopology(topology = '', nodes = 0) {
    return !nodes || isStandAloneTopology(topology)
}

export function isProviderGCP(provider) {
    return CONFIG.cloudProvider.GCP === provider
}

export function isProviderAWS(provider) {
    return CONFIG.cloudProvider.AWS === provider
}

export function isProviderAzure(provider) {
    return CONFIG.cloudProvider.AZURE === provider
}

export function isPrivateConnectOpted(endpoints = []) {
    if (!endpoints.length) return false
    return Boolean(getPrivateConnectObject(endpoints))
}

export function doPrivateConnectHaveProjects(endpoints = []) {
    if (!endpoints.length) return false
    const privateConnect = getPrivateConnectObject(endpoints)
    return Boolean(t(privateConnect, 'allowed_accounts').safeArray.length)
}

export function privateConnectLabel(provider, isDualEP = false) {
    // TODO:: Remove this if condition when dual endpoint feature
    // is deployed, also remove the default return as
    // per the new Marketing text for Dual EP those will be no longer
    // valid for Portal
    if (isDualEP) {
        if (isProviderGCP(provider)) {
            return i18n.t('dualEndpoints.privateServiceConnect')
        } else if (isProviderAWS(provider)) {
            return i18n.t('dualEndpoints.privateLink')
        } else {
            return i18n.t('dualEndpoints.azurePrivateLink')
        }
    }

    return isProviderGCP(provider)
        ? i18n.t('privateConnect.privateServiceConnect')
        : i18n.t('privateConnect.privateLink')
}

export function getPrivateConnectServiceEndpoint(endpoints = []) {
    const privateConnect = getPrivateConnectObject(endpoints)
    return t(privateConnect, 'endpoint_service').safeString
}

function getPrivateConnectObject(endpoints = []) {
    return endpoints.find(isEndpointPrivateConnect)
}

export function getPrivateConnectProjects(endpoints, provider) {
    const privateConnectEndpoint = getPrivateConnectObject(endpoints)
    let object = 'private_link.accounts'

    if (isProviderGCP(provider)) {
        object = 'private_service_connect.projects'
    }

    return t(privateConnectEndpoint, object).safeArray
}

export function getPrivateConnectInstructionLink(provider) {
    const link = `${CONFIG.DOCS_URL}/Using%20AWS%20Azure%20GCP%20private%20VPC%20connections/`
    if (isProviderAWS(provider)) {
        return `${link}Setting%20up%20AWS%20Private%20Link/`
    } else if (isProviderGCP(provider)) {
        return `${link}Setting%20up%20GCP%20Private%20Service%20Connect/`
    } else if (isProviderAzure(provider)) {
        return `${link}Setting%20up%20Azure%20Private%20Link/`
    } else {
        return link
    }
}

export function getIpAllowList(endpoints = []) {
    const ipAllowListObject = getIpAllowedObject(endpoints)
    return t(ipAllowListObject, 'allow_list').safeArray
}

export function getIpAllowedObject(endpoints) {
    return endpoints.find(
        ({ mechanism, }) => mechanism === CONFIG.ENDPOINT_MECHANISM_NLB
    )
}

export function privateConnectEntityLabel(provider) {
    if (isProviderAWS(provider)) {
        return i18n.t('accountId')
    } else if (isProviderGCP(provider)) {
        return i18n.t('projectId')
    } else {
        return i18n.t('subscriptionId')
    }
}

export function privateConnectEntity(provider) {
    if (isProviderAWS(provider)) {
        return i18n.t('privateConnect.account')
    } else if (isProviderGCP(provider)) {
        return i18n.t('privateConnect.project')
    } else {
        return i18n.t('privateConnect.subscription')
    }
}

export function getFilteredInstanceSizes(instances, params) {
    const { provider, serviceType, topology, arch, } = params
    return instances.filter((instance) => {
        return instance.provider === provider &&
            instance.service_type === serviceType &&
            instance.topology === topology &&
            (!arch || instance.architecture === arch)
    })
}

export function hasMultipleEndpoints(endpoints = []) {
    return endpoints.length > 1
}

export function areBothSecureEndpointsAllowList(endpoints = []) {
    return (
        endpoints.filter((ep) => ep.mechanism === CONFIG.ENDPOINT_MECHANISM_NLB)
            .length > 1
    )
}

export function isPrimaryEndpointPrivateServiceConnect(endpoints = []) {
    const endpoint = getPrivateConnectObject(endpoints)
    if (endpoint) {
        return endpoint.name === 'primary'
    }
    return false
}

export function getReadWritePort(endpoint) {
    const { ports, } = endpoint
    if (!ports || !t(ports).safeArray || t(ports).isEmptyArray) return null
    return ports.find((port) => port.name === 'readwrite')
}

export function getReadOnlyPort(endpoint) {
    const { ports, } = endpoint
    if (!ports || !t(ports).safeArray || t(ports).isEmptyArray) return null

    return ports.find((port) => port.name === 'readonly')
}

export function isEndpointPrivateConnect(endpoint) {
    return endpoint.mechanism === CONFIG.ENDPOINT_MECHANISM_PRIVATELINK
}

export function isServiceStatusReady(service) {
    return service.status === CONFIG.serviceStatus.READY
}

export function getPrimaryEndpoint(endpoints = []) {
    return endpoints.find((endpoint) => endpoint.name === 'primary')
}

// There is no condition on naming of secondary endpoint
// to be secondary, it can be anything but primary
export function getSecondaryEndpoint(endpoints = []) {
    return endpoints.find((endpoint) => endpoint.name !== 'primary')
}

export function getLoadBalancerFQDN(endpoints) {
    const ipAllowListObject = getIpAllowedObject(endpoints)
    return t(ipAllowListObject, 'fqdn').safeString
}

export function isVersion23(version) {
    return version === '23.06.00-00' // hardcoding as per discussion for DBAAS-14051
}

export function sortServerVersions(serverList) {
    const versionOrder = semverSort.asc(serverList.map(server => server.version))
    return [...serverList].sort((a,b) => versionOrder.indexOf(b.version) - versionOrder.indexOf(a.version))
}

export function getEndpointFQDN(endpoint) {
    return t(endpoint, 'fqdn').safeString
}

export function getNoSQLPort(endpoint) {
    const { ports, } = endpoint
    if (!ports || !t(ports).safeArray || t(ports).isEmptyArray) return null

    return ports.find((port) => port.name === 'nosql')
}

export function isServicePendingCreate(service) {
    return service.status === CONFIG.serviceStatus.PENDING_CREATE
}
