<style lang="scss" scoped>
.billing-summary {
    border: 1px solid $cornsilk;
    color: $deep-ocean;

    &-new {
        min-height: 300px;
    }
}
.separator {
    width: 100%;
    height: 1px;
    border-top: 1px solid $cornsilk;
    margin: 8px 0;
}

::v-deep .legend-label {
    min-width: 140px;
    justify-content: space-between;
}

.total-section-head {
    gap: 4px;
}
.total-section-separator {
    height: 100%;
    border: 1px solid;
}
.commit-section {
    position: relative;

    .details-icon {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-75%) rotate(-90deg);
    }
}
.details-sec {
    border-left: 1px solid $cornsilk;
    padding-left: 8px;
}
.min-max-width-120 {
    min-width: 120px;
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>

<template>
    <div v-if="showNewUI">
        <v-row
            class="billing-summary mt-0"
            :class="{'billing-summary-new': showNewUI}">
            <!-- snapshot donut chart -->
            <v-col
                cols="6"
                class="d-flex flex-column">
                <DonutChart
                    v-if="!isHistoricalBillEmpty"
                    :data="chartData"
                    :options="options"
                    :is-flat-data="true" />
            </v-col>

            <!-- bill trend -->
            <v-col cols="6" />
        </v-row>
    </div>
    <div v-else>
        <v-row class="billing-summary pa-2">
            <v-col cols="2">
                {{ $t('billings.usageDays') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ historicalBills?.usageDays }}
                </div>
            </v-col>
            <v-col cols="2">
                {{ $t('billings.computeCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalComputeCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                {{ $t('billings.storageCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalStorageCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                {{ $t('billings.dataTransferCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.dataTransferCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                <div class="align-center d-flex">
                    <span class="mr-1">
                        {{ $t('billings.supportCost') }}
                    </span>
                    <HelpToolTip
                        :size="16">
                        <template #message>
                            <p>
                                {{ $t('billings.supportType',[historicalBills?.supportType]) }}
                            </p>
                        </template>
                    </HelpToolTip>
                </div>
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.supportCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                <span class="font-weight-bold">{{ $t('total') }}</span>
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.total?.toFixed(2)]) }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import DonutChart from 'components/monitoring/widgets/DonutChart'
    import HelpToolTip from 'components/common/HelpTooltip'
    import i18n from 'plugins/i18n'

    const HistoricalBillSummary = {
        name: 'HistoricalBillSummary',
        components: { DonutChart, HelpToolTip, },
        props: {
            historicalBills: {
                required: true,
                type: Object,
            },
        },
        data: () => ({
            options: {
                format: {
                    type: 'currencyUSD',
                    decimals: 2,
                },
                apex: {
                    chart: {
                        type: 'donut',
                    },
                    legend: {
                        formatter: (seriesName, opts) => {
                            return `
                                <div class="legend-label d-flex align-center">
                                    <div class="legend-text">${seriesName}</div>
                                    <div class="legend-value">${i18n.t('currency', [opts.w.globals.series[opts.seriesIndex]])}</div>
                                </div>
                            `
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    total: {
                                        show: true,
                                        showAlways: true,
                                        fontSize: '22px',
                                        fontWeight: 600,
                                        label: i18n.t('total'),
                                        formatter: function (w) {
                                            const total = w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b
                                            }, 0)
                                            return i18n.t('currency', [total?.toFixed(2)])
                                        },
                                    },
                                },
                            },
                        },
                    },
                    tooltip: {
                        custom: function({series, seriesIndex, w, }) {
                            return `
                                <div
                                    class="d-flex align-center pl-2 pr-2 pt-1 pb-1 text-caption"
                                    style="background-color: ${w.globals.colors[seriesIndex]};">
                                    <div class="legend-text">${w.globals.labels[seriesIndex]}:</div>
                                    <div class="legend-value ml-1">${i18n.t('currency', [series[seriesIndex]])}</div>
                                </div>
                            `
                        },
                    },
                },
                combineThreshold: -1,
            },
        }),
        computed: {
            showNewUI () {
                return this.$unleash.isFeatureEnabled('enable-new-historical-bills')
            },
            chartData () {
                return [{
                    label: this.$t('billings.computeCost'),
                    value: this.historicalBills?.totalComputeCost,
                }, {
                    label: this.$t('billings.storageCost'),
                    value: this.historicalBills?.totalStorageCost,
                }, {
                    label: this.$t('billings.dataTransferCost'),
                    value: this.historicalBills?.dataTransferCost,
                }, {
                    label: this.$t('billings.supportCost'),
                    value: this.historicalBills?.supportCost,
                }]
            },
            isHistoricalBillEmpty () {
                return this.$lodash.isEmpty(this.historicalBills)
            },
        },
    }
    export default HistoricalBillSummary
</script>
