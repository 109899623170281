<style lang="scss" scoped>
.billing-summary {
    border: 1px solid $cornsilk;
    color: $deep-ocean;

    & > .col:not(:last-of-type) {
        border-right: 1px solid $cornsilk;
    }
}
</style>

<template>
    <div>
        <div v-if="!isFetchingBillingUsage">
            <div>
                <v-row class="billing-summary pa-2">
                    <v-col cols="6">
                        {{ $t('billings.current-charges') }}
                        <div class="font-weight-bold text-subtitle-1">
                            {{ isBillingUsageDetailsAvailable ? $t('currency', [total.toFixed(2)]) : '--' }}*
                        </div>
                        <div class="text-caption font-italic">
                            {{ $t('billings.estimateOnlyDescription') }}
                        </div>
                    </v-col>
                    <template v-if="isFreeTrialActive && $unleash.isFeatureEnabled('enable-serverless')">
                        <v-col cols="2">
                            <div class="align-center d-flex">
                                <span class="mr-1">
                                    {{ $t('billings.scuHoursConsumed') }}
                                </span>
                                <ScuHelpTooltip />
                            </div>
                            <div class="font-weight-bold text-subtitle-1">
                                {{ isBillingUsageDetailsAvailable ? scuConsumed?.toFixed(2) : '--' }}
                            </div>
                        </v-col>
                        <v-col cols="2">
                            <div class="align-center d-flex">
                                <span class="mr-1">
                                    {{ $t('billings.scuHoursRemaining') }}
                                </span>
                                <ScuHelpTooltip />
                            </div>
                            <div class="font-weight-bold text-subtitle-1">
                                {{ isBillingUsageDetailsAvailable ? scuRemaining?.toFixed(2) : '--' }}
                            </div>
                        </v-col>
                    </template>
                    <v-col
                        v-if="$unleash.isFeatureEnabled('enable-portal-copilot-v2')"
                        cols="2">
                        <div class="align-center d-flex">
                            <span class="mb-1">
                                {{ $t('copilot.tokens') }}
                            </span>
                        </div>
                        <table class="text-body-2">
                            <tr>
                                <td>{{ $t('input') }}:</td>
                                <td
                                    v-if="inputTokensQuota"
                                    class="pl-1 text-right">
                                    {{ $format('short', inputTokensQuota?.usage) }}/{{ $format('short', inputTokensQuota?.value) }}
                                </td>
                                <td v-else>
                                    n/a
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('output') }}:</td>
                                <td
                                    v-if="outputTokensQuota"
                                    class="pl-1 text-right">
                                    {{ $format('short', outputTokensQuota?.usage) }}/{{ $format('short', outputTokensQuota?.value) }}
                                </td>
                                <td v-else>
                                    n/a
                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
                <div
                    v-if="isBillingUsageDetailsAvailable"
                    class="mt-4">
                    <div
                        v-if="processingBillingData"
                        class="d-flex justify-center mt-16">
                        <v-progress-circular
                            indeterminate
                            size="60" />
                    </div>
                    <template v-else>
                        <BillingUsageTable
                            :current-usage-sort="currentUsageSort"
                            :usage-data="usageData" />
                        <v-row>
                            <v-col cols="8" />
                            <BillingUsageTotal
                                :total="total" />
                            <v-col cols="2" />
                        </v-row>
                    </template>
                </div>
                <div
                    v-else
                    class="mt-6">
                    <v-row
                        justify="center"
                        class="font-weight-bold text-subtitle-1 billing-summary pa-2">
                        {{ $t('errors.missingUsageData') }}
                    </v-row>
                </div>
            </div>
        </div>
        <v-container
            v-else
            class="fill-height"
            fluid>
            <v-row
                align="center"
                justify="center">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import BillingSortToggle from 'components/billing/BillingSortToggle.vue'
    import BillingUsageTable from 'components/billing/BillingUsageTable.vue'
    import BillingUsageTotal from 'components/billing/BillingUsageTotal.vue'
    import ScuHelpTooltip from 'components/common/ScuHelpTooltip.vue'
    import {
        getTopologyTitle,
        getNewUsageChildData
    } from 'utils/billing'
    import i18n from 'plugins/i18n'

    const BillingSummaryV2 = {
        name: 'BillingSummaryV2',
        components: {
            ScuHelpTooltip,
            BillingSortToggle,
            BillingUsageTable,
            BillingUsageTotal,
        },
        data() {
            return {
                processingBillingData: false,
                currentUsageSort: 'service',
                usageData: [],
            }
        },
        computed: {
            ...mapGetters([
                'billingUsageDetails',
                'orgData',
                'isFreeTrialActive',
                'isFetchingBillingUsage',
                'inputTokensQuota',
                'outputTokensQuota'
            ]),
            usageSort() {
                return [{
                    text: 'By service',
                    value: 'service',
                }, {
                    text: 'By resource',
                    value: 'resource',
                }]
            },
            total() {
                return this.billingUsageDetails?.total ?? 0
            },
            scuConsumed() {
                return this.billingUsageDetails?.scuConsumed ?? 0
            },
            scuRemaining() {
                return this.billingUsageDetails?.scuRemaining ?? 0
            },
            isBillingUsageDetailsAvailable() {
                return !this.$lodash.isEmpty(this.billingUsageDetails)
            },
        },
        watch: {
            billingUsageDetails: {
                handler() {
                    if(this.$lodash.isEmpty(this.billingUsageDetails?.items)) {
                        this.usageData = []
                    } else {
                        this.transformUsageByServices(this.billingUsageDetails?.items)
                    }
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions(['fetchCurrentBillingUsage', 'fetchBillingRecord', 'fetchUsageQuota']),
            updateUsageSort(sortType){
                this.currentUsageSort = sortType
            },
            async transformUsageByServices(usageData) {
                this.processingBillingData = true
                let usage = []
                for (const o of usageData) {
                    const resource = o?.resource || {}
                    let obj = {}
                    obj.title = resource.name ?? '',
                    obj.subTitle = i18n.t(
                        'billings.topologyOnProvider', {
                            topology: getTopologyTitle(resource.dimensions?.topology),
                            provider: resource.dimensions?.provider?.toUpperCase(),
                        }),
                    obj.cost = o?.total
                    obj.children = await getNewUsageChildData(o, this.isFreeTrialActive, this.$unleash)
                    usage.push(obj)
                }
                this.usageData = usage
                this.processingBillingData = false
            },
        },
        created() {
            const monthYear = this.$moment().startOf('month').format('YYYY-MM')
            if (this.$lodash.isEmpty(this.billingUsageDetails)) {
                this.fetchCurrentBillingUsage({
                    year: this.$moment(monthYear).year(),
                    month: this.$moment(monthYear).month() + 1,
                })
            }
            this.fetchUsageQuota()
        },
    }
    export default BillingSummaryV2
</script>
