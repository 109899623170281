<style scoped lang="scss">
.v-list-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.position-absolute > .v-btn {
    top: -3.5em;
}
.primary-text {
    color: #6353ef;
}
.gap-8 {
    gap: 8px;
}
</style>

<template>
    <div class="pt-6">
        <div class="d-flex justify-space-between mb-4">
            <v-btn
                class="text-none"
                color="primary"
                rounded
                outlined
                :ripple="false"
                @click="() => {keyLabel = '' ; isCreateDialogOpen = true}">
                {{ 'Create New' }}
            </v-btn>
        </div>
        <v-data-table
            class="data-table-full logs-table"
            :headers="headers"
            :items="keyList"
            :loading="isFetchingKeys"
            :no-data-text="$t('info.noKeys')"
            fixed-header
            :hide-default-footer="true">
            <template #item="{ item: key }">
                <tr>
                    <td>{{ key.label }}</td>
                    <td>{{ key.apiKey }}</td>
                    <td>{{ key.createdBy }}</td>
                    <td>{{ key.updatedBy }}</td>
                    <td>{{ key.createdDate }}</td>
                    <td>{{ key.updatedDate }}</td>
                    <td>{{ key.status }}</td>
                    <td>
                        <v-menu
                            transition="slide-y-transition"
                            offset-y>
                            <template #activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon>
                                        mdi-dots-horizontal
                                    </v-icon>
                                </div>
                            </template>
                            <v-list class="pointer">
                                <v-list-item
                                    @click="openDialog(key?.isActive ? ACTION_TYPE.REVOKE : ACTION_TYPE.ACTIVATE, key)">
                                    {{ key?.isActive ? $t('revoke') : $t('activate') }}
                                </v-list-item>
                                <v-list-item
                                    @click="openDialog(ACTION_TYPE.DELETE, key)">
                                    {{ $t('delete') }}
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog
            v-model="isDialogOpen"
            persistent
            max-width="600px"
            scrollable>
            <v-card>
                <v-card-title class="pt-9 pb-5 px-9">
                    <span
                        class="
                text-h4
                font-weight-medium">
                        {{ action }}</span>
                </v-card-title>
                <v-card-text class="pb-5 px-9">
                    <span
                        :class="['text-body-1', 'primary-text', {'red--text': action !== ACTION_TYPE.ACTIVATE}]">
                        {{ action === ACTION_TYPE.REVOKE ? $t('confirmations.revoke', {name: selectedKey?.label}) : action === ACTION_TYPE.ACTIVATE ? $t('confirmations.activate', {name: selectedKey?.label}) : $t('confirmations.delete', {name: selectedKey?.label}) }}</span>
                </v-card-text>
                <v-divider />
                <v-card-actions class="py-4 px-9">
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="isDialogOpen = false">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        :loading="isModifyingKey"
                        @click="action === ACTION_TYPE.DELETE ? deleteKey() : modifyKey()">
                        {{ action }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="isCreateDialogOpen"
            persistent
            max-width="600px"
            scrollable>
            <v-card>
                <v-card-title class="pt-9 pb-5 px-9">
                    <span
                        class="
                text-h4
                font-weight-medium">
                        {{ $t('create') }}{{ ' New Key' }}</span>
                </v-card-title>
                <v-card-text class="pb-5 px-9 pt-2">
                    <v-text-field
                        v-model="keyLabel"
                        label="Name"
                        placeholder="Key Name"
                        :counter="60"
                        outlined
                        :rules="[v => v.length <= 60 || 'Max 60 characters allowed']"
                        :disabled="isCreatingKey"
                        dense />
                </v-card-text>
                <v-divider />
                <v-card-actions class="py-4 px-9">
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="isCreateDialogOpen = false">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        :loading="isCreatingKey"
                        :disabled="keyLabel?.length < 1 || keyLabel?.length > 60"
                        @click="createKey">
                        {{ $t('create') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showCreatedApiKey"
            persistent
            max-width="600px"
            scrollable>
            <v-card>
                <v-card-title class="pt-9 pb-5 px-9">
                    <span
                        class="
                text-h4
                font-weight-medium">
                        {{ ' Your API Key' }}</span>
                </v-card-title>
                <v-card-text class="pb-5 px-9 pt-2">
                    <span class="text-body-1">
                        {{ $t('info.keyGenerated') }}
                    </span>
                    <div class="d-flex gap-8 mt-4">
                        <v-text-field
                            v-model="generatedApiKey"
                            :type="revealKey ? 'text' : 'password'"
                            :append-icon="revealKey ? 'mdi-eye' : 'mdi-eye-off'"
                            outlined
                            hide-details
                            :readonly="true"
                            dense
                            @click:append="revealKey = !revealKey" />
                        <div class="d-flex justify-center flex-column">
                            <v-icon
                                class="primary-text"
                                @click="copyToClipboard(generatedApiKey)">
                                mdi-content-copy
                            </v-icon>
                        </div>
                    </div>
                </v-card-text>
                <v-divider />
                <v-card-actions class="py-4 px-9">
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="showCreatedApiKey = false">
                        {{ $t('ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapGetters, mapActions  } from 'vuex'
    import to from 'await-to-js'
    import { getApiKeys, createApiKey, updateApiKey, deleteApiKey } from 'services/organizations'
    export default {
        name: 'ApiKeyManagement',
        data () {
            return {
                headers: [
                    { text: this.$t('name'), value: 'label', sortable: false, },
                    { text: this.$t('value'), value: 'apiKey', sortable: false, width: '60px', },
                    { text: this.$t('createdBy'), value: 'createdBy', sortable: false, },
                    { text: this.$t('updatedBy'), value: 'updatedBy', sortable: false, },
                    { text: this.$t('createdDate'), value: 'createdDate', sortable: false, },
                    { text: this.$t('updatedDate'), value: 'updatedDate', sortable: false, },
                    { text: this.$t('status'), value: 'status', sortable: false, },
                    { text: '', value: '', sortable: false, width: '4em', }
                ],
                keyList: [],
                isFetchingKeys: false,
                isDialogOpen: false,
                isCreateDialogOpen: false,
                action: '',
                selectedKey: '',
                isModifyingKey: false,
                ACTION_TYPE: {
                    ACTIVATE: 'Activate',
                    REVOKE: 'Revoke',
                    DELETE: 'Delete',
                },
                keyLabel: '',
                isCreatingKey: false,
                showCreatedApiKey: false,
                generatedApiKey: '',
                revealKey: false,
            }
        },
        computed: {
            ...mapGetters(['user', 'users']),
        },
        methods: {
            ...mapActions(['getUsers', 'copyToClipboard']),
            async fetchKeys() {
                this.isFetchingKeys = true
                const [error, response] = await to(getApiKeys(this.user?.user_id ?? this.user?.id))
                if (error) {
                    this.$store.commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                } else {
                    this.keyList = this.transformObj(response)
                }
                this.isFetchingKeys = false
            },
            transformObj(obj) {
                return obj?.map(key => {
                    return {
                        ...key,
                        ...{
                            status: key?.isActive ? 'Active' : 'Revoked',
                            createdDate: this.$moment(key?.createdDate * 1000).format('YYYY-MM-DD'),
                            updatedDate: this.$moment(key?.updatedDate * 1000).format('YYYY-MM-DD'),
                            createdBy: this.getUserName(key?.createdBy),
                            updatedBy: this.getUserName(key?.updatedBy),
                            apiKey: key?.apiKey?.slice(0, 34) + '......',
                        },
                    }
                })
            },
            getUserName(userId) {
                const obj = this.users?.find(user => user?.id === userId)
                return `${obj?.first_name} ${obj?.last_name}`
            },
            openDialog(userAction, key) {
                this.isDialogOpen = true
                this.action = userAction
                this.selectedKey = key
            },
            async modifyKey() {
                try {
                    this.isModifyingKey = true
                    const [error, response] = await to(updateApiKey(this.user?.user_id ?? this.user?.id, this.selectedKey?.keyId, {revoke: this.selectedKey?.isActive ? true : false,}))
                    if (error) {
                        this.$store.commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: this.$config.messageType.ERROR, })
                    } else {
                        this.$store.commit('showMessage', { text: `${this.selectedKey?.label} has been ${this.action?.toLowerCase()}d successfully` ?? response, type: this.$config.messageType.SUCCESS, })
                        this.isDialogOpen = false
                        await this.fetchKeys()
                    }
                } catch (e) {
                    this.$store.commit('showMessage', { text: `Unable to ${this.action?.toLowerCase()} key. Please try again later`, type: this.$config.messageType.ERROR, })
                } finally {
                    this.isModifyingKey = false
                }
            },
            async deleteKey() {
                try {
                    this.isModifyingKey = true
                    const [error, response] = await to(deleteApiKey(this.user?.user_id ?? this.user?.id, this.selectedKey?.keyId))
                    if (error) {
                        this.$store.commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: this.$config.messageType.ERROR, })
                    } else {
                        this.$store.commit('showMessage', { text: `${this.selectedKey?.label} has been deleted successfully` ?? response, type: this.$config.messageType.SUCCESS, })
                        this.isDialogOpen = false
                        await this.fetchKeys()
                    }
                } catch (e) {
                    this.$store.commit('showMessage', { text: 'Unable to delete key. Please try again later', type: this.$config.messageType.ERROR, })
                } finally {
                    this.isModifyingKey = false
                }
            },
            async createKey() {
                try {
                    this.isCreatingKey = true
                    const [error, response] = await to(createApiKey(this.user?.user_id ?? this.user?.id, this.keyLabel))
                    if (error) {
                        let e = error?.response?.data?.errors?.[0]?.message
                        this.$store.commit('showMessage', { text: e?.charAt(0).toUpperCase() + e.slice(1), type: this.$config.messageType.ERROR, })
                    } else {
                        this.generatedApiKey = response?.apiKey
                        this.$store.commit('showMessage', { text: `${this.keyLabel} has been created successfully`, type: this.$config.messageType.SUCCESS, })
                        this.showCreatedApiKey = true
                        this.isCreateDialogOpen = false
                        await this.fetchKeys()
                    }
                } catch (e) {
                    this.$store.commit('showMessage', { text: 'Unable to create key. Please try again later', type: this.$config.messageType.ERROR, })
                } finally {
                    this.isCreatingKey = false
                }
            },
        },
        async mounted() {
            await this.getUsers()
            try {
                this.fetchKeys()
            } catch {
                this.$store.commit('showMessage', { text: 'Unable to fetch keys. Please try again later', type: this.$config.messageType.ERROR, })
            }
        },
    }
</script>
