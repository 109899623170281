<style lang="scss" scoped>
    .v-card--reveal {
        bottom: 0;
        opacity: 1 !important;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .v-card__text {
        height: calc(100% - 52px);
    }
    .v-card__actions {
        min-height: 52px;
    }
</style>

<template>
    <v-container class="h-100">
        <v-card
            :loading="loading"
            class="h-100">
            <v-container
                v-if="contact?.firstName"
                class="pa-0 h-100">
                <v-card-text>
                    <div>{{ contact.type }}</div>
                    <p class="text-h4 text--primary">
                        {{ contact.firstName }} {{ contact.lastName }}
                    </p>
                    <p class="text--secondary mb-0">
                        {{ contact.email }}
                    </p>
                    <p class="text--secondary">
                        {{ contact.phone }}
                    </p>
                    <div
                        v-if="address?.length"
                        class="text--secondary">
                        {{ address }}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="primary"
                        :disabled="loading"
                        @click="editContact">
                        {{ $t('edit') }}
                    </v-btn>
                    <v-btn
                        v-if="!isPrimary"
                        text
                        color="primary"
                        :disabled="loading"
                        @click="reveal = true">
                        {{ $t('delete') }}
                    </v-btn>
                </v-card-actions>

                <v-expand-transition v-if="!isPrimary">
                    <v-card
                        v-if="reveal"
                        class="transition-fast-in-fast-out v-card--reveal">
                        <v-card-text class="pb-0">
                            <p class="text-h4 text--primary">
                                {{ $t('billings.deleteContactConfirmationTitle') }}
                            </p>
                            <p>
                                {{ $t('billings.deleteContactConfirmationMessage') }}
                            </p>
                        </v-card-text>
                        <v-card-actions class="pt-0">
                            <v-btn
                                text
                                color="primary"
                                :disabled="loading"
                                @click="reveal = false">
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                :disabled="loading"
                                @click="onDeleteContact">
                                {{ $t('yesDelete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>
            </v-container>
            <v-container
                v-else
                class="pa-0 h-100">
                <v-card-text>
                    <div>{{ contact?.type }}</div>
                    <p class="pt-4 text--secondary">
                        {{ $t('notFound') }}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="primary"
                        :disabled="loading"
                        @click="addNewContact">
                        {{ $t('add') }}
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card>

        <ContactModal ref="contactModal" />
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ContactModal from 'components/billing/contactManagement/ContactModal.vue'

    export default {
        name: 'ContactCard',
        components: {
            ContactModal,
        },
        props: {
            contact: { type: Object, required: true, },
            loading: { type: Boolean, default: false, },
        },
        data () {
            return {
                reveal: false,
            }
        },
        computed: {
            ...mapGetters([
                'deleteContactSuccess'
            ]),
            address() {
                return this.contact.address ? Object.values(this.contact.address).filter(Boolean).join(', ') : ''
            },
            isPrimary() {
                return this.contact.type?.toLowerCase() === 'primary'
            },
        },
        methods: {
            ...mapActions([
                'fetchContacts',
                'deleteContact'
            ]),
            openContactModal ({ mode, contact, }) {
                this.$refs.contactModal.open({ mode, contact, })
            },
            editContact() {
                this.openContactModal({ mode: 'edit', contact: this.contact, })
            },
            async onDeleteContact() {
                await this.deleteContact(this.contact.id)
                this.reveal = false
                if (this.deleteContactSuccess) {
                    this.$store.commit('showMessage', { text: this.$t('billings.contactDeletedSuccessfully'), type: '#03a128', })
                }
                await this.fetchContacts()
            },
            addNewContact () {
                this.openContactModal({ mode: 'create', })
            },
        },
    }
</script>
