import billing from 'api/billing'
//TODO:: replace this when #203 is merged
import { camelize } from 'helpers/camelCase'
import { convertToSnakeCase } from 'helpers/snakeCase'

export function getInstancesCosts(params){
    return billing.get('prices/instance', params).then(response=> camelize(response.data))
}

export function getStorageCost(params){
    return billing.get('prices/storage', params).then(response=> camelize(response.data))
}

export function getIOPSCost(params){
    return billing.get('prices/iops', params).then(response=> camelize(response.data))
}

export function getThroughputCost(params){
    return billing.get('prices/throughput', params).then(response=> camelize(response.data))
}

export function getEndpointCost(params) {
    return billing.get('prices/endpoint', params).then((response) => camelize(response.data))
}

export function getBillingUsageDetails(){
    return billing.get('usage/preview').then(response=> camelize(response.data))
}

export function getBillingRecord(noCache){
    return billing.get(`account${noCache ? '?no-cache=' + new Date().getTime() : ''}`).then(response=> camelize(response.data))
}

export function getPaymentMethods(){
    return billing.get('payment-methods').then(response=> camelize(response.data.result || response.data))
}

export function editPayment({id, data,}){
    return billing.patch(`payment-methods/${id}`, {...convertToSnakeCase(data), }).then(response=> camelize(response.data))
}

export async function deletePayment(id) {
    return billing.delete(`payment-methods/${id}`).then(response=> camelize(response.data.result))
}

export function getCountries(){
    return billing.get('locale/country').then(response=> camelize(response.data))
}

export function getStatesForCountry(params){
    return billing.get('locale/state-province',params ).then(response=> camelize(response.data))
}

export function getSpendingByService(serviceId){
    return billing.get(`usage/service/${serviceId}`).then(response=> camelize(response.data))
}

export function getSpendingForAllServices(){
    return billing.get('usage/service').then(response=> camelize(response.data))
}

export function getSpendingFoService(serviceId){
    return billing.get(`usage/service/${serviceId}`).then(response=> camelize(response.data))
}

export function getInvoices(){
    return billing.get('invoices').then(response=> camelize(response.data))
}

export function getInvoiceById(invoiceId) {
    return billing.get(`invoices/${invoiceId}`).then(response => camelize(response.data))
}

export function getInvoicePdf(invoiceId) {
    return billing.get(`invoices/${invoiceId}/pdf?as=attachment`).then(response => response.data)
}

export function setupIntent() {
    return billing.post('payment-methods/setup-intent').then((response) => camelize(response.data))
}

export function getHistoricalBills(params) {
    return billing.get('bills', params).then((response) => camelize(response.data))
}

export function getCreditLedger() {
    return billing.get('account/prepaid-credit-ledger').then((response) => camelize(response.data))
}

export function getCommitLedger() {
    return billing.get('account/postpaid-commit-ledger').then((response) => camelize(response.data))
}

export function getTax() {
    return billing.get('tax').then((response) => camelize(response.data))
}

export function addNewTax(tax){
    return billing.post('tax', {...convertToSnakeCase(tax), }).then(response=> camelize(response.data.result))
}

export function editTax({tax, id,}){
    return billing.patch(`tax/${id}`, {...convertToSnakeCase(tax), }).then(response=> camelize(response.data))
}

export async function deleteTax(id) {
    return billing.delete(`tax/${id}`).then(response=> camelize(response.data.result))
}

export function getContacts() {
    return billing.get('contacts').then((response) => camelize(response.data))
}

export function addNewContact(contact){
    return billing.post('contacts', {...convertToSnakeCase(contact), }).then(response=> camelize(response.data.result))
}

export function editContact({contact, id,}){
    return billing.patch(`contacts/${id}`, {...convertToSnakeCase(contact), }).then(response=> camelize(response.data))
}

export async function deleteContact(id) {
    return billing.delete(`contacts/${id}`).then(response=> camelize(response.data.result))
}

export function getPrices(offering, params){
    return billing.get(`prices/${offering}`, params).then(response=> camelize(response.data))
}
