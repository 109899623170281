<template>
    <div>
        <v-alert
            v-if="!isEditing"
            outlined
            type="info"
            text>
            {{ $t('info.copilotGeneratingTables') }}
        </v-alert>
        <div class="mb-4 font-weight-light text-h5">
            {{ $t('copilot.agentTablesContext') }}
        </div>

        <div class="mb-2">
            <v-icon dense>
                mdi-table-multiple
            </v-icon>
            <span class="pl-1">
                {{ datasourceSchema }}
            </span>
        </div>
        <v-autocomplete
            v-model="selectedTables"
            :items="tables"
            :label="$t('copilot.selectedTables')"
            outlined
            chips
            multi-line
            deletable-chips
            hide-details
            multiple />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'CopilotAgentModalTables',
        props: {
            agentId: {
                type: String,
                required: true,
            },
            isEditing: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selectedTables: [],
                tables: [],
                datasourceSchema: '',
            }
        },
        computed: {
            ...mapGetters([
                'agents',
                'datasources'
            ]),
            agent() {
                return this.agents.find(agent => agent.id === this.agentId)
            },
            isValid() {
                return this.selectedTables.length > 0
            },
        },
        watch: {
            isValid(value) {
                this.$emit('update:isValid', value)
            },
        },
        methods: {
            ...mapActions([
                'removeAgentContextTable',
                'addAgentContextTable',
                'refreshAgents'
            ]),
            async save() {
                const tablesToRemove = this.agent?.context?.tables.filter(table => !this.selectedTables.includes(table.name))
                const tablesToAdd = this.selectedTables.filter(table => !this.agent?.context?.tables.some(t => t.name === table))
                if (tablesToRemove.length || tablesToAdd.length) {
                    await Promise.all([
                        ...tablesToRemove.map(table => this.removeAgentContextTable({ agentId: this.agentId, tableId: table.id, })),
                        ...tablesToAdd.map(table => this.addAgentContextTable({ agentId: this.agentId, tableName: table, }))
                    ])
                    await this.refreshAgents()
                    return [tablesToRemove, tablesToAdd]
                }
                return []
            },
        },
        beforeMount() {
            this.$emit('update:actionLabel', 'next')
            this.datasourceSchema = this.agent?.context?.name
            const datasource = this.datasources.find(datasource => datasource.id === this.agent?.context?.datasourceId)
            if (datasource) {
                const schema = datasource.schemas.find(schema => schema.name === this.datasourceSchema)
                if (schema) {
                    this.tables = [...schema.tables]
                }
            }
            this.selectedTables = this.agent?.context?.tables.map(table => table.name)
        },
    }
</script>
