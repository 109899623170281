<style lang="scss" scoped>
    @import "prismjs/themes/prism-coy.css";
    @import 'vue-prism-editor/dist/prismeditor.min.css';

    .copilot {
        position: relative;
        height: 100%;
        padding-left: 96px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.expanded {
            padding-left: 360px;
            .sidebar {
                width: 340px;
                gap: 16px;
            }
        }
    }
    .sidebar {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 48px;
        height: 100%;
        padding: 24px 12px 56px;
        border-right: 1px solid #e8eef1;
    }
    .sidebar-toggle {
        position: absolute;
            bottom: 16px;
            right: 6px;
    }
    .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .context {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    .docs {
        position: absolute;
        left: 12px;
        bottom: 6px;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 6px;
    }
    #copilot-messages {
        position: relative;
        flex-grow: 1;
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
        height: calc(100% - 600px);
        overflow-y: auto;
        .loading {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }
</style>

<template>
    <v-container
        :class="['copilot ma-0 pr-12 py-4', isSidebarExpanded ? 'expanded' : null]"
        fluid>
        <div class="sidebar">
            <v-btn
                class="sidebar-toggle"
                small
                icon
                @click="toggleSidebar">
                <v-icon>
                    {{ isSidebarExpanded ? 'mdi-arrow-expand-left' : 'mdi-arrow-expand-right' }}
                </v-icon>
            </v-btn>
            <div v-if="isSidebarExpanded">
                <div class="text-overline toolbar">
                    <div>
                        {{ $tc('copilot.agent', 1) }}
                        <span
                            v-if="selectedAgent?.hasMoreInfo"
                            class="pointer"
                            @click="openAgentInfoModal">
                            <CopilotInfoIcon
                                :help-text="`${currentAgentDescription} ${$t('copilot.clickForMore')}`"
                                icon="mdi-alert-box-outline" />
                        </span>
                        <CopilotInfoIcon
                            v-else
                            :help-text="currentAgentDescription" />
                    </div>
                    <div>
                        <v-btn
                            v-if="selectedAgent?.type === 'USER'"
                            color="primary"
                            x-small
                            text
                            :disabled="areDatasourcesLoading"
                            @click="editAgent(selectedAgent?.id)">
                            <v-icon left>
                                mdi-account-edit
                            </v-icon>
                            {{ $t('edit') }}
                        </v-btn>
                        <v-btn
                            color="info"
                            x-small
                            text
                            :disabled="areDatasourcesLoading"
                            @click="createAgent">
                            <v-icon left>
                                mdi-account-plus
                            </v-icon>
                            {{ $t('create') }}
                        </v-btn>
                        <v-tooltip
                            open-delay="400"
                            :open-on-hover="true"
                            :open-on-focus="false"
                            :open-on-click="false"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    x-small
                                    icon
                                    :disabled="areAgentsLoading"
                                    v-on="on"
                                    @click="manageAgents">
                                    <v-icon>
                                        mdi-cog
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('copilot.manageAgents') }}</span>
                        </v-tooltip>
                    </div>
                </div>
                <div>
                    <CopilotAgentsMenu @showAgentInfo="openAgentInfoModal" />
                </div>
            </div>
            <div v-else>
                <v-tooltip
                    open-delay="400"
                    :open-on-hover="true"
                    :open-on-focus="false"
                    :open-on-click="false"
                    transition="scroll-x-transition"
                    right>
                    <template #activator="{ on }">
                        <v-icon v-on="on">
                            {{ selectedAgent?.type === 'SYSTEM' ? 'mdi-shield-account' : 'mdi-account' }}
                        </v-icon>
                    </template>
                    <span>{{ selectedAgent?.name }}</span>
                </v-tooltip>
            </div>

            <div v-if="isSidebarExpanded">
                <div class="text-overline toolbar">
                    <div>
                        {{ $tc('copilot.datasource', 1) }}
                        <CopilotInfoIcon :help-text="$t('copilot.datasourceInfo')" />
                    </div>
                    <div>
                        <v-btn
                            v-if="selectedDatasource?.type === 'USER'"
                            color="primary"
                            x-small
                            text
                            :disabled="areDatasourcesLoading"
                            @click="editDatasource(selectedDatasource?.id)">
                            <v-icon left>
                                mdi-database-edit
                            </v-icon>
                            {{ $t('edit') }}
                        </v-btn>
                        <v-btn
                            color="info"
                            x-small
                            text
                            @click="addDatasource">
                            <v-icon left>
                                mdi-database-plus
                            </v-icon>
                            {{ $t('add') }}
                        </v-btn>
                        <v-tooltip
                            open-delay="400"
                            :open-on-hover="true"
                            :open-on-focus="false"
                            :open-on-click="false"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    x-small
                                    icon
                                    :disabled="areDatasourcesLoading"
                                    v-on="on"
                                    @click="manageDatasources">
                                    <v-icon>
                                        mdi-cog
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('copilot.manageDatasource') }}</span>
                        </v-tooltip>
                    </div>
                </div>
                <div>
                    <CopilotDatasourcesMenu />
                </div>
            </div>
            <div v-else>
                <v-tooltip
                    open-delay="400"
                    :open-on-hover="true"
                    :open-on-focus="false"
                    :open-on-click="false"
                    transition="scroll-x-transition"
                    right>
                    <template #activator="{ on }">
                        <v-icon v-on="on">
                            {{ selectedDatasource?.type === 'SYSTEM' ? 'mdi-database-eye' : 'mdi-database' }}
                        </v-icon>
                    </template>
                    <span>{{ selectedDatasource?.name }}</span>
                </v-tooltip>
            </div>

            <div
                v-if="isSchemasMenuVisible"
                class="context">
                <div>
                    <div class="text-overline toolbar">
                        <div>
                            {{ $t('copilot.schema') }}
                            <CopilotInfoIcon :help-text="$t('copilot.schemaInfo')" />
                        </div>
                    </div>
                    <CopilotSchemasMenu />
                </div>

                <CopilotSchemaViewer />
            </div>
            <div
                v-if="isContextVisible"
                class="context">
                <div class="text-overline toolbar">
                    <div>
                        {{ $t('copilot.context') }}
                        <CopilotInfoIcon :help-text="$t('copilot.contextInfo')" />
                    </div>
                    <div class="align-center d-flex">
                        <v-btn
                            color="primary"
                            x-small
                            text
                            class="ml-2"
                            @click="manageGoldenQueries">
                            <v-icon left>
                                mdi-bookmark
                            </v-icon>
                            {{ $t('copilot.goldenQueries') }}
                        </v-btn>
                    </div>
                </div>
                <CopilotContextMenu
                    v-if="isContextVisible"
                    @editAgent="editAgent" />
            </div>

            <div
                v-if="isSidebarExpanded"
                class="docs">
                <v-btn
                    x-small
                    text
                    @click="openIntroVideo">
                    <v-icon left>
                        mdi-account-question
                    </v-icon>
                    {{ $t('copilot.agentCreationGuide') }}
                </v-btn>
                <v-btn
                    x-small
                    text
                    @click="openDocs">
                    <v-icon
                        small
                        left>
                        mdi-text-box-multiple
                    </v-icon>
                    {{ $t('documentation') }}
                </v-btn>
            </div>
        </div>

        <div>
            <CopilotChatsMenu />
            <CopilotChatStarterQuestions
                v-if="!isChatSessionLoading && chatEntries.length"
                dense
                @select="sendPrompt" />
        </div>

        <div
            id="copilot-messages"
            ref="messages">
            <div
                v-if="isChatSessionLoading"
                class="loading">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate />
            </div>
            <template v-else-if="!chatEntries.length">
                <div
                    v-if="isContextVisible && isShowOnlyGoldenQueries"
                    class="align-center d-flex flex-column h-100 justify-center w-100">
                    <div class="text--secondary">
                        {{ $t('copilot.noGoldenQueries') }}
                    </div>
                    <v-btn
                        color="primary"
                        x-small
                        text
                        class="mt-1"
                        @click="toggleShowOnlyGoldenQueires(false)">
                        {{ $t('copilot.showAllQueries') }}
                    </v-btn>
                </div>
                <CopilotChatStarterQuestions
                    v-else
                    @select="sendPrompt" />
            </template>
            <div v-else>
                <chat-message
                    v-for="(entry, i) in chatEntries"
                    :key="i"
                    :message="entry"
                    :session-id="selectedChatSession?.id"
                    class="copilot-message"
                    @runQuery="runQuery" />
            </div>
        </div>

        <CopilotChatPromptInput @input="sendPrompt" />

        <CopilotDatasourceModal ref="datasourceModal" />
        <CopilotDatasourcesManageModal
            ref="datasourcesManageModal"
            @addDatasource="addDatasource"
            @editDatasource="editDatasource"
            @removeDatasource="confirmDatasourceRemoval" />
        <ConfirmationModal
            ref="confirmDatasourceDeleteModal"
            message="confirmations.deleteDatasource"
            :on-confirm="() => removeDatasource(confirmedDatasourceId)" />
        <CopilotAgentInfoModal ref="agentInfoModal" />
        <CopilotAgentModal ref="agentModal" />
        <CopilotAgentsManageModal
            ref="agentsManageModal"
            @addAgent="createAgent"
            @editAgent="editAgent"
            @removeAgent="confirmAgentRemoval" />
        <ConfirmationModal
            ref="confirmAgentDeleteModal"
            message="confirmations.deleteAgent"
            :on-confirm="() => removeAgent(confirmedAgent)" />
        <GoldenQueriesManageModal ref="goldenQueriesManageModal" />
    </v-container>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { isCopilotAgent } from 'services/copilot.js'
    import ConfirmationModal from 'components/common/ConfirmationModal.vue'
    import CopilotInfoIcon from 'components/copilot/InfoIcon.vue'
    import CopilotDatasourcesMenu from 'components/copilot/DatasourcesMenu.vue'
    import CopilotDatasourceModal from 'components/copilot/DatasourceModal.vue'
    import CopilotDatasourcesManageModal from 'components/copilot/DatasourcesManageModal.vue'
    import CopilotAgentsMenu from 'components/copilot/AgentsMenu.vue'
    import CopilotSchemasMenu from 'components/copilot/SchemasMenu.vue'
    import CopilotSchemaViewer from 'components/copilot/SchemaViewer.vue'
    import CopilotContextMenu from 'components/copilot/ContextMenu.vue'
    import CopilotChatsMenu from 'components/copilot/ChatsMenu.vue'
    import CopilotChatStarterQuestions from 'components/copilot/ChatStarterQuestions.vue'
    import CopilotChatPromptInput from 'components/copilot/ChatPromptInput.vue'
    import CopilotAgentInfoModal from 'components/copilot/AgentInfoModal.vue'
    import CopilotAgentModal from 'components/copilot/AgentModal.vue'
    import CopilotAgentsManageModal from 'components/copilot/AgentsManageModal.vue'
    import GoldenQueriesManageModal from 'components/copilot/GoldenQueriesManageModal.vue'

    import 'prismjs/components/prism-go' // required for GO grammer
    import 'prismjs/components/prism-sql' // required for SQL grammer
    import 'prismjs/components/prism-yaml' // required for YAML grammer
    import 'prismjs/components/prism-bash' // required for BASH grammer
    import 'prismjs/components/prism-python' // required for Python grammer
    import 'prismjs/components/prism-javascript' // required for JavaScript grammer
    import 'prismjs/components/prism-markup' // required for HTML grammer
    import 'prismjs/components/prism-java' // required for Java grammer
    import 'prismjs/components/prism-json' // required for JSON grammer
    import 'prismjs/plugins/toolbar/prism-toolbar.js' // required for the following plugins
    import 'prismjs/plugins/toolbar/prism-toolbar.css' // required for the following plugins
    import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js' // show copy button
    import 'prismjs/plugins/show-language/prism-show-language.js' // display the language of the code block
    import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.js' // required to normalize the whitespace

    export default {
        name: 'CopilotPage',
        components: {
            ConfirmationModal,
            CopilotInfoIcon,
            CopilotDatasourcesMenu,
            CopilotDatasourceModal,
            CopilotDatasourcesManageModal,
            CopilotSchemasMenu,
            CopilotSchemaViewer,
            CopilotAgentsMenu,
            CopilotContextMenu,
            CopilotChatsMenu,
            CopilotChatStarterQuestions,
            CopilotChatPromptInput,
            CopilotAgentInfoModal,
            CopilotAgentModal,
            CopilotAgentsManageModal,
            GoldenQueriesManageModal,
        },
        data() {
            return {
                isSidebarExpanded: localStorage.getItem('copilot-sidepanel-collapsed') !== 'yes',
                confirmedDatasourceId: null,
                confirmedAgent: null,
            }
        },
        computed: {
            ...mapGetters([
                'datasources',
                'agents',
                'areAgentsLoading',
                'selectedAgent',
                'areDatasourcesLoading',
                'selectedDatasource',
                'selectedDatasourceSchema',
                'selectedChatSession',
                'isChatSessionLoading',
                'agentChatSessions',
                'chatSessions',
                'isShowOnlyGoldenQueries',
                'getDatasourceById',
                'getAgentById'
            ]),
            currentAgentDescription() {
                return this.selectedAgent?.description || 'n/a'
            },
            chatEntries() {
                if (this.isContextVisible && this.isShowOnlyGoldenQueries) {
                    return this.selectedChatSession?.entries.filter(entry => this.selectedAgent.queries.find(query => query.originEntryId === entry.id)) || []
                }
                return this.selectedChatSession?.entries || []
            },
            isSchemasMenuVisible() {
                return isCopilotAgent('data', this.selectedAgent?.id) && this.isSidebarExpanded
            },
            isContextVisible() {
                return this.selectedAgent?.type === 'USER' && this.isSidebarExpanded
            },
        },
        watch: {
            selectedDatasource(datasource) {
                if (this.agentChatSessions.length && !this.selectedChatSession?.id) {
                    this.changeChatSession()
                }
                if (datasource?.id) {
                    this.setRouteParams()
                }
            },
            selectedAgent(agent) {
                if (this.agentChatSessions.length && !this.selectedChatSession?.id) {
                    this.changeChatSession()
                }
                if (agent?.id) {
                    this.setRouteParams()
                }
            },
            agentChatSessions(sessions){
                if (!sessions.length && this.selectedAgent?.id && this.selectedDatasource?.id) {
                    this.newChatSession({
                        agentId: this.selectedAgent.id,
                        datasourceId: this.selectedDatasource.id,
                        datasourceSchema: this.selectedDatasourceSchema,
                    })
                }
            },
            chatEntries() {
                this.scrollToLastChatMessage()
            },
        },
        methods: {
            ...mapActions([
                'fetchDatasources',
                'fetchAgents',
                'fetchChatSessions',
                'newChatMessage',
                'newChatSession',
                'selectChatSession',
                'resetCopilotState',
                'removeDatasource',
                'removeAgent',
                'selectAgent',
                'selectDatasource',
                'setShowOnlyGoldenQueries',
                'fetchUsageQuota'
            ]),
            setRouteParams() {
                const params = this.$route.params
                if (this.selectedAgent?.id) {
                    params.agentId = this.selectedAgent.id
                }
                if (this.selectedDatasource?.id) {
                    params.datasourceId = this.selectedDatasource.id
                }
                this.$router.push({
                    name: this.$route.name,
                    params,
                })
            },
            changeChatSession() {
                const selectedChatSession = this.agentChatSessions.find(chatSession => chatSession.id === localStorage.getItem('copilotChatSessionId'))
                this.selectChatSession(selectedChatSession?.id || this.agentChatSessions[this.agentChatSessions.length - 1].id)
            },
            toggleSidebar() {
                this.isSidebarExpanded = !this.isSidebarExpanded
                if (this.isSidebarExpanded) {
                    localStorage.removeItem('copilot-sidepanel-collapsed')
                } else {
                    localStorage.setItem('copilot-sidepanel-collapsed', 'yes')
                }
            },
            addDatasource() {
                this.$refs.datasourceModal.open()
            },
            editDatasource(datasourceId) {
                this.$refs.datasourceModal.open(datasourceId)
            },
            confirmDatasourceRemoval(datasourceId) {
                this.confirmedDatasourceId = datasourceId
                this.$refs.confirmDatasourceDeleteModal.open()
            },
            manageDatasources() {
                this.$refs.datasourcesManageModal.open()
            },
            manageGoldenQueries() {
                this.$refs.goldenQueriesManageModal.open()
            },
            openIntroVideo () {
                window.open(`${this.$config.COPILOT_VIDEO_URL}`)
            },
            openDocs () {
                window.open(`${this.$config.DOCS_URL}SkyCopilot Guide`)
            },
            scrollToLastChatMessage() {
                if (this.$refs.messages && this.chatEntries && this.chatEntries.length) {
                    setTimeout(() => {
                        const items = this.$refs.messages.querySelectorAll('#copilot-messages .copilot-message')
                        if (items.length) {
                            items[items.length - 1].scrollIntoView()
                        }
                    }, 100)
                }
            },
            async sendPrompt (prompt) {
                await this.newChatMessage({
                    prompt: prompt.trim(),
                    chatSessionId: this.selectedChatSession?.id,
                })
                this.scrollToLastChatMessage()
                this.fetchUsageQuota()
            },
            async runQuery(query) {
                if (!query?.length) return
                this.newChatMessage({
                    prompt: `Run the following query: \n\n${query}`,
                    chatSessionId: this.selectedChatSession?.id,
                })
            },
            createAgent() {
                this.$refs.agentModal.open()
            },
            editAgent(agentId, step) {
                this.$refs.agentModal.open(agentId, step)
            },
            manageAgents() {
                this.$refs.agentsManageModal.open()
            },
            confirmAgentRemoval(agent) {
                this.confirmedAgent = agent
                this.$refs.confirmAgentDeleteModal.open()
            },
            openAgentInfoModal() {
                this.$refs.agentInfoModal.open()
            },
            toggleShowOnlyGoldenQueires(value) {
                this.setShowOnlyGoldenQueries(value)
            },
        },
        async created() {
            this.resetCopilotState()
            const dependencies = [
                this.fetchDatasources(),
                this.fetchAgents(),
                this.fetchChatSessions()
            ]
            await Promise.all(dependencies)
            if (this.datasources.length) {
                const datasourceId = this.$route.params.datasourceId || localStorage.getItem('copilotDatasourceId')
                const selectedDatasourceId = this.getDatasourceById(datasourceId)?.id || this.datasources[0].id
                await this.selectDatasource(selectedDatasourceId)
            }
            if (this.agents.length) {
                const agentId = this.$route.params.agentId || localStorage.getItem('copilotAgentId')
                const selectedAgentId = this.getAgentById(agentId)?.id || this.agents[0].id
                this.selectAgent(selectedAgentId)
            }
            if (!this.chatSessions?.length) {
                this.newChatSession({
                    agentId: this.selectedAgent?.id,
                    datasourceId: this.selectedDatasource?.id,
                    datasourceSchema: this.selectedDatasourceSchema,
                })
            }
            this.fetchUsageQuota()
        },
    }
</script>
