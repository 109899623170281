<style lang="scss" scoped>
    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .settings-menu {
        min-width: 440px !important;

        .settings-wrapper {
            background: white;
        }

        .std--dropdown {
            width: 200px;
            min-width: 200px;
            max-width: 200px;
        }

        .settings-footer {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
</style>

<template>
    <div class="menu">
        <v-tabs :value="selectedChatSession?.id">
            <v-tab
                v-for="(chatSession, i) in agentChatSessions"
                :key="chatSession.id"
                :tab-value="chatSession.id"
                :disabled="isChatSessionLoading"
                @change="selectChatSession(chatSession.id)">
                <span v-if="chatSession.summary">
                    <truncate-string
                        :text="chatSession.summary"
                        max-width="200px"
                        :dot-count="3"
                        :has-tooltip="true" />
                </span>
                <span v-else>
                    {{ $t('copilot.newChat') }} ({{ i + 1 }})
                </span>
                <span v-if="chatSession.agentConfig?.dbName">
                    (<span class="text-none info--text">
                        <v-icon small>mdi-table-multiple</v-icon>
                        {{ chatSession.agentConfig.dbName }}</span>)
                </span>
                <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            class="ml-1"
                            v-bind="attrs"
                            icon
                            small
                            v-on="on">
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="clearChatSession(chatSession.id)">
                            <v-list-item-title>
                                <v-icon>mdi-cancel</v-icon>
                                {{ $t('clear') }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="confirmChatSessionRemoval(chatSession.id)">
                            <v-list-item-title class="error--text">
                                <v-icon color="error">
                                    mdi-delete
                                </v-icon>
                                {{ $t('delete') }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-tab>
            <v-btn
                class="mt-2"
                color="primary"
                icon
                @click="newChat">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-tabs>
        <v-menu
            v-if="showUserSettings"
            v-model="settingsMenu"
            content-class="settings-menu"
            offset-x
            :close-on-click="false"
            :close-on-content-click="false"
            :disabled="isSettingsLoading || isSettingsError">
            <template #activator="{ on, attrs }">
                <v-btn
                    large
                    icon
                    v-bind="attrs"
                    :disabled="isSettingsLoading || isSettingsError"
                    :loading="isSettingsLoading"
                    v-on="on">
                    <v-icon large>
                        mdi-cog
                    </v-icon>
                </v-btn>
            </template>

            <div class="settings-wrapper">
                <v-list>
                    <v-list-item
                        v-for="(setting) in settingsItems"
                        :key="setting.id"
                        class="py-4">
                        <div class="item-wrap d-flex justify-space-between align-center w-100">
                            <span class="text-button text--secondary">
                                {{ setting.label }}
                                <HelpToolTip
                                    v-if="setting.help?.length"
                                    :size="16">
                                    <template #message>
                                        <p>{{ setting.help }}</p>
                                    </template>
                                </HelpToolTip>
                            </span>
                            <v-switch
                                v-if="setting.type === 'boolean'"
                                v-model="setting.value"
                                inset
                                :false-value="'off'"
                                :true-value="'on'"
                                :label="setting.value.toString()" />
                            <v-select
                                v-else
                                v-model="setting.value"
                                :items="setting.options"
                                class="std std--dropdown"
                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list', maxHeight: '140px' }"
                                height="40"
                                outlined
                                item-value="value"
                                item-text="value"
                                hide-details />
                        </div>
                    </v-list-item>
                </v-list>
                <div class="settings-footer pa-4 d-flex align-center">
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="settingsMenu = false; populateSettings()">
                        {{ $t('close') }}
                    </v-btn>
                    <v-btn
                        class="text-none ml-2"
                        color="primary"
                        rounded
                        depressed
                        :ripple="false"
                        :disabled="saveSettingsDisabled"
                        @click="saveUserSettings">
                        {{ $t('save') }}
                    </v-btn>
                </div>
            </div>
        </v-menu>
        <ConfirmationModal
            ref="confirmChatSessionDeleteModal"
            message="confirmations.deleteChatSession"
            :on-confirm="() => removeChatSession(confirmedChatSessionId)" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import HelpToolTip from 'components/common/HelpTooltip.vue'
    import ConfirmationModal from 'components/common/ConfirmationModal.vue'

    export default {
        name: 'CopilotChatsMenu',
        components: {
            ConfirmationModal,
            HelpToolTip,
        },
        data() {
            return {
                prompt: '',
                confirmedChatSessionId: null,
                settingsItems: [],
                saveSettingsDisabled: true,
                settingsMenu: false,
            }
        },
        computed: {
            ...mapGetters([
                'selectedAgent',
                'selectedDatasource',
                'selectedDatasourceSchema',
                'agentChatSessions',
                'selectedChatSession',
                'isChatSessionLoading',
                'availableSettings',
                'userSettings',
                'availableSettingsError',
                'userSettingsError',
                'isAvailableSettingsLoading',
                'isUserSettingsLoading',
                'saveUserSettingsError'
            ]),
            showUserSettings () {
                return this.$unleash.isFeatureEnabled('enable-genai-user-settings')
            },
            isSettingsLoading() {
                return this.isAvailableSettingsLoading || this.isUserSettingsLoading
            },
            isSettingsError() {
                return this.availableSettingsError || this.userSettingsError
            },
        },
        watch: {
            availableSettings: {
                handler() {
                    this.populateSettings()
                },
                deep: true,
            },
            userSettings: {
                handler() {
                    this.populateSettings()
                },
                deep: true,
            },
            settingsItems: {
                handler() {
                    this.saveSettingsDisabled = this.settingsItems.every(setting => setting.value === setting.originalValue)
                },
                deep: true,
            },
            saveUserSettingsError() {
                if (this.saveUserSettingsError) {
                    this.populateSettings()
                }
            },
        },
        methods: {
            ...mapActions([
                'selectChatSession',
                'newChatSession',
                'clearChatSession',
                'removeChatSession',
                'fetchAvailableSettings',
                'fetchUserSettings',
                'updateUserSettings'
            ]),
            async newChat() {
                this.newChatSession({
                    agentId: this.selectedAgent.id,
                    datasourceId: this.selectedDatasource.id,
                    datasourceSchema: this.selectedDatasourceSchema,
                })
            },
            confirmChatSessionRemoval(chatSessionId) {
                this.confirmedChatSessionId = chatSessionId
                this.$refs.confirmChatSessionDeleteModal.open()
            },
            populateSettings() {
                if (!this.showUserSettings) return
                const items = []
                this.availableSettings.forEach(setting => {
                    const item = {
                        label: setting.name?.replaceAll('_', ' '),
                        options: setting.options,
                        help: setting.description,
                        id: setting.id,
                    }
                    if (setting.name === 'agent_single_turn') {
                        item.type = 'boolean'
                    }
                    const savedSetting = Object.values(this.userSettings || {})
                        .find(userSetting => userSetting.id === setting.id)
                    if (savedSetting) {
                        item.value = savedSetting.value
                    } else {
                        item.value = setting.options.find(option => option.default)?.value
                    }
                    item.originalValue = item.value
                    items.push(item)
                })
                this.settingsItems = items
            },
            saveUserSettings() {
                this.settingsMenu = false
                this.settingsItems.forEach(setting => {
                    if (setting.value !== setting.originalValue) {
                        this.updateUserSettings({
                            settingId: setting.id,
                            value: setting.value,
                        })
                    }
                })
            },
        },
        async created() {
            if (this.showUserSettings) {
                if (!this.availableSettings?.length) {
                    await this.fetchAvailableSettings()
                }
                await this.fetchUserSettings()
            }
        },
    }
</script>
