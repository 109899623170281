<template>
    <div>
        <v-alert
            outlined
            type="info"
            text>
            {{ $t('info.copilotAgentCreationGuide') }}
        </v-alert>
        <iframe
            width="680"
            height="382"
            :src="$config.COPILOT_VIDEO_EMBED"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen></iframe>
    </div>
</template>

<script>
    export default {
        name: 'CopilotAgentModalGuide',
        beforeMount() {
            this.$emit('update:actionLabel', 'next')
        },
        mounted() {
            this.$emit('update:isValid', true)
        },
    }
</script>
