<style scoped>
 .control-label {
        font-size: 0.675rem;
    }
</style>

<template>
    <div class="pt-3">
        <div class="d-flex mb-3 justify-space-between">
            <div class="d-flex">
                <div>
                    <label class="d-block color text-small-text control-label">
                        {{ $tc('service') }}
                    </label>
                    <div>
                        <v-select
                            v-model="selectedDatabaseFilter"
                            :items="databaseFilterList"
                            class="std std--dropdown"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                            height="36"
                            :placeholder="'All'"
                            outlined
                            item-value="id"
                            attach
                            required
                            hide-details
                            style="min-width: 8em; max-width: 16em">
                            <template #item="{ item }">
                                {{ item.name }}
                            </template>
                            <template #selection="{ item }">
                                {{ item.name }}
                            </template>
                        </v-select>
                    </div>
                </div>
                <div
                    class="ml-2">
                    <label class="d-block color text-small-text control-label">
                        {{ $t('backups.restoreType') }}
                    </label>
                    <div>
                        <v-select
                            v-model="selectedRestoreTypeFilter"
                            :items="RestoreFilterTypes"
                            class="std std--dropdown"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                            height="36"
                            outlined
                            item-value="key"
                            attach
                            required
                            hide-details
                            style="width: 12em">
                            <template #item="{ item }">
                                {{ item.value }}
                            </template>
                            <template #selection="{ item }">
                                {{ item.value }}
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="d-flex align-end">
                <v-btn
                    v-if="isExternalStorageRestoreEnabled"
                    class="text-none mr-2"
                    color="primary"
                    rounded
                    depressed
                    @click="isExternalRestoreDialogOpen = true">
                    {{ $t('backups.restoreFromExternalStorage') }}
                </v-btn>
            </div>
        </div>
        <v-data-table
            class="data-table-full logs-table"
            :headers="headers"
            :items="restoresList"
            :options.sync="options"
            :server-items-length="restoresCount"
            :loading="isFetchingRestores"
            :footer-props="footerOptions"
            :no-data-text="$t('info.noBackups', ['restores'])"
            fixed-header>
            <template #item="{ item: restore }">
                <tr>
                    <td>{{ restore.serviceName }}</td>
                    <td>{{ restore.type }}</td>
                    <td>{{ restore.bucket }}</td>
                    <td
                        :class="{
                            'red--text': restore.status === 'Failed',
                            'green--text': restore.status === 'Succeeded'
                        }">
                        {{ restore.status }}
                    </td>
                </tr>
            </template>
        </v-data-table>
        <ExternalRestoreModal
            v-if="isExternalStorageRestoreEnabled"
            :show-dialog="isExternalRestoreDialogOpen"
            :is-loading="externalStorageRestoreInProgress"
            @on-close="isExternalRestoreDialogOpen = false"
            @on-external-restore-submit="restoreExternalStorage" />
    </div>
</template>

<script>
    import to from 'await-to-js'
    import { mapGetters, mapActions } from 'vuex'
    import { restoreBackup } from 'services/backups.js'
    import ExternalRestoreModal from './ExternalRestoreModal.vue'

    export default {
        name: 'ServiceRestores',
        components: {
            ExternalRestoreModal,
        },
        data() {
            return {
                isExternalRestoreDialogOpen: false,
                externalStorageRestoreInProgress: false,
                pollingInterval: null,
                RestoreFilterTypes: [
                    {
                        key: 'all',
                        value: 'All',
                    },
                    {
                        key: 'physical',
                        value: 'Physical',
                    },
                    {
                        key: 'logical',
                        value: 'Logical',
                    }
                ],
                selectedRestoreTypeFilter: 'all',
                selectedDatabaseFilter: 'all',
                selectedRestore: {},
                databaseFilterList: [{id: 'all', name: 'All',}],
                restoresList: [],
                headers: [
                    { text: this.$tc('database'), value: 'serviceName', sortable: false, },
                    { text: this.$t('type'), value: 'type', sortable: false, },
                    { text: this.$t('bucket'), value: 'bucket', sortable: false, },
                    { text: this.$t('status'), value: 'status', sortable: false, }
                ],
                footerOptions: {
                    'items-per-page-options': [10, 20, 50, 100],
                },
                options: {},
            }
        },
        computed: {
            ...mapGetters(['services', 'restoresData', 'isFetchingRestores']),
            restoresCount() {
                return this.restoresData?.restoresCount ?? 0
            },
            isExternalStorageRestoreEnabled() {
                return this.$unleash.isFeatureEnabled('enable-external-storage-restore')
            },
        },
        watch: {
            options: {
                handler () {
                    this.fetchRestoreData()
                },
                deep: true,
            },
            selectedDatabaseFilter: {
                handler () {
                    this.fetchRestoreData()
                },
            },
            selectedRestoreTypeFilter: {
                handler () {
                    this.fetchRestoreData()
                },
            },
            restoresData() {
                this.restoresList = this.restoresData?.restores?.map(obj => {
                    return {
                        serviceName: obj?.serviceName ?? '',
                        id: obj?.id ?? '',
                        type: obj?.type?.charAt(0)?.toUpperCase() +  obj?.type?.slice(1),
                        bucket: obj?.bucket,
                        status: obj?.status ?? 'Unknown',
                    }
                }) ?? []
            },
        },
        methods : {
            ...mapActions(['fetchServices', 'fetchRestores']),
            startPollingBackups (interval) {
                this.pollingInterval = setInterval(() => {
                    this.fetchRestoreData()
                }, interval)
            },
            stopPollingBackups () {
                clearInterval(this.pollingInterval)
            },
            async fetchRestoreData() {
                const { sortDesc, page, itemsPerPage, } = this.options
                const params = {
                    ...(this.selectedRestoreTypeFilter !== 'all' && {type: this.selectedRestoreTypeFilter,}),
                    ...(this.selectedDatabaseFilter !== 'all' && {serviceId: this.selectedDatabaseFilter,}),
                    pageSize: itemsPerPage,
                    page,
                    order: sortDesc[0] ? 'desc' : 'asc',
                }
                await this.fetchRestores(params)
            },
            async restoreExternalStorage(params) {
                try {
                    this.externalStorageRestoreInProgress = true
                    const [error, response] = await to(restoreBackup(params))
                    if (error) {
                        this.$store.commit('showMessage', { text: this.$t('errors.restoreError'), type: this.$config.messageType.ERROR, })
                    } else {
                        this.$store.commit('showMessage', { text: response?.message ?? this.$t('success.restoreBackupSuccess'), type: this.$config.messageType.SUCCESS, })
                        this.isExternalRestoreDialogOpen = false
                    }
                } catch {
                    this.$store.commit('showMessage', { text: this.$t('errors.restoreError'), type: this.$config.messageType.ERROR, })
                } finally {
                    this.externalStorageRestoreInProgress = false
                }
            },
        },
        async mounted() {
            if (!this.services?.length) {
                await this.fetchServices()
            }
            this.databaseFilterList = [...this.databaseFilterList, ...this.services?.map(service => {
                return {
                    id: service?.id,
                    name: service?.name,
                }
            })]
        },
        created() {
            this.startPollingBackups(this.$config.backupsFetchInterval * 1000)
        },
        beforeDestroy () {
            this.stopPollingBackups()
        },
    }
</script>
