<style lang="scss" scoped>
    .column-textarea {
        min-width: 250px;
    }
    .cp-col-selected {
        background-color: #f4fbf4;
    }
    .catindex {
        width: 90px;
    }

    /* Apply custom scroll */
    .custom-scroll {
        max-height: 112px; /* Limit textarea height */
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .styled-textarea {
        min-height: 105px;
    }
</style>

<template>
    <div>
        <v-row class="mt-0 mb-4">
            <v-col class="pt-0 pb-0">
                <label class="required text-uppercase">{{ $t('copilot.tableDescription') }}</label>
                <overlay-scrollbars
                    class="custom-scroll">
                    <textarea
                        ref="textarea"
                        :value="tableDescription"
                        :placeholder="$t('description')"
                        class="styled-textarea"
                        @change="$emit('update:tableDescription', $event.target.value)"
                        @input="autoResize">
                    </textarea>
                </overlay-scrollbars>
            </v-col>
        </v-row>
        <v-row class="mt-0 mb-4">
            <v-col class="pt-0 pb-0">
                <label class="text-uppercase">
                    {{ $tc('column', 2) }}
                    <CopilotInfoIcon :help-text="$t('copilot.columnsSelection')" />
                </label>
                <div class="mb-2">
                    <v-text-field
                        v-model="search"
                        outlined
                        dense
                        clearable
                        hide-details
                        :label="$t('copilot.filterColumns')"
                        append-icon="mdi-magnify"
                        @click:clear="() => search = ''" />
                </div>
                <div class="cp-columns">
                    <v-data-table
                        class="data-table-full"
                        :headers="headers"
                        :items="columns"
                        :search="search"
                        hide-default-footer
                        disable-pagination
                        fixed-header>
                        <template #[`header.selection`]>
                            <v-simple-checkbox
                                color="primary"
                                :value="areAllColumnsSelected"
                                @input="$emit('all-columns-toggle', $event)" />
                        </template>
                        <template #[`header.categorical`]="{ header }">
                            <div class="catindex text-center">
                                {{ header.text }}
                                <CopilotInfoIcon
                                    :help-text="$t('copilot.columnCategoricalInfo')"
                                    :max-width="480" />
                            </div>
                        </template>
                        <template #item="{ item: column }">
                            <tr :class="column.isSelected ? 'cp-col-selected' : ''">
                                <td>
                                    <v-simple-checkbox
                                        color="primary"
                                        :value="column.isSelected"
                                        @input="$emit('column-toggle', column)" />
                                </td>
                                <td style="white-space: nowrap;">
                                    <v-icon dense>
                                        mdi-table-column
                                    </v-icon>
                                    {{ column.name || 'n/a' }}
                                </td>
                                <td>{{ column.type || 'n/a' }}</td>
                                <td class="text-center">
                                    <v-checkbox
                                        v-model="column.dataRole"
                                        value="CATEGORICAL" />
                                </td>
                                <td>
                                    <v-textarea
                                        v-model="column.description"
                                        class="column-textarea"
                                        label=""
                                        rows="2"
                                        :placeholder="$t('copilot.columnDescription')"
                                        outlined
                                        hide-details />
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import CopilotInfoIcon from 'components/copilot/InfoIcon.vue'

    export default {
        name: 'ContextModalBody',
        components: {
            CopilotInfoIcon,
        },
        props: {
            tableDescription: {
                type: String,
                required: true,
            },
            columns: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                search: '',
                headers: [
                    { text: '', value: 'selection', sortable: false, },
                    { text: this.$t('name'), value: 'name', sortable: false, },
                    { text: this.$t('type'), value: 'type', sortable: false, },
                    { text: this.$t('copilot.columnCategoricalIndex'), value: 'categorical', sortable: false, },
                    { text: this.$t('description'), value: 'description', sortable: false, }
                ],
            }
        },
        computed: {
            areAllColumnsSelected() {
                return !this.columns.some(column => column.isSelected === false)
            },
        },
        methods: {
            autoResize() {
                const textarea = this.$refs.textarea
                textarea.style.height = 'auto' // Reset height
                textarea.style.height = textarea.scrollHeight + 'px' // Set to scroll height
            },
        },
        created() {
            setTimeout(() => this.autoResize(), 0)
        },
    }
</script>
