<style lang="scss" scoped>
.usage-header {
    color: $grayed-out;
}
</style>
<template>
    <div>
        <v-row
            class="text-subtitle-1 usage-header">
            <v-col cols="5">
                <template v-if="headerTitle?.length">
                    {{ headerTitle }}
                </template>
                <template v-else>
                    {{ currentUsageSort === 'service' ? $tc('service', 1) : $t('billings.resource') }}
                </template>
            </v-col>
            <v-col cols="4" />
            <v-col class="text-right pr-0">
                {{ $t('cost') }}
            </v-col>
            <v-col
                cols="2"
                class="text-right">
                <v-icon
                    size="18"
                    @click="allExpanded = !allExpanded">
                    {{ allExpanded ? 'mdi-collapse-all-outline' : 'mdi-expand-all-outline' }}
                </v-icon>
            </v-col>
        </v-row>
        <BillingAccordionPanel
            v-for="(data, index) in usageData"
            :key="index"
            class="mb-3"
            :accordion-data="data"
            :is-one-level-tree="isOneLevelTree"
            :all-expanded="allExpanded"
            identifier="0" />
    </div>
</template>
<script>
    import BillingAccordionPanel from 'components/billing/BillingAccordionPanel.vue'
    export default {
        name: 'BillingUsageTable',
        components: {
            BillingAccordionPanel,
        },
        props: {
            usageData: {
                type: Array,
                required: true,
            },
            currentUsageSort: {
                type: String,
                required: true,
            },
            isOneLevelTree: {
                type: Boolean,
                default: false,
                required: false,
            },
            headerTitle: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                allExpanded: false,
            }
        },
    }
</script>
