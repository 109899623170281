<style lang="scss" scoped>
    #copilot-query {
        position: relative;
        font-size: 16px;
    }
</style>

<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-cancel="close"
        :on-save="saveAsGolden"
        :min-width="760"
        :max-width="900"
        :on-close="close">
        <template #title>
            <div class="font-weight-light text-h4 pb-6 d-flex flex-column">
                <span>{{ $t('copilot.markGolden') }}</span>
                <span class="text-body-2 mt-1">{{ $t('copilot.markGoldenSubTitle') }}</span>
            </div>
        </template>
        <template #body>
            <div id="copilot-query">
                <div>
                    <chat-message
                        :message="message"
                        session-id=""
                        mode="new"
                        class="copilot-message" />
                </div>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn
                color="primary"
                style="min-width: 100px;"
                depressed
                rounded
                @click="save">
                <span class="text-none">{{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'MarkGoldenQueryModal',
        props: {
            message: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isDialogOpen: false,
            }
        },
        computed: {
            ...mapGetters([
                'selectedAgent',
                'isMarkAsGoldenSuccess',
                'isUpdateQuerySuccess'
            ]),
        },
        watch: {
            isUpdateQuerySuccess() {
                if(this.isUpdateQuerySuccess) {
                    this.close()
                }
            },
        },
        methods: {
            ...mapActions([
                'addAgentQuery'
            ]),
            open() {
                this.isDialogOpen = true
            },
            close() {
                this.isDialogOpen = false
            },
            async saveAsGolden() {
                const params = {
                    agentId: this.selectedAgent.id,
                    sessionEntryId: this.message.id,
                    type: 'GOLDEN',
                }
                await this.addAgentQuery(params)
                if(this.isMarkAsGoldenSuccess) {
                    const btnSave = document.querySelector('#copilot-query .btn-query-save')
                    btnSave ? btnSave.click() : this.close()
                }
            },
        },
    }
</script>
