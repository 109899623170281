<template>
    <v-row
        v-if="!isInitializing"
        class="pa-4"
        dense
        no-gutters>
        <v-col
            class="pr-4"
            cols="2">
            <DashboardPanel
                :title="$t('serviceDetails')"
                height="110px"
                :show-help="false"
                has-action
                @contentClick="$router.push(`/service/${service.id}/details`)">
                <template #content>
                    <div
                        v-if="isServerless"
                        class="text-center">
                        <div class="text-body-2 text-capitalize">
                            {{ `${service?.storage_volume?.size} GB` }}
                        </div>
                        <div class="text-caption">
                            {{ $t('serviceDetailsTab.storage') }}
                        </div>
                    </div>
                    <div
                        v-else
                        class="text-center">
                        <div class="text-body-2 text-capitalize">
                            {{ service.size }}
                        </div>
                        <div class="text-caption text-lowercase">
                            {{ isNodes ? $help.getNodesTextForNonReplicatedTopology(service.nodes) : $help.getNodesTextForReplicatedTopology(service.nodes) }}
                        </div>
                    </div>
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            class="pr-4"
            cols="2">
            <DashboardPanel
                :title="isServerless ? $t('consumption') : $t('spending')"
                height="110px"
                :show-help="false"
                has-action
                @contentClick="$router.push(`/service/${service.id}/details`)">
                <template #content>
                    <div
                        class="text-center"
                        :class="{ 'd-flex align-center w-100': isServerless }">
                        <v-progress-circular
                            v-if="getServiceIdsSpendingFetchStatus.indexOf(service.id) > -1"
                            class="panel-progress"
                            indeterminate
                            size="64" />
                        <template v-else>
                            <template v-if="isServerless">
                                <div class="flex">
                                    <div class="text-body-2 text-capitalize">
                                        {{ $typy(spending).isNumber ? spending.toFixed(2) : spending }}
                                    </div>
                                    <div class="text-caption">
                                        SCU-h
                                        <ScuHelpTooltip />
                                    </div>
                                </div>
                                <div
                                    v-if="!isFreeTrialActive"
                                    class="flex">
                                    <div class="text-body-2 text-capitalize">
                                        {{
                                            $typy(serverlessSpendingAmount).isNumber ?
                                                $t('currency', [serverlessSpendingAmount.toFixed(2)]) :
                                                serverlessSpendingAmount
                                        }}
                                    </div>
                                    <div class="text-caption">
                                        {{ $t('spending') }}
                                    </div>
                                </div>
                            </template>
                            <template
                                v-else>
                                <div class="text-h6">
                                    {{
                                        $typy(spending).isNumber ?
                                            $t('currency', [spending.toFixed(2)]) :
                                            spending
                                    }}
                                </div>
                            </template>
                        </template>
                    </div>
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            v-for="(panel, i) in widgets.panels"
            :key="i"
            class="pr-4"
            :cols="showGlobalReplication && i == 2 ? 1 : 2">
            <DashboardPanel
                :title="getPanelLocale(panel.title)"
                :title-size="panel.titleSize"
                height="110px"
                :show-help="false"
                :metrics="panel.metrics"
                :refresh-interval="widgets.refreshInterval"
                :service="service"
                :time="time"
                :hide-error="panel.options?.hideError"
                has-action
                @contentClick="$router.push(`/service/${service.id}/monitoring/overview`)">
                <template #content="{ data }">
                    <component
                        :is="panel.widget"
                        :data="data"
                        :options="panel.options" />
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            :class="{'pr-4': showGlobalReplication}"
            :cols="showGlobalReplication? 1 : 2">
            <DashboardPanel
                :title="$t('alerts')"
                height="110px"
                :show-help="false"
                has-action
                @contentClick="$router.push(`service/${service.id}/alerts`)">
                <template #content>
                    <AlertsCount
                        :refresh-interval="widgets.refreshInterval"
                        :service="service"
                        :time="time" />
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            v-if="showGlobalReplication"
            cols="2">
            <DashboardPanel
                :title="$t('globalReplication')"
                height="110px"
                class="pointer"
                has-action
                @contentClick="$router.push(`/service/${service.id}/details`)">
                <template #content>
                    <replication-status :service="service" />
                </template>
            </DashboardPanel>
        </v-col>
    </v-row>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { getReplicas } from 'utils/replication'
    import { isServerlessTopology } from 'utils/service'
    import DashboardPanel from 'components/monitoring/Panel.vue'
    import ReplicationStatus from 'components/service/ReplicationStatus.vue'
    import AlertsCount from 'components/service/AlertsCount.vue'
    import ScuHelpTooltip from 'components/common/ScuHelpTooltip.vue'

    export default {
        name: 'ServiceListingWidgets',
        components: {
            DashboardPanel,
            AlertsCount,
            ReplicationStatus,
            ScuHelpTooltip,
        },
        props: {
            service: {
                required: true,
                type: Object,
            },
            widgets: {
                type: Object,
                default: () => {
                    return {
                        panels: [],
                    }
                },
            },
            spending: {
                required: true,
                type: [Number, String],
            },
        },
        data() {
            return {
                isInitializing: true,
                time: {
                    selected: true,
                    start: 'now-30 minutes',
                    end: 'now',
                },
            }
        },
        computed: {
            ...mapGetters([
                'regions',
                'services',
                'servicesSpending',
                'isFreeTrialActive',
                'getServiceIdsSpendingFetchStatus'
            ]),
            showGlobalReplication() {
                return !!getReplicas(this.services, this.service)?.length
            },
            isNodes(){
                return this.$help.hasNodesInTopology(this.service?.topology)
            },
            isServerless() {
                return isServerlessTopology(this.service?.topology)
            },
            serverlessSpendingAmount() {
                const spendFound = this.servicesSpending?.find(spending=> spending.serviceId === this.service.id)
                return spendFound?.estimate ?? '--'
            },
        },
        methods: {
            ...mapActions(['getRegions', 'getSilences']),
            getPanelLocale(locale) {
                return this.$t(`monitoring.panels.${locale}`)
            },
        },
        async created() {
            try {
                if(!this.regions?.length) {
                    await this.getRegions()
                }
            } catch (_) {
            //
            }
            this.isInitializing = false
        },
    }
</script>
