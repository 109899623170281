import {
    getTitleForEntity,
    mapBillingUsageEntityByLineItem,
    mapBillingUsageEntityByServiceName
} from 'mappers/billing'
import i18n from 'plugins/i18n'
import to from 'await-to-js'
import {
    isStandAloneTopology,
    isPrimaryReplica,
    getNodeTypeForPricingAPI
    // isProviderAWS
} from 'utils/service'
import { capitalize } from 'helpers'
import t from 'typy'
import { getMeterById } from 'services/billings'

const SORT_ORDER = {
    Node: 100,
    Storage: 200,
    IOPS: 300,
    Throughput: 400,
    Infrastructure: 500,
    Network: 110,
}

const I18N_MAP = {
    Node: i18n.t('billings.nodes'),
    Storage: i18n.t('billings.storage'),
    Usage: i18n.t('billings.usage'),
    StorageCost: i18n.t('billings.storageCost'),
    Infrastructure: i18n.t('billings.scaleFabric'),
    IOPS: i18n.t('launchService.provisionedIOPs'),
    Throughput: i18n.t('launchService.storageThroughput'),
    Network: i18n.t('billings.secondaryEndpoint'),
}

const nodeUnits = ['Compute Hours', 'Node Hours', 'SCU Hours']
const unitsForHours = ['Compute Hour', 'Node Hour', 'SCU Hour']

const BILLING_METERS = {}

export function getMultiplierFactor(topology, nodeCount) {
    // Update:: Primary/replica config starts with zero replicas on Portal.
    // so there is no need to add extra 1 for the nodeCount
    // Keeping the function signature intact as to extend in future in case things change
    if (topology) return nodeCount
}

export function getTotalPricingForInstances(
    topology,
    instanceName,
    nodeCount,
    billingData,
    duration = 'hour'
) {
    const instancePrice = billingData?.find(
        (instancePricing) => instanceName === instancePricing.name.toLowerCase()
    )
    if (!instancePrice) return null
    const multiplierFactor = getMultiplierFactor(topology, nodeCount)
    return (
        parseFloat(getBillingRateForDuration(duration, instancePrice)) *
        multiplierFactor
    )
}

export function getTotalPricingForStorage(
    topology,
    diskSize,
    nodeCount,
    storageCost,
    duration = 'hour'
) {
    const multiplierFactor = getMultiplierFactor(topology, nodeCount)
    return (
        parseFloat(getBillingRateForDuration(duration, storageCost)) *
        diskSize *
        multiplierFactor
    )
}

export function getTotalPricingForIOPS(
    topology,
    diskSize,
    nodeCount,
    iops,
    iopsCost,
    duration = 'hour'
) {
    const multiplierFactor = getMultiplierFactor(topology, nodeCount)
    return (
        parseFloat(getBillingRateForDuration(duration, iopsCost)) *
        iops *
        diskSize *
        multiplierFactor
    )
}

export function getTotalPricingForScaleFabric(
    topology,
    instanceName,
    nodeCount,
    billingData,
    duration = 'hour'
) {
    const instancePrice = billingData?.find(
        (instancePricing) => instanceName === instancePricing.name.toLowerCase()
    )
    if (!instancePrice) return null
    const multiplierFactor = getMultiplierFactor(topology, nodeCount)

    let billingRateForInfra = 0
    if (duration === 'hour') {
        billingRateForInfra = instancePrice.infraPricePerHour
    } else if (duration === 'month') {
        billingRateForInfra = instancePrice.infraPricePerMonth
    } else {
        billingRateForInfra = instancePrice.infraPricePerMin
    }

    return parseFloat(billingRateForInfra) * multiplierFactor
}

function getBillingRateForDuration(duration, billingData) {
    if (duration === 'hour') return billingData?.pricePerHour
    if (duration === 'minutes') return billingData?.pricePerMin
    else return billingData?.pricePerMonth
}

export function prepareUsageByServices(usageData) {
    let usageDataByServiceMap = setupChildrenForAttribute(
        usageData,
        'configurationItem'
    )

    const usageServiceArrays = Object.values(usageDataByServiceMap)
    return usageServiceArrays.map(({ title, children, topology, provider, source, }) => {
        const entitySegregatedChildren = Object.values(
            setupChildrenForAttribute(children, 'category')
        )
        let mappedChildren = entitySegregatedChildren
            .map(entity => ({
                title: I18N_MAP[entity.title],
                children: entity.children.map(mapBillingUsageEntityByLineItem),
                order: SORT_ORDER[entity.title],
            }))
            .map((entity) => ({
                ...entity,
                cost: entity.children.reduce((total, child) => {
                    return child.cost + total
                }, 0),
            })).sort((a, b) => a.order - b.order)

        return {
            title,
            source,
            subTitle: i18n.t('billings.topologyOnProvider', { topology: getTopologyTitle(topology), provider: provider.toUpperCase(), }),
            children: mappedChildren,
            cost: mappedChildren.reduce((total, child) => {
                return child.cost + total
            }, 0),
        }
    })
}

export function prepareUsageByEntities(usageData) {
    let usageDataByEntityMap = setupChildrenForAttribute(
        usageData,
        'category'
    )

    const usageEntityArray = Object.values(usageDataByEntityMap)
    return usageEntityArray.map(({ title, children, }) => {
        children = children.map(usage => ({ ...usage, title: getTitleForEntity(usage), }))

        const entitySegregatedChildren = Object.values(
            setupChildrenForAttribute(children, 'title')
        )
        let mappedChildren = entitySegregatedChildren
            .map(entity => ({
                title: entity.title,
                children: entity.children.map(mapBillingUsageEntityByServiceName),
            }))
            .map((entity) => ({
                ...entity,
                cost: entity.children.reduce((total, child) => {
                    return child.cost + total
                }, 0),
            })).sort((a, b) => a.order - b.order)

        return {
            title: I18N_MAP[title],
            children: mappedChildren,
            cost: mappedChildren.reduce((total, child) => {
                return child.cost + total
            }, 0),
            order: SORT_ORDER[title],
        }
    }).sort((a, b) => a.order - b.order)
}

function setupChildrenForAttribute(usageData, attribute) {
    let hashMap = {}
    usageData.forEach((usage) => {
        const type = usage[attribute]
        if (!hashMap[type]) {
            hashMap[type] = {}
            hashMap[type].title = type
            hashMap[type].provider = usage.provider
            hashMap[type].topology = usage.topology
            hashMap[type].source = usage.source
            hashMap[type].component = usage.component
            hashMap[type].children = []
        }
        hashMap[type].children.push(usage)
    })
    return hashMap
}

export function getTopologyTitle(topology) {
    let topologyTitle = ''
    // For SN and Primary/Replica topologies, we need to append the server types
    // when displaying on the billing usage view
    if (isStandAloneTopology(topology) || isPrimaryReplica(topology)) {
        topologyTitle = i18n.t(`serverType.${topology}`) + ' '
    }
    topologyTitle += i18n.t(`topology.${topology}`)
    return topologyTitle
}

export function getMaxScaleRedundancyLabel(nodes, size, isValue) {
    return isValue ? `${capitalize(size)} (x${nodes})` : `x${nodes} ${i18n.t('maxScaleRedundancy')} ${capitalize(size)}`
}

export function getFilteredInstanceCosts(instanceCosts = [], params) {
    // Filter out the instance costing for
    // the filtered Instances
    const { provider, topology, arch, } = params
    return instanceCosts.filter((cost) => {
        const nodeType = getNodeTypeForPricingAPI(topology)
        return (
            cost.topology === topology &&
            cost.provider === provider &&
            cost.node === nodeType &&
            (!arch || cost.arch.toLowerCase() === arch)
        )
    })
}
export function getTotalPricingForGP3IOPS(
    topology,
    iops,
    nodeCount,
    iopsCost,
    duration = 'hour'
) {
    const multiplierFactor = getMultiplierFactor(topology, nodeCount)
    return (
        parseFloat(getBillingRateForDuration(duration, iopsCost)) *
        iops *
        multiplierFactor
    )
}
export function getTotalPricingForGP3Throughput(
    topology,
    throughput,
    nodeCount,
    throughputCost,
    duration = 'hour'
) {
    const multiplierFactor = getMultiplierFactor(topology, nodeCount)
    return (
        parseFloat(getBillingRateForDuration(duration, throughputCost)) *
        throughput *
        multiplierFactor
    )
}

export function getPricingForEndpoint(prices, duration = 'hour') {
    return parseFloat(prices[0].unitPrice) * getDurationMultiplier(duration)
}

function getDurationMultiplier(duration) {
    switch (duration) {
    case 'minute':
        return 1
    case 'hour':
        return 60
    case 'month':
        // days * hour * minutes
        return 30 * 24 * 60
    }
}

export function isRemittanceUser(billingRecord={}){
    return t(billingRecord, 'remittance').safeBoolean
}

export function hasPaymentProfile(paymentProfile=[], billingRecord=[]){
    return paymentProfile.length > 0 && billingRecord?.subscription?.status?.toLowerCase() === 'active'
}

export function getLegalEntityIdentifier(billingRecord={}){
    return t(billingRecord, 'legalEntityIdentifier').safeString
}

export function isPaymentSetupPendingForUser({
    billingRecord,
    paymentProfile,
}) {
    if (isRemittanceUser(billingRecord)) {
        return false
    }
    return !hasPaymentProfile(paymentProfile, billingRecord)
}

export function isMonthYearForNewBilling(monthYear) {
    return monthYear.year >= 2025 && monthYear.month >= 2
}

export function getNodeAttributes(object) {
    return {
        runtime: object?.metricValue * object?.hours,
        unit: object?.unit === 'Compute Hours' ? 'Node Hours' : object?.unit,
        perMinCost: object?.rate,
        cost: object?.total,
        rateUnit: unitsForHours.includes(object?.rateUnit) ? 'Hour' : object?.rateUnit,
    }
}

export function getStorageAttributes(object) {
    return {
        runtime: object?.metricValue * object?.quantity * object?.hours,
        unit: object?.unit || '',
        perMinCost: object?.rate,
        cost: object?.total,
        rateUnit: object?.rateUnit || '',
    }
}

export function getTitleForNonNodeEntity(item) {
    let title = ''
    switch (item.unit) {
    case 'GB Hours': {
        if (item.dimensions?.backupStorage) {
            title = item.dimensions?.backupStorage.charAt(0).toUpperCase() + item.dimensions?.backupStorage.slice(1)
        } else {
            title = item.dimensions?.storageType?.toUpperCase() || ''
        }
        break
    }
    case 'IOPS Hours': {
        title = 'IOPS'
        break
    }
    case 'Throughput': {
        title = 'Throughput'
        break
    }
    default: {
        title = item.unit
        break
    }
    }
    return title
}

export function getTitleForNodeEntity(item, isForSpending = false) {
    let title = item.unit
    if (item.unit === 'SCU Hours') {
        title = isForSpending ? 'SCU-h Consumed' : 'Serverless Compute Instance'
    } else if (item.dimensions?.size) {
        title = item.dimensions?.size.charAt(0).toUpperCase() + item.dimensions?.size.slice(1)
    }
    return title
}

export function getLineItemChildren(lineItems) {
    let total = 0
    const children = []
    lineItems.forEach(item => {
        total += item.total
        if (nodeUnits.includes(item.unit)) {
            children.push({
                title: getTitleForNodeEntity(item),
                ...getNodeAttributes(item),
                activeNodes: item.unit === 'SCU Hours' ? 0 : item.quantity,
            })
        } else {
            children.push({
                title: getTitleForNonNodeEntity(item),
                ...getStorageAttributes(item),
            })
        }
    })
    return {
        total,
        children,
    }
}

export async function getMeterInfo(meterId) {
    if (BILLING_METERS[meterId]) {
        return BILLING_METERS[meterId]
    }
    const [error, response] = await to(getMeterById(meterId))
    if (error || !response) return
    BILLING_METERS[meterId] = response
    return response
}

export async function getNewUsageChildData(item, isFreeTrialActive, unleash) {
    let children = []

    if (!item?.lineItems) return children

    const lineItems = Object.values(item?.lineItems || {})

    for (const meterItems of lineItems) {

        const meter = await getMeterInfo(meterItems[0]?.meter)

        if (!meter) return

        const title = meter?.label || ''

        const childItems = getLineItemChildren(meterItems)
        const child = {
            title,
            cost: childItems.total,
            children: childItems.children,
        }

        if (shouldShowServerlessTip(child, isFreeTrialActive, unleash)) {
            child.tooltip = i18n.t('billings.serverlessComputeTotalTip')
        } else if (meter?.name?.includes('backup')) {
            child.tooltip = i18n.t('billings.backupBillingTooltip')
        }
        children.push(child)
    }

    return children
}

function shouldShowServerlessTip(node, isFreeTrialActive, unleash) {
    return !isFreeTrialActive &&
        unleash.isFeatureEnabled('enable-serverless-storage-scaling') &&
        node.children.some(child => child.title.toLowerCase().includes('serverless'))
}


export function getTransformedUsageData(usageData) {
    const usageItem = usageData?.items?.[0]
    const resourceDimensions = usageItem?.resource?.dimensions
    const data = {
        components: [],
        currency: usageData?.currency || 'USD',
        estimate: usageData?.total,
        provider: resourceDimensions?.provider,
        serviceId: usageItem?.resource?.resourceId,
        name: usageItem?.resource?.name,
        symbol: usageData?.symbol || '$',
        topology: resourceDimensions?.topology,
        region: resourceDimensions?.region,
    }

    if (resourceDimensions?.size) {
        data.size = resourceDimensions.size
    }

    data.components = Object.values(usageItem?.lineItems || {}).flat()?.map(item => {
        return {
            name: nodeUnits.includes(item?.unit) ? getTitleForNodeEntity(item, true) : getTitleForNonNodeEntity(item),
            usageUnit: item?.unit,
            currency: usageData?.currency || 'USD',
            symbol: usageData?.symbol || '$',
            price: item?.rate,
            quantity: nodeUnits.includes(item?.unit) ?
                (item?.metricValue * item?.hours) : (item?.metricValue * item?.quantity * item?.hours),
            estimate: item?.total,
        }
    })

    return data
}
