import _ from 'lodash'

export function parseThroughputCost (throughputCostResponse = {}, params = {}) {
    const throughputCost = throughputCostResponse[_.camelCase(params['meter_id'])]?.map(item => {
        return {
            'name': item.dimensions?.storageType,
            'product': '',
            'topology': item.dimensions?.topology || params.topology,
            'provider': item.dimensions?.provider || params.provider,
            'size': 1,
            'unit': 'GB',
            'prices': item.hasOwnProperty('startRange') ? [
                {
                    'startingQuantity': 1,
                    'endingQuantity': Math.floor(item.startRange),
                    'unitPrice': 0,
                },
                {
                    'startingQuantity': Math.ceil(item.startRange),
                    'unitPrice': item.price?.pricePerMin,
                }
            ] : [
                {
                    'startingQuantity': 1,
                    'unitPrice': item.price?.pricePerMin,
                }
            ],
            'pricePerMin': item.price?.pricePerMin,
            'pricePerHour': item.price?.pricePerHour,
            'pricePerMonth': item.price?.pricePerMonth,
        }
    })
    return throughputCost
}
