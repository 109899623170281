<style lang="scss" scoped>
.billing-summary {
    border: 1px solid $cornsilk;
    color: $deep-ocean;

    &-new {
        min-height: 300px;
    }
}
.separator {
    width: 100%;
    height: 1px;
    border-top: 1px solid $cornsilk;
    margin: 8px 0;
}

::v-deep .legend-label {
    min-width: 140px;
    justify-content: space-between;
}

.total-section-head {
    gap: 4px;
}
.total-section-separator {
    height: 100%;
    border: 1px solid;
}
.commit-section {
    position: relative;

    .details-icon {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-75%) rotate(-90deg);
    }
}
.details-sec {
    border-left: 1px solid $cornsilk;
    padding-left: 8px;
}
.min-max-width-120 {
    min-width: 120px;
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>

<template>
    <div v-if="showNewUI">
        <v-row
            class="billing-summary mt-0"
            :class="{'billing-summary-new': showNewUI}">
            <!-- snapshot donut chart -->
            <v-col
                cols="6"
                class="d-flex flex-column">
                <DonutChart
                    v-if="!isHistoricalBillEmpty"
                    :data="chartData"
                    :options="options"
                    :is-flat-data="true" />
                <v-row
                    class="total-wrap"
                    :class="{ 'ml-1': isPrepaid, 'ml-4': !isPrepaid}">
                    <v-col
                        cols="5"
                        class="d-flex justify-space-between pr-0">
                        <div class="d-flex flex-column flex-shrink-0">
                            <div class="total-section-head align-baseline d-flex">
                                <span class="font-weight-bold text-body-2">{{ $t('billings.totalCharges') }}</span>
                                <span class="text-caption text--disabled">({{ selectedMonth }})</span>
                            </div>
                            <div>
                                <span class="font-weight-bold">{{ $t('currency', [historicalBills?.total?.toFixed(2)]) }}</span>
                            </div>
                        </div>
                        <div class="total-section-separator"></div>
                    </v-col>
                    <v-col
                        cols="7"
                        class="d-flex flex-column commit-section pointer"
                        @click="showLedgerDetails = !showLedgerDetails">
                        <template v-if="isPrepaid">
                            <div class="total-section-head align-baseline d-flex">
                                <span class="font-weight-bold text-body-2">{{ $t('billings.creditBalance') }}</span>
                                <span class="text-caption text--disabled">({{ $t('billings.availablePostThisBill') }})</span>
                            </div>
                            <div>
                                <span class="font-weight-bold">{{ $t('currency', [historicalBills?.remainingCredit?.toFixed(2)]) }}</span>
                            </div>
                        </template>
                        <template v-if="isPostpaid">
                            <div class="total-section-head align-baseline d-flex">
                                <span class="font-weight-bold text-body-2">{{ $t('billings.unusedComputeCommit') }}</span>
                            </div>
                            <div>
                                <span class="font-weight-bold">{{ $t('currency', [historicalBills?.unusedComputeCommit?.toFixed(2)]) }}</span>
                            </div>
                        </template>
                        <v-icon
                            size="13"
                            class="details-icon">
                            $expand
                        </v-icon>
                    </v-col>
                </v-row>
            </v-col>

            <!-- bill trend -->
            <v-col cols="6">
                <div
                    v-if="showLedgerDetails"
                    class="details-sec">
                    <div class="d-flex justify-space-between align-center pb-1">
                        <span class="font-weight-bold">{{ ledgerHeading }}</span>
                        <v-btn
                            class="close"
                            tabindex="-1"
                            small
                            icon
                            @click="showLedgerDetails = false">
                            <v-icon small>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                    <v-data-table
                        dense
                        hide-default-footer
                        disable-filtering
                        disable-pagination
                        disable-sort
                        :loading="isFetchingLedger"
                        :loader-height="2"
                        :headers="headers"
                        :items="ledgerList"
                        item-key="id"
                        height="280px">
                        <template #item="{ item: ledgerItem }">
                            <tr>
                                <template v-if="isPrepaid">
                                    <td
                                        class="text-capitalize min-max-width-120"
                                        :title="$help.capitalize(ledgerItem.type)">
                                        {{ ledgerItem.type }}
                                    </td>
                                    <td
                                        class="text-capitalize"
                                        :title="$help.capitalize(ledgerItem.reason)">
                                        <span class="max-line-2">{{ ledgerItem.reason }}</span>
                                    </td>
                                    <td class="min-max-width-120">
                                        <span>{{ $moment( ledgerItem.createdAt).format('DD-MM-YYYY') }}</span>
                                    </td>
                                    <td
                                        :class="{ 'success--text': ledgerItem.amount > 0, 'error--text': ledgerItem.amount < 0 }">
                                        {{ $t('currency', [Math.abs(ledgerItem.amount)?.toFixed(2)]) }}
                                    </td>
                                </template>
                                <template v-if="isPostpaid">
                                    <td class="min-max-width-120">
                                        <span>{{ $moment(ledgerItem.period).format('MMM, YYYY') }}</span>
                                    </td>
                                    <td>{{ $t('currency', [ledgerItem.invoiceAmount?.toFixed(2)]) }}</td>
                                    <td>{{ $t('currency', [ledgerItem.minComputeCommit?.toFixed(2)]) }}</td>
                                    <td
                                        :class="{ 'success--text': ledgerItem.unusedComputeCommit > 0, 'error--text': ledgerItem.unusedComputeCommit < 0 }">
                                        {{ $t('currency', [Math.abs(ledgerItem.unusedComputeCommit)?.toFixed(2)]) }}
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
    </div>
    <div v-else>
        <v-row class="billing-summary pa-2">
            <v-col cols="2">
                {{ $t('billings.usageDays') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ historicalBills?.usageDays }}
                </div>
            </v-col>
            <v-col cols="2">
                {{ $t('billings.computeCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalComputeCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                {{ $t('billings.storageCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalStorageCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                {{ $t('billings.dataTransferCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.dataTransferCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                <div class="align-center d-flex">
                    <span class="mr-1">
                        {{ $t('billings.supportCost') }}
                    </span>
                    <HelpToolTip
                        :size="16">
                        <template #message>
                            <p>
                                {{ $t('billings.supportType',[historicalBills?.supportType]) }}
                            </p>
                        </template>
                    </HelpToolTip>
                </div>
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.supportCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                <span class="font-weight-bold">{{ $t('total') }}</span>
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.total?.toFixed(2)]) }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import DonutChart from 'components/monitoring/widgets/DonutChart'
    import HelpToolTip from 'components/common/HelpTooltip'
    import i18n from 'plugins/i18n'

    const HistoricalBillSummary = {
        name: 'HistoricalBillSummary',
        components: { DonutChart, HelpToolTip, },
        props: {
            historicalBills: {
                required: true,
                type: Object,
            },
        },
        data: () => ({
            showLedgerDetails: false,
            options: {
                format: {
                    type: 'currencyUSD',
                    decimals: 2,
                },
                apex: {
                    chart: {
                        type: 'donut',
                    },
                    legend: {
                        formatter: (seriesName, opts) => {
                            return `
                                <div class="legend-label d-flex align-center">
                                    <div class="legend-text">${seriesName}</div>
                                    <div class="legend-value">${i18n.t('currency', [opts.w.globals.series[opts.seriesIndex]])}</div>
                                </div>
                            `
                        },
                    },
                    tooltip: {
                        custom: function({series, seriesIndex, w, }) {
                            return `
                                <div
                                    class="d-flex align-center pl-2 pr-2 pt-1 pb-1 text-caption"
                                    style="background-color: ${w.globals.colors[seriesIndex]};">
                                    <div class="legend-text">${w.globals.labels[seriesIndex]}:</div>
                                    <div class="legend-value ml-1">${i18n.t('currency', [series[seriesIndex]])}</div>
                                </div>
                            `
                        },
                    },
                },
                combineThreshold: -1,
            },
            prepaidHeaders: [
                { text: i18n.t('type'), value: 'type', class: 'grey lighten-3', },
                { text: i18n.t('billings.reason'), value: 'reason', class: 'grey lighten-3', },
                { text: i18n.t('billings.on'), value: 'createdAt', class: 'grey lighten-3', },
                { text: i18n.t('billings.amount'), value: 'amount', class: 'grey lighten-3', }
            ],
            postpaidHeaders: [
                { text: i18n.t('billings.period'), value: 'period', class: 'grey lighten-3', },
                { text: i18n.t('billings.invoiceAmount'), value: 'invoiceAmount', class: 'grey lighten-3', },
                { text: i18n.t('billings.minComputeCommit'), value: 'minComputeCommit', class: 'grey lighten-3', },
                { text: i18n.t('billings.unusedComputeCommit'), value: 'unusedComputeCommit', class: 'grey lighten-3', }
            ],
        }),
        computed: {
            ...mapGetters([
                'isFetchingCreditLedger',
                'creditLedger',
                'isFetchingCommitLedger',
                'commitLedger'
            ]),
            showNewUI () {
                return this.$unleash.isFeatureEnabled('enable-new-historical-bills')
            },
            chartData () {
                return [{
                    label: this.$t('billings.computeCost'),
                    value: this.historicalBills?.totalComputeCost,
                }, {
                    label: this.$t('billings.storageCost'),
                    value: this.historicalBills?.totalStorageCost,
                }, {
                    label: this.$t('billings.dataTransferCost'),
                    value: this.historicalBills?.dataTransferCost,
                }, {
                    label: this.$t('billings.supportCost'),
                    value: this.historicalBills?.supportCost,
                }]
            },
            isHistoricalBillEmpty () {
                return this.$lodash.isEmpty(this.historicalBills)
            },
            selectedMonth () {
                return this.$moment(this.historicalBills?.period).format('MMM, YYYY')
            },
            isPrepaid () {
                return this.historicalBills?.billingMode?.toLowerCase() === 'prepaid'
            },
            isPostpaid () {
                return this.historicalBills?.billingMode?.toLowerCase() === 'postpaid'
            },
            ledgerHeading () {
                return this.isPrepaid ? this.$t('billings.creditLedger') : this.$t('billings.commitLedger')
            },
            headers () {
                return this.isPrepaid ? this.prepaidHeaders : this.postpaidHeaders
            },
            ledgerList () {
                return this.isPrepaid ? this.creditLedger : this.commitLedger
            },
            isFetchingLedger () {
                return this.isPrepaid ? this.isFetchingCreditLedger : this.isFetchingCommitLedger
            },
        },
    }
    export default HistoricalBillSummary
</script>
