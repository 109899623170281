<style lang="scss" scoped>
    .chart-wrapper {
        width: 100%;
        height: 100%;
    }
</style>

<template>
    <apexchart
        class="chart-wrapper"
        :class="{ 'no-data-chart': noData }"
        width="100%"
        height="100%"
        type="donut"
        :options="fullOptions.apex"
        :series="series" />
</template>

<script>
    export default {
        name: 'DonutChart',
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            options: {
                type: Object,
                default: () => {},
            },
            isFlatData: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                defaultOptions: {
                    combineThreshold: 0.05,
                    combineLabel: 'Others',
                    apex: {
                        chart: {
                            toolbar: {
                                show: false,
                            },
                        },
                        labels: [],
                        tooltip: {
                            y: {},
                        },
                    },
                },
                fullOptions: {},
                series: [],
                noData: false,
            }
        },
        watch: {
            data (newVal) {
                this.parseData(newVal)
            },
        },
        methods: {
            parseData (dataPoints) {
                this.noData = false
                const series = []
                const labels = []
                let data = []

                if (this.isFlatData) {
                    data = dataPoints
                } else {
                    dataPoints.forEach(({ result, resultType, }) => {
                        const vectorData = resultType === 'vector' ? this.mapVector(result) : this.mapMatrix(result)
                        data = data.concat(vectorData)
                    })
                }

                // Threshold
                const { combineThreshold, combineLabel, } = this.fullOptions
                const totalVal = data.reduce((prev, { value, }) => prev + value, 0)
                let othersValue = 0

                data.forEach(({ label, value, }) => {
                    if (value / totalVal <= combineThreshold) {
                        othersValue += value
                    } else {
                        labels.push(label)
                        series.push(value)
                    }
                })
                if (othersValue > 0) {
                    series.push(othersValue)
                    labels.push(combineLabel)
                }
                if (series?.length) {
                    this.fullOptions.apex.labels = labels
                    this.fullOptions.apex = { ...this.fullOptions.apex, }
                    this.series = series
                } else if (this.options?.hideError) {
                    this.fullOptions.apex.tooltip.enabled = false
                    this.fullOptions.apex = { ...this.fullOptions.apex, }
                    this.series = [1]
                    this.noData = true
                }
            },
            mapVector(data) {
                return data.map(({ metric, label, value, }) => {
                    return {
                        label: this.formatLabel(label || JSON.stringify(metric)),
                        value: parseFloat(value[1]),
                    }
                })
            },
            mapMatrix(data) {
                return data.map(({ metric, label, values, }) => {
                    return {
                        label: this.formatLabel(label || JSON.stringify(metric)),
                        value: values.reduce((prev, value) => prev + parseFloat(value[1]), 0),
                    }
                })
            },
            formatLabel (label = '') {
                return this.$help.capitalize(label.replaceAll('_', ' '))
            },
        },
        async created () {
            const options = this.$lodash.merge(this.defaultOptions, this.options)
            if (options.format) {
                options.apex.tooltip.y.formatter = (value) => {
                    return this.$format(options.format.type, value, options.format.decimals)
                }
            }
            this.fullOptions = options
            this.parseData(this.data)
        },
    }
</script>
