// TODO: Reevaluate those components and refactor
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import SearchBox from 'components/common/SearchBox'
import LabelChip from 'components/common/LabelChip'
import IconSpriteSheet from 'components/common/IconSpriteSheet'
import DataTable from 'components/common/DataTable'
import BaseDialog from 'components/common/BaseDialog'
import TruncateString from 'components/common/TruncateString'
import InboundReplicationInfo from 'components/common/InboundReplicationInfo'
import DateRangePicker from 'components/common/DateRangePicker'
import TagListing from 'components/alerts/TagListing'
import CopilotChatMessage from 'components/copilot/ChatMessage.vue'

export default {
    'overlay-scrollbars': OverlayScrollbarsComponent,
    'data-table': DataTable,
    'base-dialog': BaseDialog,
    'search-box': SearchBox,
    'label-chip': LabelChip,
    'icon-sprite-sheet': IconSpriteSheet,
    'truncate-string': TruncateString,
    'inbound-replication-info': InboundReplicationInfo,
    'date-range-picker': DateRangePicker,
    'tag-listing': TagListing,
    'chat-message': CopilotChatMessage,
}
