<style lang="scss" scoped>
.header-row {
    background: rgba(232, 238, 241, 0.4);
    border: 1px solid $cornsilk;

    .service-meta {
        color: $grayed-out;
    }

    .source-text{
        color: $azure
    }
}
</style>
<template>
    <div
        v-if="hasData"
        class="text-subtitle-2">
        <v-row class="header-row">
            <v-col
                :class="[{ 'pointer': hasChildren }]"
                cols="6"
                @click="hasChildren ? toggleHeaderTab() : null">
                <v-icon
                    :class="['mr-2', { 'rotate--right': !headerExpanded, 'visibility--none': !hasChildren }]"
                    size="13">
                    $expand
                </v-icon>
                <span class="font-weight-bold">{{ accordionData.title }}</span>
                <span class="ml-2 service-meta">{{ accordionData.subTitle }}</span>
            </v-col>
            <v-col cols="3" />
            <v-col class="font-weight-bold text-right pr-0">
                {{ $t('currency', [accordionData.cost?.toFixed(2)]) }}
            </v-col>
            <v-col cols="2" />
        </v-row>
        <template v-if="headerExpanded">
            <div v-if="isOneLevelTree">
                <BillingPanelLeafNodes
                    :node="accordionData" />
            </div>
            <div v-else>
                <BillingPanelChildNodes
                    v-for="(childNode,index) in accordionData.children"
                    :key="index"
                    :node="childNode"
                    :all-expanded="allExpanded"
                    :child-index="index" />
            </div>
        </template>
    </div>
</template>

<script>
    import BillingPanelChildNodes from 'components/billing/BillingPanelChildNodes.vue'
    import BillingPanelLeafNodes from 'components/billing/BillingPanelLeafNodes.vue'
    import HelpToolTip from 'components/common/HelpTooltip.vue'

    const BillingAccordionPanel = {
        name: 'BillingAccordionPanel',
        components: { BillingPanelChildNodes, BillingPanelLeafNodes, HelpToolTip, },
        props: {
            accordionData: {
                type: Object,
                default: () => { },
                required: false,
            },
            identifier: {
                type: String,
                default: 0,
                required: true,
            },
            isOneLevelTree: {
                type: Boolean,
                default: false,
                required: false,
            },
            allExpanded: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                headerExpanded: false,
            }
        },
        watch: {
            allExpanded: {
                immediate: true,
                handler(newVal) {
                    this.headerExpanded = newVal
                },
            },
        },
        computed:{
            hasData(){
                return this.accordionData && this.accordionData?.title
            },
            hasChildren() {
                const { children, } = this.accordionData
                return children && children.length
            },
        },
        methods: {
            toggleHeaderTab() {
                this.headerExpanded = !this.headerExpanded
            },
        },
    }
    export default BillingAccordionPanel
</script>
