<template>
    <v-row justify="center">
        <v-dialog
            v-model="isDialogOpen"
            persistent
            max-width="800px"
            scrollable>
            <v-card>
                <v-card-title class="pt-7 px-10">
                    <span class="text-h4 font-weight-medium">
                        {{ $t('backups.restoreFromExternalStorage') }}
                    </span>
                </v-card-title>
                <v-card-text class="overflow-visible">
                    <v-container style="min-height: 200px;">
                        <v-row>
                            <v-col cols="6">
                                <div>
                                    <label class="text-uppercase required">
                                        {{ $tc('service') }}
                                    </label>
                                    <div>
                                        <v-select
                                            v-model="selectedServiceId"
                                            :items="serviceItems"
                                            class="std std--dropdown"
                                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list', maxHeight: '200px' }"
                                            height="36"
                                            outlined
                                            item-value="id"
                                            attach
                                            required
                                            hide-details>
                                            <template #item="{ item }">
                                                {{ item.name }}
                                            </template>
                                            <template #selection="{ item }">
                                                {{ item.name }}
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                cols="5"
                                class="px-0 pb-0">
                                <label class="text-uppercase">
                                    {{ $t('launchService.cloudProvider') }}
                                </label>
                                <v-radio-group
                                    v-model="provider"
                                    row
                                    class="mt-0">
                                    <v-radio
                                        label="AWS"
                                        value="aws" />
                                    <v-radio
                                        label="GCP"
                                        value="gcp" />
                                </v-radio-group>
                            </v-col>
                            <v-col
                                cols="6"
                                class="py-0">
                                <label class="required text-uppercase">
                                    {{ $t('backups.id') }}
                                </label>
                                <v-text-field
                                    v-model="id"
                                    :placeholder="$tc('backups.backupDataFileReference', provider === 'aws' ? 1 : 2)"
                                    outlined
                                    dense />
                            </v-col>
                            <v-col
                                cols="5"
                                class="px-0 pb-0">
                                <label class="text-uppercase">
                                    {{ $t('backups.restoreType') }}
                                </label>
                                <v-radio-group
                                    v-model="method"
                                    row
                                    class="mt-0">
                                    <v-radio value="mariabackup">
                                        <template #label>
                                            <div class="align-center d-flex">
                                                <span class="mr-1">{{ $t('backups.physical') }}</span>
                                                <HelpToolTip :size="16">
                                                    <template #message>
                                                        <p>{{ $t('backups.mariabackup') }}</p>
                                                    </template>
                                                </HelpToolTip>
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="mysqldump">
                                        <template #label>
                                            <div class="align-center d-flex">
                                                <span class="mr-1">{{ $t('backups.logical') }}</span>
                                                <HelpToolTip :size="16">
                                                    <template #message>
                                                        <p>{{ $t('backups.mysqldump') }}</p>
                                                    </template>
                                                </HelpToolTip>
                                            </div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0">
                                <label class="required text-uppercase">
                                    {{ $t('backups.storagePath') }}
                                </label>
                                <v-text-field
                                    v-model="path"
                                    :placeholder="provider === 'aws' ? 's3://my_backup_bucket' : 'gs://my_backup_bucket'"
                                    outlined
                                    dense />
                            </v-col>
                            <template v-if="provider === 'aws'">
                                <v-col
                                    cols="7"
                                    class="py-0">
                                    <label class="required text-uppercase">
                                        {{ $t('backups.accessKeyId') }}
                                    </label>
                                    <v-text-field
                                        v-model="accessKeyId"
                                        :placeholder="$t('backups.accessKeyId')"
                                        outlined
                                        dense />
                                </v-col>
                                <v-col
                                    cols="5"
                                    class="pt-0">
                                    <label class="required text-uppercase">
                                        {{ $tc('region') }}
                                    </label>
                                    <div>
                                        <v-select
                                            v-model="selectedRegion"
                                            :items="regionList"
                                            class="std std--dropdown"
                                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list', maxHeight: '200px' }"
                                            height="40"
                                            outlined
                                            attach
                                            required
                                            hide-details>
                                            <template #item="{ item }">
                                                {{ item }}
                                            </template>
                                            <template #selection="{ item }">
                                                {{ item }}
                                            </template>
                                        </v-select>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0">
                                    <label class="required text-uppercase">
                                        {{ $t('backups.accessKey') }}
                                    </label>
                                    <v-text-field
                                        v-model="accessKey"
                                        :placeholder="$t('backups.accessKey')"
                                        outlined
                                        dense />
                                </v-col>
                            </template>
                            <template v-else>
                                <v-col
                                    cols="12"
                                    class="py-0">
                                    <label class="required text-uppercase">
                                        {{ `${$t('backups.serviceAccountKey')} (JSON)` }}
                                    </label>
                                    <v-textarea
                                        v-model="serviceAccountKey"
                                        :placeholder="'{\n  &quot;type&quot;: &quot;service_account&quot;,\n  ...\n}'"
                                        outlined
                                        dense />
                                </v-col>
                            </template>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions class="px-9 py-4">
                    <a
                        target="_blank"
                        :href="`${$config.DOCS_URL}Backup%20and%20Restore/Restore%20From%20Your%20Own%20Bucket`">
                        {{ $t('learn-more') }}
                    </a>
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="onClose">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        class="text-none"
                        color="primary"
                        style="min-width: 100px;"
                        rounded
                        depressed
                        :disabled="!isFormValid"
                        :loading="isLoading"
                        @click="onSubmit">
                        {{ $t('restore') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import HelpToolTip from 'components/common/HelpTooltip'

    export default {
        name: 'ExternalRestoreModal',
        components: {
            HelpToolTip,
        },
        props: {
            showDialog: {
                type: Boolean,
                required: true,
            },
            isLoading: {
                type: Boolean,
            },
        },
        data () {
            return {
                isDialogOpen: false,
                selectedServiceId: '',
                serviceItems: [],
                provider: 'aws',
                id: '',
                path: '',
                method: 'mariabackup',
                accessKeyId: '',
                accessKey: '',
                selectedRegion: '',
                serviceAccountKey: '',
            }
        },
        computed: {
            ...mapGetters(['regions', 'services']),
            regionList() {
                return this.regions?.filter(region => region?.provider?.toLowerCase() === 'aws')?.map(region => region?.name)
            },
            isFormValid() {
                return this.selectedServiceId !== '' && this.id !== '' && this.isExternalStorageDetailsProvided
            },
            isExternalStorageDetailsProvided() {
                if (this.provider === 'aws')
                    return this.path !== '' && this.accessKey !== '' && this.accessKeyId !== '' && this.selectedRegion !== ''
                else
                    return this.path !== '' && this.serviceAccountKey !== ''
            },
        },
        watch: {
            showDialog() {
                this.isDialogOpen = this.showDialog
                if (this.isDialogOpen) {
                    this.loadData()
                }
            },
            selectedServiceId(newVal) {
                const service = this.serviceItems.find((service) => service.id === newVal)
                if (service && this.selectedBackupType === 'snapshot') {
                    this.provider = service.provider
                }
            },
        },
        methods: {
            ...mapActions(['getRegions', 'fetchServices']),
            onSubmit() {
                this.$emit('on-external-restore-submit', {
                    serviceId: this.selectedServiceId,
                    id: this.id,
                    externalSource: this.getExternalStorage(),
                })
            },
            onClose() {
                this.$emit('on-close')
            },
            getExternalStorageCredentials() {
                switch(this.provider) {
                    case 'aws':
                        // New lines are needed
                        return btoa(
                            `[default]
                            aws_access_key_id = ${this.accessKeyId}
                            aws_secret_access_key = ${this.accessKey}
                            region = ${this.selectedRegion}`
                        )
                    case 'gcp':
                        return btoa(this.serviceAccountKey)
                    default:
                        return ''
                }
            },
            getExternalStorage () {
                return {
                    bucket: this.path,
                    method: this.method,
                    credentials: this.getExternalStorageCredentials(),
                }
            },
            mapServices (services = []) {
                const validStatuses = ['ready', 'stopped', 'pendingScale', 'pendingModifying']
                return services.filter(
                    service => parseFloat(service.version) < 11.5 && validStatuses.includes(service.status)
                ).map(service => {
                    return {
                        id: service.id,
                        name: service.name,
                        provider: service.provider,
                    }
                })
            },
            async loadData () {
                if (!this.services?.length) {
                    await this.fetchServices()
                }
                if (!this.regions?.length) {
                    await this.getRegions()
                }
                this.serviceItems = this.mapServices(this.services)
            },
        },

    }
</script>
