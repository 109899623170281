<template>
    <div>
        <v-menu
            transition="slide-y-transition"
            offset-y>
            <template #activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                    @click="getConfig">
                    <v-icon v-if="!isButton">
                        mdi-dots-horizontal
                    </v-icon>
                    <v-btn
                        v-if="isButton"
                        outlined
                        rounded
                        color="primary"
                        class="text-capitalize">
                        <span class="pr-2">{{ $t('actions') }} </span>
                        <v-icon>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </div>
            </template>
            <v-list>
                <v-list-item
                    v-if="showEditDuplicate"
                    @click="edit">
                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="showEditDuplicate"
                    @click="duplicate">
                    <v-list-item-title>{{ $t('duplicate') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openApply">
                    <v-list-item-title>{{ $t('configManager.applyToService') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="!isDefaultConfig"
                    class="error--text"
                    @click="openDelete">
                    <v-list-item-title>{{ $t('configManager.deleteConfig') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <config-actions
            ref="deleteModal"
            :dialog-type="common.DIALOG_TYPE.DELETE"
            :on-action="deleteConfig" />
        <config-actions
            ref="applyModal"
            :dialog-type="common.DIALOG_TYPE.APPLY"
            :current-config="selectedConfig"
            @on-apply="applyConfigToService" />
        <config-actions
            ref="duplicateModal"
            :dialog-type="common.DIALOG_TYPE.DUPLICATE"
            :current-config="selectedConfig"
            @on-duplicate="createDuplicate" />
    </div>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import common from 'config/common'
    import ConfigActionsModal from 'components/alerts/ConfigActionsModal.vue'
    export default {
        name: 'ActionsMenu',
        components: {
            'config-actions': ConfigActionsModal,
        },
        props: {
            isButton: {
                type: Boolean,
                default: false,
                required: true,
            },
            currentConfig: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                common: common,
            }
        },
        computed: {
            ...mapGetters(['selectedConfig', 'deleteConfigSuccess', 'applyToServiceSuccess', 'newConfigResponse', 'selectedConfigDetails', 'selectedValuesListSuccess', 'removeConfigSuccess']),
            isDefaultConfig() {
                return this.currentConfig ? this.currentConfig.public : this.selectedConfig.public
            },
            showEditDuplicate() {
                return !this.isDefaultConfig && this.$unleash.isFeatureEnabled('enable-portal-edit-duplicate-config')
            },
        },
        methods: {
            ...mapMutations(['setSelectedConfig']),
            ...mapActions(['deleteSelectedConfig', 'getConfigsList', 'applyToService', 'createNewConfig', 'getSelectedConfig', 'setSelectedValuesList', 'removeConfig', 'fetchServices']),
            openDelete() {
                if (this.selectedConfig.services && this.selectedConfig.services.length) {
                    this.$store.commit('showMessage', { text: this.$t('configManager.cannotDelete'), type: common.messageType.ERROR, })
                } else {
                    this.$refs.deleteModal.open()
                }
            },
            openApply() {
                this.$refs.applyModal.open()
            },
            edit() {
                if (this.selectedConfig.services && this.selectedConfig.services.length) {
                    this.$store.commit('showMessage', { text: this.$t('configManager.cannotEdit'), type: common.messageType.ERROR, })
                } else {
                    this.$store.commit('toggleEditMode', true)
                    if (!this.$route.params.configName) this.$router.push(`/settings/configuration-manager/${this.selectedConfig.name}`)
                }
            },
            duplicate() {
                this.$refs.duplicateModal.open()
            },
            async createDuplicate(duplicateConfigName) {
                let config = this.selectedConfig
                if (config) {
                    let selectedValuesList = []
                    this.selectedConfigDetails.forEach(config => {
                        const { selected_values: selectedVal, id, } = config
                        if (selectedVal && selectedVal.length) {
                            selectedValuesList.push({
                                'config_key_id': id,
                                'selected_values': selectedVal,
                            })
                        }
                    })
                    let configObject = {
                        'name': duplicateConfigName,
                        'topology_id': config.topology_id,
                        'version_id': config.version_id,
                    }
                    await this.createNewConfig({ configObject: configObject, setAsSelected: true, })
                    if (this.newConfigResponse) {
                        await this.getConfigsList()
                        if (selectedValuesList.length) {
                            // Separate API call needed to save selected values if any
                            await this.setSelectedValuesList({ configId: this.newConfigResponse.id, selectedValuesList, })
                        }
                        if (!selectedValuesList.length || this.selectedValuesListSuccess) {
                            this.$store.commit('showMessage', { text: this.$t('success.duplicateConfig'), type: this.$config.messageType.SUCCESS, })
                            this.$router.push(`/settings/configuration-manager/${duplicateConfigName}`)
                        }
                    }
                }
            },
            async applyConfigToService(checkedServices) {
                let showSuccessMessage = () => {
                    this.$store.commit('showMessage', { text: this.$t('success.applyToService'), type: common.messageType.SUCCESS, })
                }
                for (let serviceId of checkedServices) {
                    if (this.selectedConfig.public) {
                        // Since 'default' config gets applied automatically only need to remove the applied 'custom' configs
                        await this.removeConfig({ serviceId, })
                        if (this.removeConfigSuccess) {
                            this.fetchServices() // To update the applied to services chips
                            showSuccessMessage()
                        }
                    } else {
                        await this.applyToService({ serviceId: serviceId, configId: this.selectedConfig.id, })
                        if (this.applyToServiceSuccess) showSuccessMessage()
                    }
                }
            },
            async deleteConfig() {
                await this.deleteSelectedConfig({ configId: this.selectedConfig.id, })
                if (this.deleteConfigSuccess) {
                    await this.getConfigsList()
                    this.$store.commit('showMessage', { text: this.$t('success.configDelete'), type: common.messageType.SUCCESS, })
                    if (this.$route.name !== this.$config.CONFIG_MANAGER_TITLE) this.$router.push('/settings/configuration-manager')
                }
            },
            async getConfig() {
                if (!this.isButton && !this.currentConfig.public) {
                    await this.getSelectedConfig({ configId: this.currentConfig.id, })
                }
                if (this.currentConfig.public) {
                    this.setSelectedConfig(this.currentConfig)
                }
            },
        },
    }
</script>
