import Vue from 'vue'
import to from 'await-to-js'
import _ from 'lodash'
import { t } from 'typy'
import common from 'config/common'
import i18n from 'plugins/i18n'
import {
    getInstancesCosts,
    getStorageCost,
    getIOPSCost,
    getCurrentBillingUsage,
    getBillingRecord,
    getPaymentMethods,
    editPayment,
    deletePayment,
    getCountries,
    getStatesForCountry,
    getInvoices,
    getThroughputCost,
    getEndpointCost,
    getHistoricalBills,
    getNewHistoricalBills,
    getCreditLedger,
    getCommitLedger,
    getTax,
    addNewTax,
    editTax,
    deleteTax,
    getContacts,
    addNewContact,
    editContact,
    deleteContact,
    getPrices,
    getOfferings,
    getOfferingMeters
} from 'services/billings'
import launchService from 'config/launchService'
import { isProviderAWS, isProviderGCP } from 'utils/service'
import { isMonthYearForNewBilling, getTransformedUsageData } from 'utils/billing'
export default {
    state: {
        instanceCosts: [],
        instanceCostsErrors: false,
        storageCosts: [],
        storageCostsErrors: false,
        iopsCosts: {},
        iopsCostsErrors: false,
        billingUsageDetails: [],
        billingUsageDetailsErrors: false,
        isFetchingBillingUsage: false,
        isFetchingInvoices: false,
        isPaymentDialogOpen: false,
        billingRecord: {},
        billingRecordErrors: false,
        paymentProfile: {},
        paymentProfileErrors: false,
        billingCountries: [],
        billingCountriesErrors: false,
        billingStates: [],
        billingStatesErrors: false,
        isFetchingBillingRecord: false,
        isFetchingPaymentDetail: false,
        servicesSpending: [],
        servicesSpendingErrors: false,
        serviceIdsSpendingFetchStatus: [],
        invoices: [],
        invoicesError: false,
        historicalUsage: [],
        gp3IopsCosts: {},
        throughputCosts: {},
        endpointPricing: [],
        endpointPricingErrors: false,
        gp3StorageCosts: {},
        isFetchingHistoricalBills: false,
        historicalBills: {},
        historicalBillsErrors: false,
        isFetchingCreditLedger: false,
        creditLedger: [],
        creditLedgerErrors: false,
        isFetchingCommitLedger: false,
        commitLedger: [],
        commitLedgerErrors: false,
        tax: null,
        taxError: false,
        isFetchingTax: false,
        savingTaxSuccess: false,
        deleteTaxSuccess: false,
        contacts: [],
        contactsError: false,
        isFetchingContacts: false,
        savingContactSuccess: false,
        deleteContactSuccess: false,
        updatingPaymentSuccess: false,
        deletePaymentSuccess: false,
        prices: [],
        pricesErrors: false,
        storageMetering: {},
        usageMetering: {},
        offerings: [],
        offeringsError: false,
        offeringMeters: [],
        offeringMetersError: false,
    },
    mutations: {
        setInstanceCosts(state, payload) {
            state.instanceCosts = payload
        },
        setInstanceCostsErrors(state, payload) {
            state.instanceCostsErrors = payload
        },
        setStorageCosts(state, payload) {
            state.storageCosts = payload
        },
        setStorageCostsErrors(state, payload) {
            state.storageCostsErrors = payload
        },
        setIOPSCosts(state, payload) {
            state.iopsCosts = payload
        },
        setIOPSCostsErrors(state, payload) {
            state.iopsCostsErrors = payload
        },
        setBillingUsageErrors(state, payload) {
            state.billingUsageDetailsErrors = payload
        },
        setFetchingUsageFlag(state, payload) {
            state.isFetchingBillingUsage = payload
        },
        setFetchingInvoicesFlag(state, payload) {
            state.isFetchingInvoices = payload
        },
        setPaymentDialog(state, payload) {
            state.isPaymentDialogOpen = payload
        },
        setBillingUsage(state, payload) {
            state.billingUsageDetails = payload
        },
        setBillingRecord(state, payload) {
            state.billingRecord = payload
        },
        setBillingRecordPhoneNumber(state, payload) {
            state.billingRecord = { ...state.billingRecord, primaryContact: payload, }
        },
        setBillingRecordErrors(state, payload) {
            state.billingRecordErrors = payload
        },
        setPaymentProfile(state, payload) {
            state.paymentProfile = payload
        },
        setPaymentProfileErrors(state, payload) {
            state.paymentProfileErrors = payload
        },
        setCountries(state, payload) {
            state.billingCountries = payload
        },
        setCountriesErrors(state, payload) {
            state.billingCountriesErrors = payload
        },
        setStates(state, payload) {
            state.billingStates = payload
        },
        setStatesErrors(state, payload) {
            state.billingStatesErrors = payload
        },
        setFetchingBillingRecord(state, payload) {
            state.isFetchingBillingRecord = payload
        },
        setFetchingPaymentDetail(state, payload) {
            state.isFetchingPaymentDetail = payload
        },
        setSpendingDetail(state, payload) {
            if (!_.isEmpty(payload)) {
                const index = state.servicesSpending?.findIndex(x => x.serviceId === payload?.serviceId)
                if (index > -1) {
                    Vue.set(state.servicesSpending, index, payload)
                } else
                    state.servicesSpending = [...state.servicesSpending, payload]
            }
        },
        setSpendingErrors(state, payload) {
            state.servicesSpendingErrors = payload
        },
        setServiceSpendingFetchStatus(state, payload) {
            if (payload?.status) {
                state.serviceIdsSpendingFetchStatus = [...state.serviceIdsSpendingFetchStatus, payload.serviceId]
            } else {
                state.serviceIdsSpendingFetchStatus = state.serviceIdsSpendingFetchStatus.filter(id => id !== payload.serviceId)
            }
        },
        setUpdatingPaymentSuccess(state, payload) {
            state.updatingPaymentSuccess = payload
        },
        setDeletePaymentSuccess(state, payload) {
            state.deletePaymentSuccess = payload
        },
        setInvoices(state, payload) {
            state.invoices = payload
        },
        setInvoicesErrors(state, payload) {
            state.invoicesError = payload
        },
        setHistoricalUsage(state, payload) {
            state.historicalUsage = payload
        },
        setGp3IOPSCosts(state, payload) {
            state.gp3IopsCosts = payload
        },
        setThroughputCosts(state, payload) {
            state.throughputCosts = payload
        },
        setThroughputCostsErrors(state, payload) {
            state.throughputCostsErrors = payload
        },
        setEndpointPricing(state, payload) {
            state.endpointPricing = payload
        },
        setEndpointPricingErrors(state, payload) {
            state.endpointPricingError = payload
        },
        setGp3StorageCosts(state, payload) {
            state.gp3StorageCosts = payload
        },
        setFetchingHistoricalBillsFlag(state, payload) {
            state.isFetchingHistoricalBills = payload
        },
        setHistoricalBills(state, payload) {
            state.historicalBills = payload
        },
        setHistoricalBillsErrors(state, payload) {
            state.historicalBillsErrors = payload
        },
        setFetchingCreditLedgerFlag(state, payload) {
            state.isFetchingCreditLedger = payload
        },
        setCreditLedger(state, payload) {
            state.creditLedger = payload
        },
        setCreditLedgerErrors(state, payload) {
            state.creditLedgerErrors = payload
        },
        setFetchingCommitLedgerFlag(state, payload) {
            state.isFetchingCommitLedger = payload
        },
        setCommitLedger(state, payload) {
            state.commitLedger = payload
        },
        setCommitLedgerErrors(state, payload) {
            state.commitLedgerErrors = payload
        },
        setTax(state, payload) {
            state.tax = payload
        },
        setTaxErrors(state, payload) {
            state.taxError = payload
        },
        setFetchingTaxFlag(state, payload) {
            state.isFetchingTax = payload
        },
        setSavingTaxSuccess(state, payload) {
            state.savingTaxSuccess = payload
        },
        setDeleteTaxSuccess(state, payload) {
            state.deleteTaxSuccess = payload
        },
        setContacts(state, payload) {
            state.contacts = payload
        },
        setContactsErrors(state, payload) {
            state.contactsError = payload
        },
        setFetchingContactsFlag(state, payload) {
            state.isFetchingContacts = payload
        },
        setSavingContactSuccess(state, payload) {
            state.savingContactSuccess = payload
        },
        setDeleteContactSuccess(state, payload) {
            state.deleteContactSuccess = payload
        },
        setPrices(state, payload = {}) {
            state.prices = payload
            const storageMeterId = _.camelCase('2dac0a24-f029-42cf-aba4-73893a5eba54')
            const usageMeterId = _.camelCase('b6a8e57f-929b-4030-8f6d-1e55e13db0ac')
            state.storageMetering = payload[storageMeterId]?.[0]?.price || {}
            state.usageMetering = payload[usageMeterId]?.[0]?.price || {}
            state.usageMetering.startRange = Math.floor(payload[usageMeterId]?.[0]?.startRange || 0)
        },
        setPricesErrors(state, payload) {
            state.pricesErrors = payload
        },
        setOfferings(state, payload) {
            state.offerings = payload
        },
        setOfferingsErrors(state, payload) {
            state.offeringsError = payload
        },
        setOfferingMeters(state, payload) {
            state.offeringMeters = payload
        },
        setOfferingMetersErrors(state, payload) {
            state.offeringMetersError = payload
        },
    },
    actions: {
        async fetchInstancesCost({ commit, }, { offering, params, }) {
            const [error, response] = await to(getInstancesCosts(offering, params))

            if (!error) {
                commit('setInstanceCosts', response)
                commit('setInstanceCostsErrors', false)
            } else {
                commit('setInstanceCostsErrors', error.message)
            }
        },
        async fetchStorageCost({ commit, }, { offering, params, }) {
            const [error, response] = await to(getStorageCost(offering, params))

            if (!error) {
                const gp3Costs = response?.find(res => res.name == launchService.iops.gp3)
                if (gp3Costs) {
                    commit('setGp3StorageCosts', gp3Costs)
                }
                commit('setStorageCosts', response)
                commit('setStorageCostsErrors', false)
            } else {
                commit('setStorageCostsErrors', error.message)
            }
        },
        async fetchIOPSCost({ commit, }, { offering, params, }) {
            const [error, response] = await to(getIOPSCost(offering, params))

            if (!error) {
                commit('setIOPSCosts', response?.find(res => res.name == launchService.iops.io1))
                commit('setGp3IOPSCosts', response?.find(res => res.name == launchService.iops.gp3))
                commit('setIOPSCostsErrors', false)
            } else {
                commit('setIOPSCostsErrors', error.message)
            }
        },
        async fetchCurrentBillingUsage({ commit, }, monthYear) {
            commit('setFetchingUsageFlag', true)
            const [error, response] = await to(
                getCurrentBillingUsage(monthYear)
            )
            if (response) {
                commit('setBillingUsage', response)
                commit('setBillingUsageErrors', false)
            } else {
                commit('setBillingUsageErrors', error.message)
            }
            commit('setFetchingUsageFlag', false)
        },
        togglePaymentDialog({ commit, }, isOpen) {
            commit('setPaymentDialog', isOpen)
        },
        async fetchBillingRecord({ commit, }, noCache) {
            commit('setFetchingBillingRecord', true)
            const [error, response] = await to(getBillingRecord(noCache))
            if (!error) {
                commit('setBillingRecord', response)
                commit('setBillingRecordErrors', false)
            } else {
                commit('setBillingRecordErrors', error.message)
            }
            commit('setFetchingBillingRecord', false)
        },
        updateBillingRecordPhoneNumber({ state, commit, }, phoneNumber) {
            commit('setBillingRecordPhoneNumber', { ...state.billingRecord?.primaryContact, primaryContactPhone: phoneNumber, phoneVerified: true, })
        },
        async fetchPaymentMethods({ commit, }) {
            commit('setFetchingPaymentDetail', true)
            const [error, response] = await to(getPaymentMethods())
            if (!error) {
                commit('setPaymentProfile', response)
                commit('setPaymentProfileErrors', false)
            } else {
                commit('setPaymentProfileErrors', error.message)
            }
            commit('setFetchingPaymentDetail', false)
        },
        async fetchCountries({ commit, }) {
            const [error, response] = await to(getCountries())
            if (!error) {
                commit('setCountries', response)
                commit('setCountriesErrors', false)
            } else {
                commit('setCountriesErrors', error.message)
            }
        },
        async fetchStatesForCountry({ commit, }, params) {
            const [error, response] = await to(getStatesForCountry(params))
            if (!error) {
                commit('setStates', response)
                commit('setStatesErrors', false)
            } else {
                commit('setStatesErrors', error.message)
            }
        },
        async fetchSpendingDetailsForService({ commit, }, params) {
            const serviceId = params?.['resource_id']
            commit('setServiceSpendingFetchStatus', { serviceId, status: true, })
            const [error, response] = await to(getCurrentBillingUsage(params))

            if (!error || !t(response).isEmptyObject) {
                commit('setSpendingDetail', getTransformedUsageData(response))
                commit('setSpendingErrors', false)
            } else {
                commit('setSpendingErrors', error.message)
            }
            commit('setServiceSpendingFetchStatus', { serviceId, status: false, })
        },
        async fetchInvoices({ commit, }) {
            commit('setFetchingInvoicesFlag', true)
            const [error, response] = await to(getInvoices())
            if (!error || !t(response).isArray) {
                commit('setInvoices', response)
                commit('setInvoicesErrors', false)
            } else {
                commit('setInvoicesErrors', error.message)
            }
            commit('setFetchingInvoicesFlag', false)
        },
        async fetchThroughputCost({ commit, }, { offering, params, }) {
            params['volume_type'] = launchService.iops.gp3
            const [error, response] = await to(getThroughputCost(offering, params))

            if (!error) {
                commit('setThroughputCosts', response?.find(res => res.name == launchService.iops.gp3))
                commit('setThroughputCostsErrors', false)
            } else {
                commit('setThroughputCostsErrors', error.message)
            }
        },
        async fetchEndpointPricing({ commit, }, params) {
            const [error, response] = await to(getEndpointCost(params))

            if (!error || t(response).isArray) {
                // for now just get single endpoint charges per
                // cloud provider as topology does not impact
                // secondary endpoint pricing in any way
                // API is still WIP, so handling it in UI
                // this business logic should go away once API
                // changes are streamlined as required
                const awsPrice = response.find((priceObj) =>
                    isProviderAWS(priceObj.provider)
                )
                const gcpPrice = response.find((priceObj) =>
                    isProviderGCP(priceObj.provider)
                )

                let endpointPricing = []

                if (awsPrice) endpointPricing.push(awsPrice)
                if (gcpPrice) endpointPricing.push(gcpPrice)

                commit('setEndpointPricing', endpointPricing)
                commit('setEndpointPricingErrors', false)
            } else {
                commit('setEndpointPricingErrors', error.message)
            }
        },
        async fetchHistoricalBills({ commit, }, params) {
            commit('setFetchingHistoricalBillsFlag', true)
            const [error, response] = await to(
                isMonthYearForNewBilling(params) ?
                    getNewHistoricalBills(params) : getHistoricalBills(params)
            )
            if (!error) {
                commit('setHistoricalBills', response)
                commit('setHistoricalBillsErrors', false)
            } else {
                commit('setHistoricalBillsErrors', error.message)
            }
            commit('setFetchingHistoricalBillsFlag', false)
        },
        async fetchCreditLedger({ commit, }) {
            commit('setFetchingCreditLedgerFlag', true)
            const [error, response] = await to(getCreditLedger())
            if (!error || error.response.status === 404) {
                commit('setCreditLedger', (response && t(response).isArray) ? response : [])
                commit('setCreditLedgerErrors', false)
            } else {
                commit('setCreditLedgerErrors', error.message)
            }
            commit('setFetchingCreditLedgerFlag', false)
        },
        async fetchCommitLedger({ commit, }) {
            commit('setFetchingCommitLedgerFlag', true)
            const [error, response] = await to(getCommitLedger())
            if (!error || error.response.status === 404) {
                commit('setCommitLedger', (response && t(response).isArray) ? response : [])
                commit('setCommitLedgerErrors', false)
            } else {
                commit('setCommitLedgerErrors', error.message)
            }
            commit('setFetchingCommitLedgerFlag', false)
        },
        async fetchTax({ commit, }) {
            commit('setFetchingTaxFlag', true)
            const [error, response] = await to(getTax())
            if (!error || error.response.status === 404) {
                commit('setTax', response || null)
                commit('setTaxErrors', false)
            } else {
                commit('showMessage', { text: i18n.t('errors.fetchTaxInfo'), type: common.messageType.ERROR, })
                commit('setTaxErrors', error.message)
            }
            commit('setFetchingTaxFlag', false)
        },
        async addNewTax({ commit, }, params) {
            const [error, response] = await to(addNewTax(params))
            if (!error) {
                commit('setSavingTaxSuccess', response)
            } else {
                commit('setSavingTaxSuccess', false)
                commit('showMessage', {
                    text: i18n.t('errors.generic'),
                    type: common.messageType.ERROR,
                    showDataErrorMessage: true,
                    error,
                })
            }
        },
        async editTax({ commit, }, params) {
            const [error, response] = await to(editTax(params))
            if (!error) {
                commit('setSavingTaxSuccess', response)
            } else {
                commit('setSavingTaxSuccess', false)
                commit('showMessage', {
                    text: i18n.t('errors.generic'),
                    type: common.messageType.ERROR,
                    showDataErrorMessage: true,
                    error,
                })
            }
        },
        async deleteTax({ commit, }, params) {
            const [error, response] = await to(deleteTax(params))
            if (!error) {
                commit('setDeleteTaxSuccess', response)
            } else {
                commit('setDeleteTaxSuccess', false)
                commit('showMessage', {
                    text: i18n.t('errors.generic'),
                    type: common.messageType.ERROR,
                    showDataErrorMessage: true,
                    error,
                })
            }
        },
        async fetchContacts({ commit, }) {
            commit('setFetchingContactsFlag', true)
            const [error, response] = await to(getContacts())
            if (!error) {
                commit('setContacts', _.isArray(response) ? response : [])
                commit('setContactsErrors', false)
            } else {
                commit('showMessage', { text: i18n.t('errors.fetchContacts'), type: common.messageType.ERROR, })
                commit('setContactsErrors', error.message)
            }
            commit('setFetchingContactsFlag', false)
        },
        async addNewContact({ commit, }, params) {
            const [error, response] = await to(addNewContact(params))
            if (!error) {
                commit('setSavingContactSuccess', response)
            } else {
                commit('setSavingContactSuccess', false)
                commit('showMessage', {
                    text: i18n.t('errors.generic'),
                    type: common.messageType.ERROR,
                    showDataErrorMessage: true,
                    error,
                })
            }
        },
        async editContact({ commit, }, params) {
            const [error, response] = await to(editContact(params))
            if (!error) {
                commit('setSavingContactSuccess', response)
            } else {
                commit('setSavingContactSuccess', false)
                commit('showMessage', {
                    text: i18n.t('errors.generic'),
                    type: common.messageType.ERROR,
                    showDataErrorMessage: true,
                    error,
                })
            }
        },
        async deleteContact({ commit, }, params) {
            const [error, response] = await to(deleteContact(params))
            if (!error) {
                commit('setDeleteContactSuccess', response)
            } else {
                commit('setDeleteContactSuccess', false)
                commit('showMessage', {
                    text: i18n.t('errors.generic'),
                    type: common.messageType.ERROR,
                    showDataErrorMessage: true,
                    error,
                })
            }
        },
        async editPayment({ commit, }, params) {
            const [error, response] = await to(editPayment(params))
            if (!error) {
                commit('setUpdatingPaymentSuccess', response)
            } else {
                commit('setUpdatingPaymentSuccess', false)
                commit('showMessage', {
                    text: i18n.t('errors.generic'),
                    type: common.messageType.ERROR,
                    showDataErrorMessage: true,
                    error,
                })
            }
        },
        async deletePayment({ commit, }, params) {
            const [error, response] = await to(deletePayment(params))
            if (!error) {
                commit('setDeletePaymentSuccess', response)
            } else {
                commit('setDeletePaymentSuccess', false)
                commit('showMessage', {
                    text: i18n.t('errors.generic'),
                    type: common.messageType.ERROR,
                    showDataErrorMessage: true,
                    error,
                })
            }
        },
        async fetchServerlessPrices({ commit, }, { offering = 'serverless', params, }) {
            const [error, response] = await to(getPrices(offering, params))

            if (!error) {
                commit('setPrices', response)
                commit('setPricesErrors', false)
            } else {
                commit('showMessage', { text: i18n.t('errors.fetchPrices'), type: common.messageType.ERROR, })
                commit('setPricesErrors', error.message)
            }
        },
        async fetchOfferings({ commit, }) {
            const [error, response] = await to(getOfferings())
            if (!error) {
                commit('setOfferings', response)
                commit('setOfferingsErrors', false)
            } else {
                commit('showMessage', { text: i18n.t('errors.fetchOfferings'), type: common.messageType.ERROR, })
                commit('setOfferingsErrors', error.message)
            }
        },
        async fetchOfferingMeters({ commit, }, id) {
            const [error, response] = await to(getOfferingMeters(id))
            if (!error) {
                commit('setOfferingMeters', response)
                commit('setOfferingMetersErrors', false)
            } else {
                commit('showMessage', { text: i18n.t('errors.fetchOfferingMeters'), type: common.messageType.ERROR, })
                commit('setOfferingMetersErrors', error.message)
            }
        },
    },
    getters: {
        instanceCosts: (state) => state.instanceCosts,
        instanceCostsErrors: (state) => state.instanceCostsErrors,
        storageCosts: (state) => state.storageCosts,
        storageCostsErrors: (state) => state.storageCostsErrors,
        iopsCosts: (state) => state.iopsCosts,
        iopsCostsErrors: (state) => state.iopsCostsErrors,
        billingUsageDetails: (state) => state.billingUsageDetails,
        billingUsageDetailsErrors: (state) => state.billingUsageDetailsErrors,
        isFetchingBillingUsage: (state) => state.isFetchingBillingUsage,
        isFetchingInvoices: (state) => state.isFetchingInvoices,
        isPaymentDialogOpen: (state) => state.isPaymentDialogOpen,
        billingRecord: (state) => state.billingRecord,
        billingRecordErrors: (state) => state.billingRecordErrors,
        isFreeTrialActive: (state) => state.billingRecord?.status === 'TRIAL',
        freeCreditLeft: (state) => state.billingRecord?.trialCredit || 0,
        freeSCUHoursLeft: (state) => state.billingRecord?.scuRemainingMtd || 0,
        paymentProfile: (state) => state.paymentProfile,
        paymentProfileErrors: (state) => state.paymentProfileErrors,
        billingCountries: (state) => state.billingCountries,
        billingCountriesErrors: (state) => state.billingCountriesErrors,
        billingStates: (state) => state.billingStates,
        billingStatesErrors: (state) => state.billingStatesErrors,
        isFetchingBillingRecord: (state) => state.isFetchingBillingRecord,
        isFetchingPaymentDetail: (state) => state.isFetchingPaymentDetail,
        servicesSpending: (state) => state.servicesSpending,
        servicesSpendingErrors: (state) => state.servicesSpendingErrors,
        getServiceIdsSpendingFetchStatus: (state) => state.serviceIdsSpendingFetchStatus,
        invoices: (state) => state.invoices,
        invoicesErrors: (state) => state.invoicesErrors,
        historicalUsage: (state) => state.historicalUsage,
        billingSubscriptions: (state) =>
            state.billingRecord.subscriptions || [],
        monitoringSubscription: (state, getters) =>
            getters.billingSubscriptions.find(
                ({ product, }) => product === 'monitor'
            ),
        gp3IopsCosts: (state) => state.gp3IopsCosts,
        throughputCosts: (state) => state.throughputCosts,
        throughputCostsErrors: (state) => state.throughputCostsErrors,
        endpointPricing: (state) => state.endpointPricing,
        endpointPricingError: (state) => state.endpointPricingError,
        gp3StorageCosts: (state) => state.gp3StorageCosts,
        isFetchingHistoricalBills: (state) => state.isFetchingHistoricalBills,
        historicalBills: (state) => state.historicalBills,
        historicalBillsErrors: (state) => state.historicalBillsErrors,
        isFetchingCreditLedger: (state) => state.isFetchingCreditLedger,
        creditLedger: (state) => state.creditLedger,
        creditLedgerErrors: (state) => state.creditLedgerErrors,
        isFetchingCommitLedger: (state) => state.isFetchingCommitLedger,
        commitLedger: (state) => state.commitLedger,
        commitLedgerErrors: (state) => state.commitLedgerErrors,
        isFetchingTax: (state) => state.isFetchingTax,
        tax: (state) => state.tax,
        taxError: (state) => state.taxError,
        savingTaxSuccess: (state) => state.savingTaxSuccess,
        deleteTaxSuccess: (state) => state.deleteTaxSuccess,
        isFetchingContacts: (state) => state.isFetchingContacts,
        contacts: (state) => state.contacts,
        contactsError: (state) => state.contactsError,
        savingContactSuccess: (state) => state.savingContactSuccess,
        deleteContactSuccess: (state) => state.deleteContactSuccess,
        updatingPaymentSuccess: (state) => state.updatingPaymentSuccess,
        deletePaymentSuccess: (state) => state.deletePaymentSuccess,
        prices: (state) => state.prices,
        pricesErrors: (state) => state.pricesErrors,
        storageMetering: (state) => state.storageMetering,
        usageMetering: (state) => state.usageMetering,
        offerings: (state) => state.offerings,
        offeringsError: (state) => state.offeringsError,
        offeringMeters: (state) => state.offeringMeters,
        offeringMetersError: (state) => state.offeringMetersError,
    },
}
