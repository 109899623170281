<template>
    <base-dialog
        v-model="isDialogOpen"
        :should-dismiss-on-save="false"
        :on-save="saveDatasource"
        :on-cancel="close"
        :min-width="776"
        :max-width="776"
        :on-close="close">
        <template #title>
            <div class="font-weight-light text-h4 pb-6">
                {{ isEditing ? $t('copilot.editDatasource') : $t('copilot.addDatasource') }}
            </div>
        </template>
        <template #body>
            <div>
                <v-alert
                    outlined
                    type="info"
                    text>
                    {{ $t('info.copilotAllowAgentIP') }}:
                    <strong>{{ agentIP }}</strong>
                </v-alert>

                <div v-if="!isEditing">
                    <div class="text-body-1 pb-1">
                        {{ $t('copilot.quickConnect') }}:
                    </div>
                    <v-progress-circular
                        v-if="servicesLoading"
                        :size="28"
                        indeterminate
                        color="primary" />
                    <div v-else-if="readyServices.length">
                        <v-btn
                            v-for="(service, i) in readyServices"
                            :key="i"
                            class="mr-2"
                            :loading="isFetchingCredentials"
                            outlined
                            small
                            @click="connectToService(service)">
                            <icon-sprite-sheet
                                size="20"
                                class="status-icon"
                                :frame="service.status"
                                left>
                                health
                            </icon-sprite-sheet>
                            <span class="pl-1 text-none txt-compact">{{ service.name }}</span>
                        </v-btn>
                    </div>
                    <div v-else>
                        {{ $t('noServiceAvail') }}
                    </div>
                </div>

                <v-row class="my-6">
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('name') }}</label>
                        <v-text-field
                            v-model="name"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('name')"
                            maxlength="24"
                            dense
                            hide-details
                            autofocus />
                    </v-col>
                </v-row>
                <v-row class="mb-6">
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ `${$t('hostname')} (${$t('fqdn')})` }}</label>
                        <v-text-field
                            v-model="hostname"
                            type="text"
                            required
                            outlined
                            :placeholder="`${$t('hostname')} (${$t('fqdn')})`"
                            dense
                            hide-details />
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('port') }}</label>
                        <v-text-field
                            v-model="port"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('port')"
                            dense
                            hide-details />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('username') }}</label>
                        <v-text-field
                            v-model="username"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('username')"
                            dense
                            hide-details />
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('password') }}</label>
                        <v-text-field
                            v-model="password"
                            :type="revealPassword ? 'text' : 'password'"
                            :append-icon="revealPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            required
                            outlined
                            :placeholder="$t('password')"
                            dense
                            hide-details
                            @click:append="toggleRevealPassword" />
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                style="min-width: 100px;"
                :disabled="!isFormValid"
                :loading="isSaving"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import to from 'await-to-js'
    import { fetchServiceCredentials } from 'services/dbServices.js'

    export default {
        name: 'CopilotDatasourceModal',
        data() {
            return {
                isDialogOpen: false,
                isSaving: false,
                isFetchingCredentials: false,
                agentIP: '146.148.41.59/32',
                id: null,
                name: '',
                serviceId: null,
                hostname: '',
                port: '',
                username: '',
                password: '',
                revealPassword: false,
            }
        },
        computed: {
            ...mapGetters([
                'servicesLoading',
                'datasources',
                'services',
                'selectedAgent'
            ]),
            isEditing() {
                return Boolean(this.id)
            },
            isFormValid() {
                return !!this.name &&
                    !!this.hostname &&
                    !!this.port &&
                    !!this.username &&
                    !!this.password
            },
            readyServices() {
                return this.services.filter(service => service.status === 'ready')
            },
        },
        methods: {
            ...mapActions([
                'fetchServices',
                'addDatasource',
                'editDatasource',
                'selectDatasource'
            ]),
            open(datasourceId) {
                this.fetchServices()
                this.id = datasourceId ? datasourceId : null
                if (datasourceId) {
                    const datasource = this.datasources.find(datasource => datasource.id === datasourceId)
                    this.name = datasource.name
                    this.serviceId = datasource.serviceId
                    this.hostname = datasource.host
                    this.port = datasource.port
                    this.username = datasource.username
                    this.password = ''
                } else {
                    this.name = ''
                    this.serviceId = null
                    this.hostname = ''
                    this.port = ''
                    this.username = ''
                    this.password = ''
                }
                this.isDialogOpen = true
            },
            close() {
                this.isDialogOpen = false
            },
            toggleRevealPassword() {
                this.revealPassword = !this.revealPassword
            },
            getEndpoint(service) {
                return service.endpoints?.find(
                    point => point.name !== 'primary' && point.visibility === 'public'
                ) || service.endpoints?.[0] || {}
            },
            async connectToService(service) {
                this.isFetchingCredentials = true
                const [error, data] = await to(fetchServiceCredentials(service.id))
                if (error) {
                    this.$store.commit('showMessage', { text: this.$t('errors.generic'), type: this.$config.messageType.ERROR, })
                } else {
                    this.name = service.name
                    this.serviceId = service.id
                    this.hostname = service.fqdn
                    this.port = this.getEndpoint(service).ports[0]?.port || 3306
                    this.username = data.username
                    this.password = data.password
                }
                this.isFetchingCredentials = false
            },
            async saveDatasource() {
                this.isSaving = true
                let savedId = null
                const payload = {
                    name: this.name,
                    serviceId: this.serviceId,
                    host: this.hostname,
                    port: this.port,
                    username: this.username,
                    password: this.password,
                }
                if (this.isEditing) {
                    savedId = await this.editDatasource({
                        datasourceId: this.id,
                        data: payload,
                    })
                } else {
                    savedId = await this.addDatasource(payload)
                }
                this.isSaving = false
                if (savedId) {
                    if (this.selectedAgent?.type === 'USER') {
                        this.selectDatasource(savedId)
                    }
                    this.close()
                }
            },
        },
    }
</script>
