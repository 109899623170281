<style lang="scss" scoped>
    .column-textarea {
        min-width: 250px;
    }
    .cp-col-selected {
        background-color: #f4fbf4;
    }
    .catindex {
        width: 90px;
    }
</style>
<template>
    <div>
        <base-dialog
            v-model="isDialogOpen"
            :should-dismiss-on-save="false"
            :on-save="updateTableContext"
            :on-cancel="confirmClose"
            :min-width="1024"
            :max-width="1024"
            :on-close="confirmClose"
            :should-prevent-auto-close="true">
            <template #title>
                <div class="font-weight-light text-h4 text-capitalize">
                    {{ $t('copilot.enhanceTable') }}:
                </div>
                <div class="text-h6 pb-6">
                    {{ tableName }}
                </div>
            </template>
            <template #body>
                <context-modal-body
                    :table-description="tableDescription"
                    :columns="columns"
                    @update:tableDescription="tableDescription = $event"
                    @column-toggle="toggleColumn"
                    @all-columns-toggle="toggleAllColumns" />
            </template>
            <template #actions="{ cancel, save }">
                <v-spacer />
                <v-btn
                    text
                    rounded
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ $t('close') }} </span>
                </v-btn>
                <v-btn
                    color="primary"
                    class="save"
                    style="min-width: 100px;"
                    :disabled="!isFormValid"
                    depressed
                    rounded
                    @click="save">
                    <span class="text-none"> {{ $t('save') }}</span>
                </v-btn>
            </template>
        </base-dialog>
        <ConfirmationModal
            ref="confirmCloseModal"
            message="copilot.dialogCloseConfirmation"
            :on-confirm="close" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import to from 'await-to-js'
    import { rebuildAgentContext } from 'services/copilot.js'
    import ConfirmationModal from 'components/common/ConfirmationModal.vue'
    import ContextModalBody from 'components/copilot/ContextModalBody.vue'

    export default {
        name: 'CopilotContextModal',
        components: {
            ConfirmationModal,
            ContextModalBody,
        },
        data() {
            return {
                isDialogOpen: false,
                table: {},
                tableName: '',
                tableDescription: '',
                columns: [],
                initialColumnsSnapshot: [],
            }
        },
        computed: {
            ...mapGetters([
                'selectedAgent'
            ]),
            isFormValid() {
                return !!this.tableDescription
            },
        },
        methods: {
            ...mapActions([
                'editAgentContextTable',
                'editAgentContextTableColumns',
                'refreshAgents',
                'startPollingAgentsIndexStatus'
            ]),
            open(table) {
                this.table = table
                this.tableName = table.name
                this.tableDescription = table.description
                if (table.availableColumns) {
                    this.columns = table.availableColumns.map(column => {
                        const contextColumn = table.columns.find(col => col.name === column.name)
                        return {
                            ...(contextColumn || column),
                            isSelected: Boolean(contextColumn),
                        }
                    })
                }
                this.initialColumnsSnapshot = this.$lodash.cloneDeep(this.columns)
                this.isDialogOpen = true
            },
            toggleColumn(column) {
                column.isSelected = !column.isSelected
                if (!column.isSelected) {
                    column.dataRole = null
                }
            },
            toggleAllColumns(status) {
                this.columns.forEach(column => {
                    column.isSelected = status
                    if (!status) {
                        column.dataRole = null
                    }
                })
            },
            confirmClose() {
                if (this.$lodash.isEqual(this.initialColumnsSnapshot, this.columns)) {
                    this.close()
                } else {
                    this.$refs.confirmCloseModal.open()
                }
            },
            async close() {
                this.isDialogOpen = false
                if (this.selectedAgent.indexStatus === 'OUTOFSYNC') {
                    await to(rebuildAgentContext(this.selectedAgent.id))
                    await this.refreshAgents()
                    this.startPollingAgentsIndexStatus()
                }
            },
            async updateTableContext() {
                const updates = []
                if (this.table.description !== this.tableDescription) {
                    updates.push(this.editAgentContextTable({
                        contextId: this.table.contextId,
                        tableId: this.table.id,
                        tableDescription: this.tableDescription,
                    }))
                }
                if (!this.$lodash.isEqual(this.initialColumnsSnapshot, this.columns)) {
                    const columnsToUpdate = this.columns
                        .filter(column => column.isSelected)
                        .map(column => {
                            return {
                                name: column.name,
                                type: column.type,
                                description: column.description,
                                dataRole: column.dataRole,
                            }
                        })
                    updates.push(this.editAgentContextTableColumns({
                        agentId: this.selectedAgent.id,
                        tableId: this.table.id,
                        columns: columnsToUpdate,
                    }))
                }
                const [editStatus, columnsUpdateStatus] = await Promise.all(updates)
                if (editStatus) {
                    this.table.description = this.tableDescription
                }
                if (editStatus || columnsUpdateStatus) {
                    await this.refreshAgents()
                }
                if (editStatus !== false && columnsUpdateStatus !== false) {
                    this.close()
                }
            },
        },
    }
</script>
