<style lang="scss" scoped>
.tier-wrapper {
    min-height: 630px;
}
.card-container {
    gap: 2rem;

    .tier-card-wrap {
        flex: 1 1 80%;
    }
}

.service-details {
    font-size: 12px;
}

.confirm-message {
    max-width: 820px;
}

.compare-wrap {
    padding-left: 12px;
    padding-right: 12px;
    gap: 12px;
}

.computed-wrapper {
    background-color: $estimate-calculations;
    padding-left: 12px;
    padding-right: 12px;
}
</style>
<template>
    <div class="start-stop-popup">
        <base-dialog
            v-model="shouldShowDialog"
            :min-width="1000"
            :max-width="1400"
            :on-save="requestForPowerTier"
            :on-close="cancelUpgrade"
            :on-cancel="cancelUpgrade">
            <template #title>
                <p class="font-weight-regular text-h4 ma-0">
                    {{ $t('tierUpgrade.upgradeTierTitle') }}
                </p>
            </template>
            <template #body>
                <v-container class="tier-wrapper fill-height">
                    <div
                        v-if="!isRequestForPowerSubmitted"
                        class="card-container d-flex pt-2">
                        <v-row class="tier-card-wrap">
                            <v-col cols="6">
                                <TierFeatureCard
                                    :is-disabled="true"
                                    :show-current-tier-banner="true"
                                    :title="$t('tierUpgrade.foundationTier.title')"
                                    :description="$t('tierUpgrade.foundationTier.description')"
                                    :features="$t('tierUpgrade.foundationTier.features')" />
                            </v-col>
                            <v-col cols="6">
                                <TierFeatureCard
                                    :is-selected="true"
                                    :title="$t('tierUpgrade.powerTier.title')"
                                    :description="$t('tierUpgrade.powerTier.description')"
                                    :features="$t('tierUpgrade.powerTier.features')" />
                            </v-col>
                        </v-row>
                        <v-card class="selection-entity-box pt-4 pb-3 align-self-start">
                            <v-row class="pa-0">
                                <v-col class="pl-6 mb-4 font-weight-bold">
                                    {{ $t('tierUpgrade.upgradeWarning') }}
                                </v-col>
                            </v-row>
                            <div
                                v-if="comparisonDataLoading"
                                class="d-flex justify-center">
                                <v-progress-circular
                                    indeterminate
                                    size="60" />
                            </div>
                            <template v-else>
                                <div
                                    class="compare-wrap d-flex flex-column">
                                    <div
                                        v-for="data in comparisonData"
                                        :key="data.service?.id || data.service?.name"
                                        class="compare-row d-flex flex-column">
                                        <span class="text--primary font-weight-medium">
                                            {{ data.service?.name }}
                                            <HelpToolTip
                                                v-if="data.service?.is_sample"
                                                :size="16">
                                                <template #message>
                                                    <p>{{ $t('tierUpgrade.sampleServiceInfo') }}</p>
                                                </template>
                                            </HelpToolTip>
                                        </span>
                                        <span class="cpmpare-service-details">
                                            {{ $help.capitalize(data.service?.size) }}(x{{ data.service?.nodes }}),
                                            {{ $t('launchService.gb-ssd', [data.service?.storage_volume?.size || 100]) }},
                                            {{ $t('launchService.scaleFabricInRegion', [data.service?.region]) }}
                                        </span>
                                        <div class="d-flex pt-2 justify-space-between">
                                            <span>
                                                {{ $t('tierUpgrade.foundationTier.title') }} {{ $t('currency', [data.cost.foundation.toFixed(2)]) }}
                                            </span>
                                            <span>
                                                {{ $t('tierUpgrade.powerTier.title') }} {{ $t('currency', [data.cost.power.toFixed(2)]) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="!comparisonDataLoading"
                                    class="mt-4 pt-6 computed-wrapper text-caption">
                                    <v-row>
                                        <v-col
                                            class="py-0"
                                            cols>
                                            <span class="mr-1 font-weight-bold">
                                                {{ $t("launchService.estimatedTotal") }}
                                            </span>
                                            <HelpToolTip :size="18">
                                                <template #message>
                                                    <p>{{ $t('billings.costEstimateToolTip') }}</p>
                                                    <a
                                                        rel="noopener noreferrer"
                                                        class="text-capitalize"
                                                        target="_blank"
                                                        :href="`${$config.DOCS_URL}Billing%20and%20Power%20Tier/Pricing`">
                                                        {{ $t('learn-more') }}
                                                    </a>
                                                </template>
                                            </HelpToolTip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols
                                            class="d-flex justify-space-between py-1">
                                            <span>
                                                {{ $t("tierUpgrade.foundationTierPricing") }}
                                            </span>
                                            <span>
                                                {{ $t('currency',[$t('perMonth', { month: parseFloat(foundationTotal).toFixed(2)} )]) }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <v-col
                                            cols
                                            class="d-flex justify-space-between">
                                            <span>
                                                {{ $t("tierUpgrade.powerTierPricing") }}
                                            </span>
                                            <span>
                                                {{ $t('currency',[$t('perMonth', { month: parseFloat(powerTotal).toFixed(2)} )]) }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </div>
                            </template>
                        </v-card>
                    </div>
                    <v-row
                        v-else
                        align="center"
                        justify="center"
                        class="fill-height text-subtitle-1">
                        {{ $t('tierUpgrade.requestSubmittedMsg') }}
                    </v-row>
                </v-container>
            </template>
            <template #actions="{ cancel, save }">
                <template v-if="showConfirmation">
                    <div class="text-body-2 font-weight-medium confirm-message">
                        <span>
                            {{ $t('tierUpgrade.confirmationMessage') }}
                        </span>
                    </div>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        class="green white--text"
                        depressed
                        rounded
                        :disabled="disableUpgrade || !!impersonatedUser"
                        @click="save">
                        <span class="text-none px-3">
                            {{ $t('confirm') }}
                        </span>
                    </v-btn>
                </template>
                <template v-else>
                    <div
                        v-if="!!impersonatedUser"
                        class="text-body-2 font-weight-medium red--text">
                        <span>
                            {{ $t('tierUpgrade.impersonationMessage') }}
                        </span>
                    </div>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none">{{ `${isRequestForPowerSubmitted ? $t('ok') : $t('cancel')}` }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        :disabled="disableUpgrade || !!impersonatedUser"
                        @click="showConfirmation=true">
                        <span class="text-none px-3">
                            {{ $t('tierUpgrade.upgrade') }}
                        </span>
                    </v-btn>
                </template>
            </template>
        </base-dialog>
    </div>
</template>
<script>
    import to from 'await-to-js'
    import HelpToolTip from 'components/common/HelpTooltip.vue'
    import TierFeatureCard from './TierFeatureCard.vue'
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { OVERLAY_TRANSPARENT_LOADING } from 'store/overlayTypes'
    import { getPrices } from 'services/billings'
    import {
        isPrimaryReplica,
        getNodeTypeForPricingAPI
    } from 'utils/service'

    export default {
        components: { TierFeatureCard, HelpToolTip, },
        props: {
            showDialog: Boolean,
        },
        data() {
            return {
                shouldShowDialog: false,
                isRequestForPowerSubmitted: false,
                disableUpgrade: false,
                showConfirmation: false,
                comparisonData: [],
                comparisonDataLoading: false,
                foundationTotal: 0,
                powerTotal: 0,
                servicesToshow: [],
            }
        },
        computed: {
            ...mapGetters([
                'tierUpgradeError',
                'billingRecord',
                'services',
                'offerings',
                'offeringMeters',
                'contacts',
                'isFetchingContacts',
                'impersonatedUser'
            ]),
            primaryContactEmail() {
                return this.contacts.find(
                    contact => contact.type.toLowerCase() === 'primary'
                )?.email || ''
            },
        },
        watch: {
            showDialog: function (val) {
                this.shouldShowDialog = val
            },
        },
        methods: {
            ...mapMutations(['showOverlay', 'hideOverlay']),
            ...mapActions([
                'fetchServices',
                'upgradeToPowerTier',
                'fetchBillingRecord',
                'fetchPaymentMethods',
                'fetchOfferings',
                'fetchOfferingMeters',
                'fetchContacts'
            ]),
            cancelUpgrade() {
                this.shouldShowDialog = false
                this.showConfirmation = false
                this.$emit('cancel-upgrade')
            },
            async requestForPowerTier() {
                // Check if billing profile and account details
                // are available in store else call service
                this.disableUpgrade = true
                this.showOverlay(OVERLAY_TRANSPARENT_LOADING)
                if (this.$lodash.isEmpty(this.billingRecord)) {
                    await this.fetchBillingRecord()
                }
                if (!this.contacts?.length) {
                    await this.fetchContacts()
                }

                const { legalEntityIdentifier: chargifySite, number: billingAccount, } = this.billingRecord

                const profile = {
                    primaryContactEmail: this.primaryContactEmail,
                    chargifySite,
                    billingAccount,
                    tier: this.$config.POWER_TIER,
                }

                await this.upgradeToPowerTier(profile)
                this.hideOverlay()
                this.disableUpgrade = false

                if (!this.tierUpgradeError) {
                    localStorage.setItem('tierUpgrade', 'true')
                    window.location.href = '/'
                } else {
                    const errorData = this.tierUpgradeError?.response?.data
                    const showTrialUpgradeError = errorData?.exception === 'BillingException' &&
                        errorData?.errors?.[0].message?.toLowerCase().includes('trial accounts')

                    this.$store.commit(
                        'showMessage', {
                            text: showTrialUpgradeError ?
                                this.$t('tierUpgrade.trialUpgradeError') :
                                this.$t('tierUpgrade.upgradeError'),
                            type: this.$config.messageType.ERROR,
                        })
                }
                this.showConfirmation = false
            },
            getOffering(serviceType) {
                return this.offerings?.find(offering => offering.productMapping === serviceType) || {}
            },
            async getComparison() {
                this.comparisonDataLoading = true

                if (!this.services?.length) {
                    await this.fetchServices()
                }
                await this.$lodash.isEmpty(this.offerings) && this.fetchOfferings()

                this.comparisonData = []
                this.servicesToshow = this.services?.filter(service => isPrimaryReplica(service.topology)) || []
                if (!this.servicesToshow?.length) {
                    this.servicesToshow.push({
                        'name': 'sample-service',
                        'region': 'us-central1',
                        'provider': 'gcp',
                        'tier': 'foundation',
                        'topology': 'es-replica',
                        'architecture': 'amd64',
                        'size': 'sky-2x8',
                        'nodes': 2,
                        'service_type': 'transactional',
                        'maxscale_nodes': 1,
                        'maxscale_size': 'sky-2x8',
                        'is_sample': true,
                        'storage_volume': {
                            'size': 100,
                            'volume_type': 'StandardSSD_LRS',
                        },
                    })
                }
                this.servicesToshow?.forEach(async (service, index) => {
                    const { topology, provider, region, size: name, architecture, } = service
                    const compare = { service, }
                    const isAWSProvider = provider === this.$config.cloudProvider.AWS
                    const storageSize = service.storage_volume?.size || 100

                    const offering = this.getOffering(service.service_type)
                    const params = {
                        topology,
                        provider,
                        region,
                        name,
                        size: name,
                        node: getNodeTypeForPricingAPI(topology),
                    }
                    if (isAWSProvider && architecture?.length) {
                        params.architecture = architecture
                    }
                    // eslint-disable-next-line no-unused-vars
                    const [foundationError, foundationResponse] = await to(getPrices(
                        offering?.name, {
                            ...params,
                            tier: 'foundation',
                        }))
                    const foundationItems = Object.values(foundationResponse)?.flat() || []
                    const foundationCharges = service.nodes * foundationItems?.
                        filter(obj => obj.meter?.name === 'provisioned-compute' || obj.meter?.name === 'storage')?.
                        reduce(
                            (accumulator, current) => {
                                return accumulator +
                                    ((current.meter?.name === 'storage' ? storageSize : 1) * current.price?.pricePerMonth) +
                                    (current.price?.scaleFabricPricePerMonth ?? 0)
                            }, 0
                        )
                    // eslint-disable-next-line no-unused-vars
                    const [powerError, powerResponse] = await to(getPrices(
                        offering?.name, {
                            ...params,
                            tier: 'power',
                        }))
                    const powerItems = Object.values(powerResponse)?.flat() || []
                    const powerCharges = service.nodes * powerItems?.
                        filter(obj => obj.meter?.name === 'provisioned-compute' || obj.meter?.name === 'storage')?.
                        reduce(
                            (accumulator, current) => {
                                return accumulator +
                                    ((current.meter?.name === 'storage' ? storageSize : 1) * current.price?.pricePerMonth) +
                                    (current.price?.scaleFabricPricePerMonth ?? 0)
                            }, 0
                        )

                    compare.cost = {
                        foundation: foundationCharges,
                        power: powerCharges,
                    }

                    this.comparisonData.push(compare)

                    this.foundationTotal += foundationCharges
                    this.powerTotal += powerCharges

                    if (index === this.servicesToshow.length - 1) {
                        this.comparisonDataLoading = false
                    }
                })
            },
        },
        async mounted() {
            this.showConfirmation = false
            await this.getComparison()
        },
    }
</script>
