<style lang="scss" scoped>
    /* Apply custom scroll */
    .custom-scroll {
        max-height: 240px; /* Limit textarea height */
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .styled-textarea {
        min-height: 233px;
    }
</style>

<template>
    <div v-if="isSaving">
        <v-alert
            outlined
            type="info"
            text>
            {{ $t('info.copilotGeneratingAgentContext') }}
        </v-alert>
        <v-progress-linear
            indeterminate
            color="primary" />
    </div>
    <div v-else>
        <v-row class="my-6">
            <v-col class="pt-0 pb-0">
                <label class="required text-uppercase">{{ $t('name') }}</label>
                <v-text-field
                    v-model="name"
                    type="text"
                    required
                    outlined
                    :placeholder="$t('copilot.agentName')"
                    maxlength="24"
                    dense
                    hide-details
                    autofocus />
            </v-col>
        </v-row>
        <v-row class="my-6">
            <v-col class="pt-0 pb-0">
                <label class="required text-uppercase">{{ $tc('copilot.datasource', 1) }}</label>
                <v-select
                    v-model="datasourceId"
                    :class="['std std--dropdown select-dropdown']"
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                    :items="datasources"
                    :disabled="isEditing"
                    :loading="areDatasourcesLoading"
                    item-text="name"
                    item-value="id"
                    height="36"
                    outlined
                    attach
                    single-line
                    @change="updateDatasourceSchema">
                    <template #selection="{ item }">
                        <v-icon dense>
                            {{ item.type === 'SYSTEM' ? 'mdi-database-eye' : 'mdi-database' }}
                        </v-icon>
                        <span class="pl-1">
                            {{ item.name }}
                        </span>
                    </template>
                    <template #item="{ item }">
                        <v-icon dense>
                            {{ item.type === 'SYSTEM' ? 'mdi-database-eye' : 'mdi-database' }}
                        </v-icon>
                        <span class="pl-1">
                            {{ item.name }}
                        </span>
                    </template>
                </v-select>
            </v-col>
            <v-col class="pt-0 pb-0">
                <label class="required text-uppercase">{{ $t('copilot.schema') }}</label>
                <v-autocomplete
                    v-model="datasourceSchema"
                    :class="['std std--dropdown select-dropdown']"
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                    :items="datasourceSchemas"
                    :disabled="isEditing"
                    :loading="areDatasourcesLoading || isAgentContextLoading"
                    height="36"
                    outlined
                    attach
                    single-line>
                    <template #selection="{ item }">
                        <v-icon dense>
                            mdi-table-multiple
                        </v-icon>
                        <span class="pl-1">
                            {{ item }}
                        </span>
                    </template>
                    <template #item="{ item }">
                        <v-icon dense>
                            mdi-table-multiple
                        </v-icon>
                        <span class="pl-1">
                            {{ item }}
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="my-6">
            <v-col class="pt-0 pb-0">
                <label class="required text-uppercase">
                    {{ $t('description') }}
                    <CopilotInfoIcon
                        :help-text="$t('copilot.agentDescription')"
                        :max-width="560" />
                </label>
                <overlay-scrollbars
                    class="custom-scroll">
                    <textarea
                        ref="textarea"
                        v-model="description"
                        type="text"
                        :placeholder="$t('copilot.agentDescription')"
                        class="styled-textarea"
                        required
                        @input="autoResize">
                    </textarea>
                </overlay-scrollbars>
            </v-col>
        </v-row>
        <v-row
            v-if="isEditing"
            class="my-6">
            <v-col class="pt-0 pb-0">
                <label class="text-uppercase">
                    {{ $t('status') }}
                </label>
                <v-radio-group
                    v-model="status"
                    class="mt-0"
                    hide-details
                    row>
                    <v-radio
                        :label="$t('copilot.agentStatus.DEVELOPMENT')"
                        value="DEVELOPMENT" />
                    <v-radio
                        :label="$t('copilot.agentStatus.DEPLOYED')"
                        value="DEPLOYED" />
                </v-radio-group>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import to from 'await-to-js'
    import { createContext, generateAgentContext } from 'services/copilot.js'
    import CopilotInfoIcon from 'components/copilot/InfoIcon.vue'

    export default {
        name: 'CopilotAgentModalDetails',
        components: {
            CopilotInfoIcon,
        },
        props: {
            agentId: {
                type: [String, null],
                default: null,
            },
        },
        data() {
            return {
                name: '',
                description: '',
                status: 'DEVELOPMENT',
                datasourceId: null,
                datasourceSchema: null,
                isSaving: false,
            }
        },
        computed: {
            ...mapGetters([
                'datasources',
                'selectedDatasource',
                'areDatasourcesLoading',
                'isAgentContextLoading',
                'agents'
            ]),
            agent() {
                return this.agents.find(agent => agent.id === this.agentId)
            },
            isEditing() {
                return Boolean(this.agent)
            },
            datasourceSchemas() {
                const datasource = this.datasources.find(datasource => datasource.id === this.datasourceId)
                if (!datasource) {
                    return []
                } else {
                    return datasource.schemas.map(datasource => datasource.name)
                }
            },
            isValid() {
                return !!this.name &&
                    !!this.description &&
                    !!this.datasourceId &&
                    !!this.datasourceSchema
            },
        },
        watch: {
            isValid: {
                handler(value) {
                    this.$emit('update:isValid', value)
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions([
                'fetchDatasource',
                'fetchAgentContext',
                'newAgent',
                'editAgent'
            ]),
            autoResize() {
                const textarea = this.$refs.textarea
                textarea.style.height = 'auto' // Reset height
                textarea.style.height = textarea.scrollHeight + 'px' // Set to scroll height
            },
            async createAgent() {
                const [error, data] = await to(createContext(this.datasourceId, this.datasourceSchema))
                if (error) {
                    this.$store.commit('showMessage', { text: this.$t('errors.createContext'), type: this.$config.messageType.ERROR, })
                } else {
                    const newAgent = await this.newAgent({
                        name: this.name,
                        description: this.description,
                        contextId: data.id,
                    })
                    const [error] = await to(generateAgentContext(newAgent.id))
                    if (error) {
                        this.$store.commit('showMessage', { text: this.$t('errors.createContext'), type: this.$config.messageType.ERROR, })
                    } else {
                        await this.fetchAgentContext(newAgent.id)
                        return newAgent.id
                    }
                }
            },
            async save() {
                this.isSaving = true
                if (this.isEditing) {
                    if (
                        this.agent.name !== this.name ||
                        this.agent.description !== this.description ||
                        this.agent.status !== this.status
                    ) {
                        const savedId = await this.editAgent({
                            agentId: this.agent.id,
                            data: {
                                name: this.name,
                                description: this.description,
                                status: this.status,
                            },
                        })
                        return [this.datasourceId, savedId]
                    }
                } else {
                    const createdId = await this.createAgent()
                    return [this.datasourceId, createdId]
                }
                this.isSaving = false
                return []
            },
            async updateDatasourceSchema(datasourceId) {
                const selectedDatasource = this.datasources.find(datasource => datasource.id === datasourceId)
                if (selectedDatasource) {
                    if (!selectedDatasource.schemas.length) {
                        await this.fetchDatasource(selectedDatasource.id)
                    }
                    if (this.agent) {
                        if (!this.agent?.context) {
                            await this.fetchAgentContext(this.agent.id)
                        }
                        this.datasourceSchema = this.agent?.context?.name
                    } else {
                        this.datasourceSchema = selectedDatasource.schemas[0]?.name
                    }
                }
            },
        },
        beforeMount() {
            this.$emit('update:actionLabel', 'next')

            if (this.agent) {
                this.name = this.agent.name
                this.description = this.agent.description
                this.status = this.agent.status
                this.datasourceId = this.agent.datasourceId
                this.datasourceSchema = null
                this.updateDatasourceSchema(this.agent.datasourceId)
            } else {
                this.name = ''
                this.description = ''
                this.status = null
                this.datasourceId = this.selectedDatasource.id
                this.datasourceSchema = this.selectedDatasource?.schemas[0]?.name
            }
        },
        created() {
            setTimeout(() => this.autoResize(), 0)
        },
    }
</script>
