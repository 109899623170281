<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid $cornsilk;
}

.per-min-cost {
    color: $grayed-out
}
</style>
<template>
    <div v-if="hasChildren">
        <v-row
            v-for="(childNode, index) in node.children"
            :key="index"
            :class="['border-bottom mb-0', { 'mt-0': index }, { 'mt-3': !index }]">
            <v-col
                cols="5"
                class="pl-16 font-weight-regular mt-0 child-title pt-2">
                {{ childNode.title }}
                <span
                    v-if="childNode.activeNodes"
                    class="font-weight-thin ml-6 text--disabled">
                    {{ $tc('billings.activeNodes', childNode.activeNodes, [childNode.activeNodes]) }}
                </span>
                <span
                    v-if="childNode.subTitle"
                    class="font-weight-thin ml-6 text--disabled">
                    {{ childNode.subTitle }}
                </span>
            </v-col>
            <v-col
                class="mt-0 pt-2"
                cols="4">
                <template v-if="!childNode.hideRateDetails">
                    <span class="font-weight-regular">
                        {{ childNode.runtime?.toFixed(2) }}
                    </span>
                    <span class="pl-1 font-weight-regular">
                        {{ childNode.unit }}
                    </span>
                    <span class="ml-6 font-weight-thin per-min-cost">
                        {{ $t('billings.atPerUnit',[$t('currency', [childNode.perMinCost?.toFixed(8)]), childNode.rateUnit]) }}
                    </span>
                </template>
            </v-col>
            <v-col class="text-right pr-0 pt-2 mt-0">
                <template v-if="childNode.type === 'percent'">
                    {{ (childNode.cost ?? 0).toFixed(2) }}%
                </template>
                <template v-else>
                    {{ $t('currency', [childNode.cost?.toFixed(2)]) }}
                </template>
            </v-col>
            <v-col
                cols="2"
                class="pr-0 pt-2 mt-0">
                <HelpToolTip
                    v-if="node.tooltip"
                    :size="16">
                    <template #message>
                        <p>
                            {{ node.tooltip }}
                        </p>
                    </template>
                </HelpToolTip>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import HelpToolTip from 'components/common/HelpTooltip'

    const BillingPanelLeafNodes = {
        name: 'BillingPanelLeafNodes',
        components: {
            HelpToolTip,
        },
        props: {
            node: {
                type: Object,
                required: true,
            },
        },
        computed: {
            hasChildren() {
                const { children, } = this.node
                return children && children.length
            },
        },
    }

    export default BillingPanelLeafNodes
</script>
