import { listConfigs, getConfig, listProducts, listConfigsPerProduct, createConfig, deleteConfig, applyToService, removeConfigFromService, getConfigsPerTopology, getConfigValues, setConfigValues, updateConfig } from 'services/configurations'
import to from 'await-to-js'
import common from 'config/common'
import configManager from 'config/configManager.js'
import { hasFlags } from 'helpers/index.js'
const { CONFIG_MANAGER_FLAGS, } = configManager

export default {
    state: {
        configs: [],
        products: [],
        configsPerProduct: {},
        selectedConfig: null,
        configsLoading: false,
        newConfigResponse: false,
        deleteConfigSuccess: false,
        applyToServiceSuccess: false,
        configInEditMode: false,
        removeConfigSuccess: false,
        topologyConfigs: [],
        selectedValues: null,
        selectedValuesListSuccess: false,
        selectedConfigDetails: [],
    },
    mutations: {
        setConfigs(state, configs) {
            state.configs = configs
        },
        setProducts(state, products) {
            state.products = products
        },
        setConfigsPerProduct(state, { productName, response, }) {
            state.configsPerProduct[productName] = response
        },
        setConfigsLoading(state, payload) {
            state.configsLoading = payload
        },
        setSelectedConfig(state, payload) {
            state.selectedConfig = payload
        },
        setNewConfigResponse(state, payload) {
            state.newConfigResponse = payload
        },
        setDeleteConfigSuccess(state, payload) {
            state.deleteConfigSuccess = payload
        },
        setApplyToServiceSuccess(state, payload) {
            state.applyToServiceSuccess = payload
        },
        toggleEditMode(state, bool) {
            state.configInEditMode = bool
        },
        setRemoveConfigSuccess(state, payload) {
            state.removeConfigSuccess = payload
        },
        setTopologyConfigs(state, payload) {
            state.topologyConfigs = payload
        },
        setSelectedValues(state, payload) {
            state.selectedValues = payload
        },
        setSelectedValuesListSuccess(state, payload) {
            state.selectedValuesListSuccess = payload
        },
        setSelectedConfigDetails(state, payload) {
            state.selectedConfigDetails = payload
        },
        setUpdateConfigSuccess(state, payload) {
            state.updateConfigSuccess = payload
        },
    },
    actions: {
        async getConfigsList({ commit, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(listConfigs())
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setConfigsLoading', false)
            } else {
                commit('setConfigs', response)
            }
            commit('setConfigsLoading', false)
        },
        async getProducts({ commit, }, { topologyId, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(listProducts(topologyId))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setConfigsLoading', false)
            } else {
                commit('setProducts', response)
            }
            commit('setConfigsLoading', false)
        },
        async getConfigsPerProduct({ commit, }, { productName, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(listConfigsPerProduct(productName))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setConfigsLoading', false)
            } else {
                commit('setConfigsPerProduct', { productName, response, })
            }
            commit('setConfigsLoading', false)
        },
        async getSelectedConfig({ commit, }, { configId, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(getConfig(configId))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setSelectedConfig', false)
                commit('setConfigsLoading', false)
            } else {
                commit('setSelectedConfig', response)
            }
            commit('setConfigsLoading', false)
        },
        async applyToService({ commit, }, { serviceId, configId, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(applyToService(serviceId, configId))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setApplyToServiceSuccess', false)
                commit('setConfigsLoading', false)
            } else {
                commit('setApplyToServiceSuccess', response || true)
            }
            commit('setConfigsLoading', false)
        },
        async deleteSelectedConfig({ commit, }, { configId, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(deleteConfig(configId))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setDeleteConfigSuccess', false)
                commit('setConfigsLoading', false)
            } else {
                commit('setDeleteConfigSuccess', response || true)
            }
            commit('setConfigsLoading', false)
        },
        async createNewConfig({ commit, }, { configObject, setAsSelected = false, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(createConfig(configObject))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setNewConfigResponse', null)
                commit('setConfigsLoading', false)
            } else {
                commit('setNewConfigResponse', response)
                setAsSelected && commit('setSelectedConfig', response)
            }
            commit('setConfigsLoading', false)
        },
        clearSelectedConfig({ commit, }) {
            commit('setSelectedConfig', null)
        },
        async removeConfig({ commit, }, { serviceId, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(removeConfigFromService(serviceId))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setRemoveConfigSuccess', false)
                commit('setConfigsLoading', false)
            } else {
                commit('setRemoveConfigSuccess', response || true)
            }
            commit('setConfigsLoading', false)
        },
        async getTopologyConfigs({ commit, dispatch,}, { topologyName, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(getConfigsPerTopology(topologyName))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setConfigsLoading', false)
            } else {
                dispatch('handleConversion', {response,})
                commit('setTopologyConfigs', response)
            }
            commit('setConfigsLoading', false)
        },
        async getSelectedValues({ commit, }, { configId, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(getConfigValues(configId))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setConfigsLoading', false)
            } else {
                commit('setSelectedValues', response)
            }
            commit('setConfigsLoading', false)
        },
        async setSelectedValuesList({ commit, }, { configId, selectedValuesList, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(setConfigValues(configId, selectedValuesList))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setConfigsLoading', false)
                commit('setSelectedValuesListSuccess', false)
            } else {
                commit('setSelectedValuesListSuccess', response || true)
            }
            commit('setConfigsLoading', false)
        },
        setSelectedConfigDetailsList({ commit, }, { configDetails, }) {
            commit('setSelectedConfigDetails', configDetails)
        },
        handleConversion({}, {response,}) {
            function formatToBytes(value) {
                const k = 1024
                let i = 0
                let val = parseInt(value, 10) || 0
                while (val >= k && ++i) {
                    val /= k
                }
                return val.toFixed(0) + common.SIZE_UNITS[i]
            }
            response.forEach(config => {
                // Handle variables with size options & convert them into bytes
                if (hasFlags([CONFIG_MANAGER_FLAGS.SI_UNITS], config.flags)) {
                    let newDefValues = []
                    config.default_value.forEach(dValue => {
                        if (Number(dValue > -1)) newDefValues.push(formatToBytes(dValue))
                    })
                    if (newDefValues.length) config['default_value'] = newDefValues
                    let newValues = []
                    config.allowed_values.forEach(value => {
                        if (Number(value) && Number(value) > -1) {
                            newValues.push(formatToBytes(value))
                        } else {
                            newValues.push(value)
                        }
                    })
                    config['allowed_values'] = newValues
                }
            })
        },
        async updateSelectedConfig({ commit, }, { configId, nameObject, }) {
            commit('setConfigsLoading', true)
            const [error, response] = await to(updateConfig(configId, nameObject))
            if (error) {
                commit('showMessage', { text: error?.response?.data?.errors?.[0]?.message, type: common.messageType.ERROR, })
                commit('setUpdateConfigSuccess', false)
                commit('setConfigsLoading', false)
            } else {
                commit('setUpdateConfigSuccess', response || true)
            }
            commit('setConfigsLoading', false)
        },
    },
    getters: {
        configs: state => state.configs,
        products: state => state.products,
        configsPerProduct: state => state.configsPerProduct,
        configsLoading: state => state.configsLoading,
        selectedConfig: state => state.selectedConfig,
        newConfigResponse: state => state.newConfigResponse,
        deleteConfigSuccess: state => state.deleteConfigSuccess,
        applyToServiceSuccess: state => state.applyToServiceSuccess,
        configInEditMode: state => state.configInEditMode,
        removeConfigSuccess: state => state.removeConfigSuccess,
        topologyConfigs: state => state.topologyConfigs,
        selectedValues: state => state.selectedValues,
        selectedValuesListSuccess: state => state.selectedValuesListSuccess,
        selectedConfigDetails: state => state.selectedConfigDetails,
        updateConfigSuccess: state => state.updateConfigSuccess,
    },
}
