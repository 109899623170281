import _ from 'lodash'
import { getNodeTypeForPricingAPI } from 'utils/service'

export function parseInstancesCost (instancesCostResponse = {}, params = {}) {
    const instancesCost = []
    instancesCostResponse[_.camelCase(params['meter_id'])]?.forEach(item => {
        const cost = {
            'name': item.dimensions?.size,
            'node': getNodeTypeForPricingAPI(item.dimensions?.topology),
            'tier': item.dimensions?.tier,
            'product': '',
            'topology': item.dimensions?.topology,
            'provider': params.provider,
            'pricePerMin': item.price?.pricePerMin,
            'pricePerHour': item.price?.pricePerHour,
            'pricePerMonth': item.price?.pricePerMonth,
            'infraPricePerMin': item.price?.scaleFabricPricePerMin,
            'infraPricePerHour': item.price?.scaleFabricPricePerHour,
            'infraPricePerMonth': item.price?.scaleFabricPricePerMonth,
        }
        const architecture = item.dimensions?.architecture
        if (architecture?.length) {
            cost['arch'] = architecture
            instancesCost.push(cost)
        } else {
            const archs = ['arm64', 'amd64']
            archs.forEach(arch => {
                instancesCost.push({ ...cost, arch, })
            })
        }
    })
    return instancesCost
}
