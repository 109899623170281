import to from 'await-to-js'
import i18n from 'plugins/i18n.js'
import common from 'config/common.js'
import { getUniqueString } from 'helpers/string.js'
import {
    isCopilotAgent,
    getDatasources,
    getDatasource,
    addDatasource,
    editDatasource,
    removeDatasource,
    getAgents,
    createAgent,
    editAgent,
    removeAgent,
    getQueries,
    createQuery,
    updateQuery,
    removeQuery,
    getContext,
    removeContextTable,
    addContextTable,
    editContextTable,
    editContextTableColumns,
    getChatSessions,
    getChatSession,
    removeChatSession,
    clearChatSession,
    createChatSession,
    sendChatMessage,
    submitChatFeedback,
    analyseChatMessageQuery,
    getUsageQuotas
} from 'services/copilot.js'

export default {
    state: {
        datasources: [],
        datasourcesErrorMsg: false,
        datasourcesLoading: false,
        selectedDatasourceId: null,
        selectedDatasourceSchema: null,
        agents: [],
        agentsErrorMsg: false,
        agentsLoading: false,
        agentContextLoading: false,
        selectedAgentId: false,
        chats: [],
        chatsErrorMsg: false,
        chatsLoading: false,
        chatLoading: false,
        selectedChatId: null,
        isShowOnlyGoldenQueries: false,
        updateQuerySuccess: false,
        usageQuotas: [],
    },
    mutations: {
        setDatasources(state, datasourcesList) {
            state.datasources = datasourcesList
        },
        setDatasourceSchemas(state, datasourceData) {
            const datasource = state.datasources.find(datasource => datasource.id === datasourceData.id)
            if (datasource && datasourceData.schemas) {
                datasource.schemas = datasourceData.schemas
            }
        },
        addDatasource(state, datasource) {
            state.datasources.push(datasource)
        },
        editDatasource(state, { datasourceId, data, }) {
            const datasource = state.datasources.find(datasource => datasource.id === datasourceId)
            if (datasource) {
                datasource.name = data.name
                datasource.hostname = data.hostname
                datasource.port = data.port
                datasource.username = data.username
            }
        },
        removeDatasource(state, datasourceId) {
            const datasourceIndex = state.datasources.findIndex(datasource => datasource.id === datasourceId)
            if (datasourceIndex !== -1) {
                state.datasources.splice(datasourceIndex, 1)
                if (state.selectedDatasourceId === datasourceId) {
                    state.selectedDatasourceId = state.datasources[0]
                }
            }
        },
        setDatasourcesError(state, error) {
            state.datasourcesErrorMsg = error
        },
        setDatasourcesLoading(state, status) {
            state.datasourcesLoading = status
        },
        setSelectedDatasourceId(state, datasourceId) {
            state.selectedDatasourceId = datasourceId
            if (datasourceId) {
                localStorage.setItem('copilotDatasourceId', datasourceId)
            }
        },
        setSelectedDatasourceSchema(state, schema) {
            state.selectedDatasourceSchema = schema
        },
        setAgents(state, agentsList) {
            state.agents = agentsList
        },
        updateAgents(state, agentsList) {
            agentsList.forEach(agent => {
                const existingAgent = state.agents.find(eAgent => eAgent.id === agent.id)
                if (existingAgent) {
                    existingAgent.name = agent.name
                    existingAgent.description = agent.description
                    existingAgent.status = agent.status
                    existingAgent.contextId = agent.contextId
                    existingAgent.indexStatus = agent.indexStatus
                }
            })
        },
        setAgentsError(state, error) {
            state.agentsErrorMsg = error
        },
        setAgentsLoading(state, status) {
            state.agentsLoading = status
        },
        setAgentContextLoading(state, status) {
            state.agentContextLoading = status
        },
        setSelectedAgentId(state, agentId) {
            state.selectedAgentId = agentId
            if (agentId) {
                localStorage.setItem('copilotAgentId', agentId)
            }
        },
        addAgent(state, agent) {
            state.agents.push(agent)
        },
        editAgent(state, { agentId, data, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agent) {
                agent.name = data.name
                agent.description = data.description
            }
        },
        removeAgent(state, agentId) {
            const agentIndex = state.agents.findIndex(agent => agent.id === agentId)
            if (agentIndex !== -1) {
                state.agents.splice(agentIndex, 1)
            }
        },
        setAgentQueries(state, { agentId, queries, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agentId && queries) {
                agent.queries = queries
            }
        },
        addAgentQuery(state, { agentId, query, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agent && agent.queries) {
                agent.queries.unshift(query)
                // Reorder
                const nullIds = agent.queries.filter(item => item.id === null)
                const nonNullIds = agent.queries.filter(item => item.id !== null)
                agent.queries = [...nullIds, ...nonNullIds]
            }
        },
        clearTempAgentQueries(state, agentId) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agent && agent.queries) {
                agent.queries = agent.queries.filter(query => query.id)
            }
        },
        updateAgentQuery (state, { agentId, queryId, query, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agent && agent.queries) {
                const queryIndex = agent.queries.findIndex(query => query.id === queryId)
                if (queryIndex !== -1) {
                    agent.queries[queryIndex] = query
                }
            }
        },
        removeAgentQuery (state, { agentId, queryId, tempQueryId, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agent && agent.queries) {
                const queryIndex = agent.queries.findIndex(query => query.id === queryId || (query.tempId && query.tempId === tempQueryId))
                if (queryIndex !== -1) {
                    agent.queries.splice(queryIndex, 1)
                }
            }
        },
        setAgentContext(state, { agentId, context, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agentId && context) {
                agent.context = context
            }
        },
        removeAgentContextTable (state, { agentId, tableId, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agent && agent.context) {
                const tableIndex = agent.context.tables.findIndex(table => table.id === tableId)
                if (tableIndex !== -1) {
                    agent.context.tables.splice(tableIndex, 1)
                }
            }
        },
        addAgentContextTable(state, { agentId, table, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agent && agent.context) {
                agent.context.tables.push(table)
            }
        },
        editAgentContextTableColumns(state, { agentId, tableId, columns, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (agent && agent.context) {
                const table = agent.context.tables.find(table => table.id === tableId)
                if (table) {
                    table.columns = columns
                }
            }
        },
        setChats(state, chatsList) {
            state.chats = chatsList
        },
        addChat(state, chat) {
            state.chats.push(chat)
        },
        removeChat(state, chatId) {
            const chatIndex = state.chats.findIndex(chat => chat.id === chatId)
            if (chatIndex !== -1) {
                state.chats.splice(chatIndex, 1)
            }
        },
        clearChat(state, chatId) {
            const chat = state.chats.find(chat => chat.id === chatId)
            if (chat) {
                chat.entries = []
            }
        },
        setChatsError(state, error) {
            state.chatsErrorMsg = error
        },
        setChatsLoading(state, status) {
            state.chatsLoading = status
        },
        setChatLoading(state, status) {
            state.chatLoading = status
        },
        setSelectedChatId(state, chatId) {
            state.selectedChatId = chatId
            if (chatId) {
                localStorage.setItem('copilotChatSessionId', chatId)
            }
        },
        pushChatSessionEntry(state, { chatId, entry, }) {
            const chat = state.chats.find(chat => chat.id === chatId)
            if (chat && entry) {
                chat.entries.push(entry)
            }
        },
        updateChatSessionEntry(state, { chatId, entryId, entry, }) {
            const chat = state.chats.find(chat => chat.id === chatId)
            if (chat && entry) {
                const chatEntry = chat.entries.find(chatEntry => chatEntry.id === entryId)
                chatEntry.id = entry.id
                chatEntry.feedback = entry.feedback
                chatEntry.chat.response = entry.chat.response
            }
        },
        setChatSessionEntries(state, chatData) {
            const chat = state.chats.find(chat => chat.id === chatData.id)
            if (chat && chatData.entries) {
                chat.entries = chatData.entries
            }
        },
        updateChatSessionEntryFeedback(state, { chatId, entryId, feedback, }) {
            const chat = state.chats.find(chat => chat.id === chatId)
            if (chat) {
                const entryIndex = chat.entries.findIndex(entry => entry.id === entryId)
                if (entryIndex !== -1) {
                    const entry = chat.entries[entryIndex]
                    entry.feedback = feedback
                }
            }
        },
        updateChatSessionEntryConfidence(state, { chatId, entryId, confidence, }) {
            const chat = state.chats.find(chat => chat.id === chatId)
            if (chat) {
                const entryIndex = chat.entries.findIndex(entry => entry.id === entryId)
                if (entryIndex !== -1) {
                    const entry = chat.entries[entryIndex]
                    entry.confidence = confidence
                }
            }
        },
        setShowOnlyGoldenQueries(state, status) {
            state.isShowOnlyGoldenQueries = status
        },
        setUpdateQuerySuccess(state, status) {
            state.updateQuerySuccess = status
        },
        setUsageQuotas(state, quotas) {
            state.usageQuotas = quotas
        },
    },
    actions: {
        async fetchDatasources({ commit, }) {
            commit('setDatasourcesLoading', true)
            const [error, response] = await to(getDatasources())
            if (error) {
                commit('setDatasourcesError', true)
                commit('showMessage', { text: i18n.t('errors.fetchDatasources'), type: common.messageType.ERROR, error, })
            } else {
                commit('setDatasources', response)
                commit('setDatasourcesError', false)
            }
            commit('setDatasourcesLoading', false)
        },
        async fetchDatasource({ commit, }, datasourceId) {
            commit('setDatasourcesLoading', true)
            const [error, response] = await to(getDatasource(datasourceId))
            if (error) {
                commit('setDatasourcesError', true)
                commit('showMessage', { text: i18n.t('errors.fetchDatasources'), type: common.messageType.ERROR, error, })
            } else {
                commit('setDatasourceSchemas', response)
                commit('setSelectedDatasourceSchema', response.schemas[0]?.name)
                commit('setDatasourcesError', false)
            }
            commit('setDatasourcesLoading', false)
            return response
        },
        async selectDatasource({ commit, dispatch, }, datasourceId) {
            commit('setSelectedChatId', null)
            commit('setSelectedDatasourceId', datasourceId)
            commit('setShowOnlyGoldenQueries', false)
            await dispatch('fetchDatasource', datasourceId)
        },
        selectDatasourceSchema({ commit, }, schema) {
            commit('setSelectedDatasourceSchema', schema)
        },
        async addDatasource({ commit, }, datasource) {
            const [error, response] = await to(addDatasource(datasource))
            if (error) {
                const errorMsg = error?.response?.status === 400 ? i18n.t('errors.connectDatasource') : i18n.t('errors.saveDatasource')
                commit('showMessage', { text: errorMsg, type: common.messageType.ERROR, error, })
                return null
            } else {
                commit('addDatasource', response)
                return response.id
            }
        },
        async editDatasource({ commit, }, { datasourceId, data, }) {
            const [error, response] = await to(editDatasource(datasourceId, data))
            if (error) {
                const errorMsg = error?.response?.status === 400 ? i18n.t('errors.connectDatasource') : i18n.t('errors.saveDatasource')
                commit('showMessage', { text: errorMsg, type: common.messageType.ERROR, error, })
                return null
            } else {
                commit('editDatasource', { datasourceId, data: response, })
                return datasourceId
            }
        },
        async removeDatasource({ commit, }, datasourceId) {
            const [error] = await to(removeDatasource(datasourceId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.removeDatasource'), type: common.messageType.ERROR, error, })
            } else {
                commit('removeDatasource', datasourceId)
            }
        },
        async fetchAgents({ commit, }) {
            commit('setAgentsLoading', true)
            const [error, response] = await to(getAgents())
            if (error) {
                commit('setAgentsError', true)
                commit('showMessage', { text: i18n.t('errors.fetchAgents'), type: common.messageType.ERROR, error, })
            } else {
                commit('setAgents', response)
                commit('setAgentsError', false)
            }
            commit('setAgentsLoading', false)
        },
        async refreshAgents({ commit, }) {
            const [error, response] = await to(getAgents(true))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.fetchAgents'), type: common.messageType.ERROR, error, })
            } else {
                commit('updateAgents', response)
            }
        },
        selectAgent({ commit, dispatch, }, agentId) {
            commit('setSelectedChatId', null)
            commit('setSelectedAgentId', agentId)
            commit('setShowOnlyGoldenQueries', false)
            Promise.all([
                dispatch('fetchAgentContext', agentId),
                dispatch('fetchAgentQueries', agentId)
            ])
        },
        async newAgent({ commit, }, { name, description, contextId, }) {
            const [error, response] = await to(createAgent({ name, description, contextId, }))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.createAgent'), type: common.messageType.ERROR, error, })
            } else {
                commit('addAgent', response)
            }
            return response
        },
        async editAgent({ commit, }, { agentId, data, }) {
            const [error, response] = await to(editAgent(agentId, data))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.editAgent'), type: common.messageType.ERROR, error, })
                return null
            } else {
                commit('editAgent', { agentId, data: response, })
                return agentId
            }
        },
        async removeAgent({ state, commit, dispatch, }, { agentId, }) {
            const [error] = await to(removeAgent(agentId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.removeAgent'), type: common.messageType.ERROR, error, })
            } else {
                commit('removeAgent', agentId)
                if (state.selectedAgentId === agentId) {
                    dispatch('selectAgent', state.agents[0].id)
                }
            }
        },
        async fetchAgentQueries({ state, commit, }, agentId) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (!agent) {
                return
            }
            commit('setAgentsLoading', true)
            const [error, response] = await to(getQueries(agent.id, 'GOLDEN'))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.getAgentContext'), type: common.messageType.ERROR, error, })
            } else {
                commit('setAgentQueries', { agentId, queries: response, })
            }
            commit('setAgentsLoading', false)
        },
        async addAgentQuery({ state, commit, }, query) {
            const agent = state.agents.find(agent => agent.id === query.agentId)
            if (!agent) {
                return
            }
            const [error, response] = await to(createQuery(query))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.addQuery'), type: common.messageType.ERROR, error, })
            } else {
                commit('addAgentQuery', { agentId: query.agentId, query: response, })
            }
        },
        addTempAgentQuery({ state, commit, }, agentId) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (!agent) {
                return
            }
            const query = {
                id: null,
                tempId: getUniqueString(8),
                agentId: agent.id,
                type: 'GOLDEN',
                chat: {
                    prompt: '',
                    response: {
                        content: '',
                        errorText: '',
                        sqlText: ' ',
                        colKeys: [],
                    },
                },
            }
            commit('addAgentQuery', { agentId: query.agentId, query, })
        },
        clearTempAgentQueries({ state, commit, }, agentId) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (!agent) {
                return
            }
            commit('clearTempAgentQueries', agent.id)
        },
        async updateAgentQuery({ commit, }, { agentId, queryId, query, }) {
            commit('setUpdateQuerySuccess', false)
            const [error, response] = await to(updateQuery(queryId, query))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.updateQuery'), type: common.messageType.ERROR, error, })
            } else {
                commit('updateAgentQuery', { agentId, queryId, query: response, })
                commit('showMessage', { text: i18n.t('success.updateQuery'), type: common.messageType.SUCCESS, })
            }
            commit('setUpdateQuerySuccess', !error)
        },
        async removeAgentQuery({ state, commit, }, { agentId, queryId, tempQueryId, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (!agent) {
                return
            }
            if (tempQueryId) {
                commit('removeAgentQuery', { agentId, tempQueryId, })
            } else {
                const [error] = await to(removeQuery(queryId))
                if (error) {
                    commit('showMessage', { text: i18n.t('errors.removeQuery'), type: common.messageType.ERROR, error, })
                } else {
                    commit('removeAgentQuery', { agentId, queryId, })
                }
            }
        },
        async fetchAgentContext({ state, commit, }, agentId) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (!agent || !agent.contextId) {
                return
            }
            commit('setAgentContextLoading', true)
            const [error, response] = await to(getContext(agent.contextId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.getAgentContext'), type: common.messageType.ERROR, error, })
            } else {
                commit('setAgentContext', { agentId, context: response, })
            }
            commit('setAgentContextLoading', false)
        },
        async removeAgentContextTable({ state, commit, }, { agentId, tableId, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (!agent || !agent.context) {
                return
            }
            const [error] = await to(removeContextTable(agent.context.id, tableId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.removeContextTable'), type: common.messageType.ERROR, error, })
            } else {
                commit('removeAgentContextTable', { agentId, tableId, })
            }
        },
        async addAgentContextTable({ state, commit, }, { agentId, tableName, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (!agent || !agent.context) {
                return
            }
            const [error, response] = await to(addContextTable(agent.context.id, tableName))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.addContextTable'), type: common.messageType.ERROR, error, })
            } else {
                commit('addAgentContextTable', { agentId, table: response, })
            }
        },
        async editAgentContextTable({ commit, }, { contextId, tableId, tableDescription, }) {
            const [error] = await to(editContextTable(contextId, tableId, tableDescription))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.editContextTable'), type: common.messageType.ERROR, error, })
                return false
            } else {
                // TODO: Mutate with the bulk update
                // commit('editAgentContextTable', { agentId, tableId, tableDescription })
                return true
            }
        },
        async editAgentContextTableColumns({ state, commit, }, { agentId, tableId, columns, }) {
            const agent = state.agents.find(agent => agent.id === agentId)
            if (!agent || !agent.context) {
                return
            }
            const [error, response] = await to(editContextTableColumns(agent.context.id, tableId, columns))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.editContextTable'), type: common.messageType.ERROR, error, })
                return false
            } else {
                commit('editAgentContextTableColumns', { agentId, tableId, columns: response.columns, })
                return true
            }
        },
        async fetchChatSessions({ commit, } ) {
            commit('setChatsLoading', true)
            const [error, response] = await to(getChatSessions())
            if (error) {
                commit('setChatsError', true)
                commit('showMessage', { text: i18n.t('errors.fetchChatSessions'), type: common.messageType.ERROR, error, })
            } else {
                commit('setChats', response)
                commit('setChatsError', false)
            }
            commit('setChatsLoading', false)
        },
        async selectChatSession({ commit, dispatch, }, chatSessionId) {
            const chatSession = await dispatch('getChatSession', chatSessionId)
            commit('setSelectedChatId', chatSessionId)
            if (chatSession?.agentConfig?.dbName) {
                commit('setSelectedDatasourceSchema', chatSession.agentConfig.dbName)
            }
        },
        resetCopilotState({ commit, }) {
            commit('setSelectedDatasourceId', null)
            commit('setDatasources', [])
            commit('setSelectedAgentId', null)
            commit('setAgents', [])
            commit('setSelectedChatId', null)
            commit('setChats', [])
            commit('setUsageQuotas', [])
        },
        async newChatSession({ state, commit, }, { agentId, datasourceId, datasourceSchema, }) {
            const config = {}
            if (datasourceId && !isCopilotAgent('ama', state.selectedAgentId)) {
                config.datasourceId = datasourceId
            }
            if (datasourceId && isCopilotAgent('data', state.selectedAgentId)) {
                config.dbName = datasourceSchema
            }
            const [error, response] = await to(createChatSession(agentId, Object.keys(config).length ? config : null))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.createChatSession'), type: common.messageType.ERROR, error, })
            } else {
                commit('setShowOnlyGoldenQueries', false)
                commit('addChat', response)
                commit('setSelectedChatId', response.id)
            }
        },
        async getChatSession({ commit, }, chatSessionId) {
            commit('setChatLoading', true)
            const [error, response] = await to(getChatSession(chatSessionId))
            if (error) {
                commit('setSelectedChatId', null)
                commit('showMessage', { text: i18n.t('errors.getChatSession'), type: common.messageType.ERROR, error, })
            } else {
                commit('setChatSessionEntries', response)
            }
            commit('setChatLoading', false)
            return response
        },
        async removeChatSession({ state, commit, dispatch, }, chatSessionId) {
            commit('setChatLoading', true)
            const [error] = await to(removeChatSession(chatSessionId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.removeChatSession'), type: common.messageType.ERROR, error, })
            } else {
                commit('removeChat', chatSessionId)
                if (state.selectedChatId === chatSessionId && state.chats.length) {
                    await dispatch('selectChatSession', state.chats[state.chats.length - 1]?.id)
                }
            }
            commit('setChatLoading', false)
        },
        async clearChatSession({ commit, }, chatSessionId) {
            commit('setChatLoading', true)
            const [error] = await to(clearChatSession(chatSessionId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.clearChatSession'), type: common.messageType.ERROR, error, })
            } else {
                commit('clearChat', chatSessionId)
            }
            commit('setChatLoading', false)
        },
        async newChatMessage({ commit, }, { prompt, chatSessionId, }) {
            const entryId = getUniqueString(16)
            commit('pushChatSessionEntry', {
                chatId: chatSessionId,
                entry: {
                    id: entryId,
                    chat: { prompt, response: null,},
                    feedback: null,
                    confidence: null,
                },
            })
            const [error, response] = await to(sendChatMessage(prompt, chatSessionId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.sendChatSessionMessage'), type: common.messageType.ERROR, error, })
            } else {
                commit('updateChatSessionEntry', { chatId: chatSessionId, entryId, entry: response, })
            }
        },
        async submitChatFeedback({ commit, }, { feedback, entryId, chatSessionId, }) {
            const [error] = await to(submitChatFeedback(feedback, entryId, chatSessionId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.submitChatFeedback'), type: common.messageType.ERROR, error, })
            }
            commit('updateChatSessionEntryFeedback', {
                chatId: chatSessionId,
                entryId,
                feedback,
            })
        },
        async analyseChatMessageQuery({ commit, }, { chatSessionId, entryId, }) {
            const [error, response] = await to(analyseChatMessageQuery(chatSessionId, entryId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.unknown'), type: common.messageType.ERROR, error, })
            }
            commit('updateChatSessionEntryConfidence', {
                chatId: chatSessionId,
                entryId,
                confidence: response,
            })
        },
        async setShowOnlyGoldenQueries({ commit, }, status) {
            commit('setShowOnlyGoldenQueries', status)
        },
        async fetchUsageQuota({ commit, }) {
            const [error, data] = await to(getUsageQuotas())
            if (error) {
                commit('showMessage', { text: i18n.t('errors.fetchUsageQuota'), type: common.messageType.ERROR, error, })
            } else {
                commit('setUsageQuotas', data)
            }
        },
    },
    getters: {
        datasources: state => state.datasources,
        datasourcesError: state => state.datasourcesErrorMsg,
        areDatasourcesLoading: state => state.datasourcesLoading,
        selectedDatasource: state => state.datasources.find(datasource => datasource.id === state.selectedDatasourceId),
        selectedDatasourceSchema: state => state.selectedDatasourceSchema,
        getDatasourceById(state) {
            return (id) => state.datasources.find(datasource => datasource.id === id)
        },
        agents: state => state.agents,
        agentsError: state => state.agentsErrorMsg,
        areAgentsLoading: state => state.agentsLoading,
        isAgentContextLoading: state => state.agentContextLoading,
        selectedAgent: state => state.agents.find(agent => agent.id === state.selectedAgentId),
        getAgentById(state) {
            return (id) => state.agents.find(agent => agent.id === id)
        },
        chatSessions: state => state.chats,
        agentChatSessions: state => state.chats.filter(chat => {
            if (isCopilotAgent('data', state.selectedAgentId) || isCopilotAgent('dba', state.selectedAgentId)) {
                return chat.agentId === state.selectedAgentId && chat.agentConfig?.datasourceId === state.selectedDatasourceId
            }
            return chat.agentId === state.selectedAgentId
        }),
        selectedChatSession: state => state.chats.find(chat => chat.id === state.selectedChatId),
        chatSessionsError: state => state.chatsErrorMsg,
        areChatSessionsLoading: state => state.chatsLoading,
        isChatSessionLoading: state => state.chatLoading,
        isShowOnlyGoldenQueries: state => state.isShowOnlyGoldenQueries,
        isUpdateQuerySuccess: state => state.updateQuerySuccess,
        inputTokensQuota: state => state.usageQuotas.find(quota => quota.name === 'llm_input'),
        outputTokensQuota: state => state.usageQuotas.find(quota => quota.name === 'llm_output'),
    },
}
