<style lang="scss" scoped>
.control-label {
    font-size: 0.675rem;
}
.section-header {
    margin: 0 -12px;
    justify-content: space-between;

    .section-right {
        gap: 4px
    }

    .separator {
        border: 1px solid;
        margin: 4px 0;
    }
}
</style>

<template>
    <div class="mt-6">
        <v-row class="mb-0">
            <v-col
                cols="11"
                sm="5">
                <label class="d-block color text-small-text control-label">
                    {{ $t('billings.invoiceMonth') }}
                </label>
                <MonthYearPicker
                    @change="getHistoricalBills" />
            </v-col>
        </v-row>
        <v-container v-if="!isFetchingHistoricalBills">
            <template v-if="historicalBills && !historicalBillsErrors">
                <template v-if="showNewUI">
                    <!-- summary -->
                    <div class="section-header d-flex">
                        <div class="section-title">
                            {{ $t('billings.summary') }}
                        </div>
                        <div class="section-right d-flex">
                            <span>
                                {{ $t('billings.usageDays') }}: {{ historicalBills.usageDays }}
                            </span>
                            <span class="separator"></span>
                            <span>
                                {{ $t('billings.billingMode') }}: {{ historicalBills.billingMode }}
                            </span>
                        </div>
                    </div>
                    <HistoricalBillSummary
                        :historical-bills="historicalBills" />

                    <!-- service usage table -->
                    <div class="mt-10">
                        <div
                            v-if="processingBillingData"
                            class="d-flex justify-center mt-16">
                            <v-progress-circular
                                indeterminate
                                size="60" />
                        </div>
                        <template v-else>
                            <BillingUsageTable
                                :current-usage-sort="currentUsageSort"
                                :usage-data="usageData"
                                :header-title="$t('billings.serviceWiseBreakdown')"
                                :is-one-level-tree="false" />
                        </template>
                    </div>

                    <!-- bill breakdown table -->
                    <div class="mt-10">
                        <BillingUsageTable
                            :current-usage-sort="currentUsageSort"
                            :usage-data="breakdownData"
                            :header-title="$t('billings.billBreakdown')"
                            :is-one-level-tree="true" />
                        <v-row>
                            <v-col cols="8" />
                            <BillingUsageTotal
                                :total="total" />
                            <v-col cols="2" />
                        </v-row>
                    </div>
                </template>
                <template v-else>
                    <HistoricalBillSummary
                        :historical-bills="historicalBills" />

                    <div
                        class="mt-4">
                        <div
                            v-if="processingBillingData"
                            class="d-flex justify-center mt-16">
                            <v-progress-circular
                                indeterminate
                                size="60" />
                        </div>
                        <template v-else>
                            <BillingUsageTable
                                :current-usage-sort="currentUsageSort"
                                :usage-data="usageData"
                                :is-one-level-tree="false" />
                            <v-row>
                                <v-col cols="8" />
                                <BillingUsageTotal
                                    :total="total" />
                                <v-col cols="2" />
                            </v-row>
                        </template>
                    </div>
                </template>
            </template>
            <template v-else>
                <v-row
                    justify="center"
                    class="font-weight-bold text-subtitle-1 pa-2">
                    {{ $t('errors.noData') }}
                </v-row>
            </template>
        </v-container>
        <v-container
            v-else
            class="fill-height mt-8"
            fluid>
            <v-row
                align="center"
                justify="center">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import i18n from 'plugins/i18n'
    import HistoricalBillSummary from 'components/billing/HistoricalBillSummary.vue'
    import BillingUsageTable from 'components/billing/BillingUsageTable.vue'
    import BillingUsageTotal from 'components/billing/BillingUsageTotal.vue'
    import MonthYearPicker from 'components/common/MonthYearPicker.vue'
    import {
        getTopologyTitle,
        isMonthYearForNewBilling,
        getNewUsageChildData
    } from 'utils/billing'

    export default {
        name: 'HistoricalUsage',
        components: {
            BillingUsageTable,
            BillingUsageTotal,
            MonthYearPicker,
            HistoricalBillSummary,
        },
        data() {
            return {
                processingBillingData: false,
                currentUsageSort: 'service',
                selectedMonthYear: {},
                usageData: [],
            }
        },
        computed: {
            ...mapGetters([
                'isFetchingHistoricalBills',
                'historicalBillsErrors',
                'historicalBills'
            ]),
            showNewUI () {
                return this.$unleash.isFeatureEnabled('enable-new-historical-bills')
            },
            breakdownData() {
                if(this.$lodash.isEmpty(this.historicalBills)) return []
                return this.createBillBreakdownData()
            },
            total() {
                return this.historicalBills?.total ?? 0
            },
        },
        watch: {
            historicalBills: {
                async handler() {
                    this.processingBillingData = true
                    this.usageData = []
                    if (isMonthYearForNewBilling(this.selectedMonthYear)) {
                        if(!this.$lodash.isEmpty(this.historicalBills?.items)) {
                            await this.transformNewUsageByServices()
                        }
                    } else {
                        if(!this.$lodash.isEmpty(this.historicalBills?.lineItems)){
                            this.transformUsageByServices()
                        }
                    }
                    this.processingBillingData = false
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions(['fetchHistoricalBills']),
            updateUsageSort(sortType) {
                this.currentUsageSort = sortType
            },
            getHistoricalBills(monthYear) {
                this.selectedMonthYear = monthYear
                this.fetchHistoricalBills(monthYear)
            },
            addDataTransferCost(usage) {
                usage.push({
                    title: i18n.t('billings.dataTransferCost'),
                    subTitle: i18n.t('billings.accumulatedMessage'),
                    cost: this.historicalBills?.dataTransferCost,
                    children: [],
                })
            },
            addSupportCost(usage) {
                usage.push({
                    title: i18n.t('billings.supportCost'),
                    subTitle: i18n.t('billings.supportType',[this.historicalBills?.supportType]),
                    cost: this.historicalBills?.supportCost,
                    children: [],
                })
            },
            async transformNewUsageByServices() {
                let usage = []
                let usageData = this.historicalBills?.items
                for (const o of usageData) {
                    let obj = {}
                    const resource = o?.resource || {}
                    obj.title = resource.name ?? '',
                    obj.subTitle = i18n.t(
                        'billings.topologyOnProvider', {
                            topology: getTopologyTitle(resource.dimensions?.topology),
                            provider: resource.dimensions?.provider?.toUpperCase(),
                        }),
                    obj.cost = o?.total
                    obj.children = await getNewUsageChildData(o)
                    usage.push(obj)
                }
                if (!this.showNewUI) {
                    this.addDataTransferCost(usage)
                    this.addSupportCost(usage)
                }
                this.usageData = usage
            },
            transformUsageByServices() {
                let usage = []
                let usageData = this.historicalBills?.lineItems
                usageData?.forEach(o => {
                    let obj = {}
                    let data = this.getChildData(o)
                    obj.title = o?.service?.name ?? '',
                    obj.subTitle = i18n.t(
                        'billings.topologyOnProvider', {
                            topology: getTopologyTitle(o?.service?.topology),
                            provider: o?.service?.provider.toUpperCase(),
                        }),
                    obj.cost = data.total
                    obj.children = data.children
                    usage.push(obj)
                })
                if (!this.showNewUI) {
                    this.addDataTransferCost(usage)
                    this.addSupportCost(usage)
                }
                this.usageData = usage
            },
            createBillBreakdownData() {
                let data = []
                data.push({
                    title: i18n.t('billings.computeCost'),
                    subTitle: i18n.t('billings.computeCostTip'),
                    cost: this.historicalBills?.totalComputeCost,
                    children: [{
                        title: i18n.t('billings.computeCharges'),
                        subTitle: i18n.t('billings.beforeDiscount'),
                        cost: this.historicalBills?.undiscountedComputeCost,
                        hideRateDetails: true,
                        children: [],
                    }, {
                        title: i18n.t('billings.minComputeCost'),
                        cost: this.historicalBills?.minComputeCost,
                        hideRateDetails: true,
                        children: [],
                    }, {
                        title: i18n.t('billings.discount'),
                        cost: this.historicalBills?.discount,
                        type: 'percent',
                        hideRateDetails: true,
                        children: [],
                    }, {
                        title: i18n.t('billings.additionalDiscount'),
                        cost: this.historicalBills?.additionalDiscount,
                        type: 'percent',
                        hideRateDetails: true,
                        children: [],
                    }, {
                        title: i18n.t('billings.computeCharges'),
                        subTitle: i18n.t('billings.afterDiscount'),
                        cost: this.historicalBills?.discountedComputeCost,
                        hideRateDetails: true,
                        children: [],
                    }],
                })
                data.push({
                    title: i18n.t('billings.storageCost'),
                    subTitle: i18n.t('billings.accumulatedMessage'),
                    cost: this.historicalBills?.totalStorageCost,
                    children: [],
                })
                this.addDataTransferCost(data)
                this.addSupportCost(data)
                return data
            },
            getChildData(lineItem) {
                let total = 0
                let children = []

                for (let instance in lineItem?.cost) {
                    let obj = {}
                    let instanceTotal = 0
                    let items = lineItem?.cost[instance] || []
                    obj.title = items[0]?.instance
                        ? (items[0]?.instance.charAt(0).toUpperCase() + items[0]?.instance.slice(1))
                        : ''
                    obj.children = []
                    items.forEach(node => {
                        instanceTotal += node?.totalCost
                        obj.children.push({
                            title: node?.name == 'Compute' ? i18n.t('billings.nodes') : node?.name,
                            ...this.getAttributes(node),
                        })
                    })
                    obj.cost = instanceTotal
                    children.push(obj)
                    total += instanceTotal
                }

                return {children, total,}
            },
            getAttributes(object) {
                let res = {
                    runtime: object?.totalHours,
                    unit: i18n.t('billings.hours'),
                    perMinCost: object?.pricePerHour,
                    cost: object?.totalCost,
                    rateUnit: i18n.t('billings.hours'),
                }
                if (object?.name == 'Compute') {
                    res.activeNodes = object?.activeNodes
                }
                if (object?.name == 'Storage') {
                    res.hideRateDetails = true
                }
                return res
            },
        },
    }
</script>
