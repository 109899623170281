<template>
    <div>
        <v-alert
            v-if="!isEditing"
            outlined
            type="info"
            dismissible
            text>
            {{ $t('info.copilotGeneratingTablesSemantics') }}
        </v-alert>

        <div class="mb-4 font-weight-light text-h5">
            {{ $t('copilot.tableSemantics') }}:
            <v-autocomplete
                :items="agentContextTables"
                :value="table.id"
                item-text="name"
                item-value="id"
                dense
                outlined
                hide-details
                return-object
                class="d-inline-block"
                @change="switchTable" />
        </div>
        <context-modal-body
            :table-description="tableDescription"
            :columns="columns"
            @update:tableDescription="tableDescription = $event"
            @column-toggle="toggleColumn"
            @all-columns-toggle="toggleAllColumns" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ContextModalBody from 'components/copilot/ContextModalBody.vue'

    export default {
        name: 'CopilotAgentModalTablesContext',
        components: {
            ContextModalBody,
        },
        props: {
            agentId: {
                type: String,
                required: true,
            },
            isEditing: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                tableIndex: 0,
                table: {},
                tableName: '',
                tableDescription: '',
                columns: [],
                initialColumnsSnapshot: [],
                updates: [],
            }
        },
        computed: {
            ...mapGetters([
                'agents'
            ]),
            agent() {
                return this.agents.find(agent => agent.id === this.agentId)
            },
            agentContextTables() {
                return this.agent?.context?.tables || []
            },
            isValid() {
                return !!this.tableDescription
            },
        },
        watch: {
            isValid: {
                handler(value) {
                    this.$emit('update:isValid', value)
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions([
                'editAgentContextTable',
                'editAgentContextTableColumns'
            ]),
            toggleColumn(column) {
                column.isSelected = !column.isSelected
                if (!column.isSelected) {
                    column.dataRole = null
                }
            },
            toggleAllColumns(status) {
                this.columns.forEach(column => {
                    column.isSelected = status
                    if (!status) {
                        column.dataRole = null
                    }
                })
            },
            async updateTableContext() {
                const updates = []
                if (this.table.description !== this.tableDescription) {
                    updates.push(this.editAgentContextTable({
                        contextId: this.table.contextId,
                        tableId: this.table.id,
                        tableDescription: this.tableDescription,
                    }))
                }
                if (!this.$lodash.isEqual(this.initialColumnsSnapshot, this.columns)) {
                    const columnsToUpdate = this.columns
                        .filter(column => column.isSelected)
                        .map(column => {
                            return {
                                name: column.name,
                                type: column.type,
                                description: column.description,
                                dataRole: column.dataRole,
                            }
                        })
                    updates.push(this.editAgentContextTableColumns({
                        agentId: this.agentId,
                        tableId: this.table.id,
                        columns: columnsToUpdate,
                    }))
                }
                const [editStatus] = await Promise.all(updates)
                if (editStatus) {
                    this.table.description = this.tableDescription
                }
                return updates
            },
            back() {
                this.$emit('update:actionLabel', 'next')
                const previousTable = this.agentContextTables[this.tableIndex - 1]
                if (previousTable) {
                    this.tableIndex--
                    this.setTable()
                    return false
                }
                return true
            },
            async save() {
                const updates = await this.updateTableContext()
                this.updates = [...this.updates, ...updates]
                const nextTable = this.agentContextTables[this.tableIndex + 1]
                if (updates.includes(false)) {
                    return false
                }
                if (nextTable) {
                    this.tableIndex++
                    this.setTable()
                    return false
                }
                return this.updates
            },
            switchTable(val) {
                const tableIndex = this.agentContextTables.findIndex(table => table.id === val.id)
                if (tableIndex !== -1) {
                    this.tableIndex = tableIndex
                    this.setTable()
                }
            },
            setTable() {
                this.table = this.agentContextTables[this.tableIndex]
                this.tableName = this.table.name
                this.tableDescription = this.table.description
                if (this.table.availableColumns) {
                    this.columns = this.table.availableColumns.map(column => {
                        const contextColumn = this.table.columns.find(col => col.name === column.name)
                        return {
                            ...(contextColumn || column),
                            isSelected: Boolean(contextColumn),
                        }
                    })
                }
                this.initialColumnsSnapshot = this.$lodash.cloneDeep(this.columns)
                if (this.tableIndex === this.agentContextTables.length - 1) {
                    this.$emit('update:actionLabel', 'finish')
                }
            },
        },
        beforeMount() {
            this.$emit('update:actionLabel', 'next')
            this.setTable()
        },
    }
</script>
