<style lang="scss" scoped>
.control-label {
    font-size: 0.675rem;
}

.scale-reduce {
    transform: scale(0.725);
    transform-origin: 50% 50%;
}

.context-dropdown {
    width: 328px
}

.logType-dropdown,
.date-range-dropdown {
    width: 220px
}

.downloadBtn {
    margin: 0% 5% 0% 5%;
}
</style>

<template>
    <div
        ref="container"
        v-resize="setBodyHeight"
        class="fill-height d-flex flex-column flex-grow-1">
        <div
            ref="toolbar"
            class="d-flex flex-column">
            <div class="d-flex align-end justify-space-between mb-2">
                <div class="d-flex">
                    <div class="context-dropdown">
                        <label class="d-block color text-small-text control-label">
                            {{ $t("context") }}
                        </label>
                        <SelectServers
                            :filter="servers"
                            :preselected="preselected"
                            :auto-select-all="false"
                            @change="serverContext = $event" />
                    </div>
                    <div class="logType-dropdown ml-2">
                        <label class="d-block color text-small-text control-label">
                            {{ $t('logType') }}
                        </label>
                        <div>
                            <v-select
                                :value="selectedLogTypes"
                                multiple
                                :items="logTypes"
                                return-object
                                item-text="txt"
                                item-value="val"
                                class="std std--dropdown"
                                :placeholder="$t('allLogs')"
                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                                height="36"
                                outlined
                                attach
                                single-line
                                hide-details>
                                <template #prepend-item>
                                    <v-list-item
                                        class="list-item context-list-item d-flex align-center pl-2"
                                        @click="toggleLogTypes">
                                        <v-checkbox
                                            class="scale-reduce"
                                            :input-value="areAllLogTypesSelected"
                                            primary />
                                        {{ $t('allLogs') }}
                                    </v-list-item>
                                </template>
                                <template #item="{ item }">
                                    <v-list-item
                                        class="list-item context-list-item d-flex align-center pl-2"
                                        @click="item.selected = !item.selected">
                                        <v-checkbox
                                            class="scale-reduce"
                                            :input-value="item.selected"
                                            primary />
                                        {{ item.txt }}
                                    </v-list-item>
                                </template>

                                <template #selection="{ item, index }">
                                    <span v-if="index === 0 && areAllLogTypesSelected">{{ $t('allLogs') }}</span>
                                    <div
                                        v-else-if="index === 0"
                                        class="v-select__selection v-select__selection--comma">
                                        {{ item.txt }}
                                        <span
                                            v-if="selectedLogTypes.length > 1"
                                            class="grey--text text--lighten2">
                                            ({{ $t('plusMore', [selectedLogTypes.length - 1]) }})
                                        </span>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="date-range-dropdown ml-2">
                        <label class="d-block color text-small-text control-label">
                            {{ $t("timeFrame") }} {{ $t('utc') }}
                        </label>
                        <date-range-picker
                            ref="dateRangePicker"
                            :has-custom-range="true"
                            @change="dateRange = $event" />
                    </div>
                </div>
                <div>
                    <v-btn
                        class="mx-2 text-capitalize"
                        color="primary"
                        rounded
                        depressed
                        :disabled="selectedLogs.length !== 1"
                        @click="viewLog">
                        {{ $t('view') }}
                    </v-btn>
                    <v-menu
                        v-model="downloadMenu"
                        :close-on-content-click="false"
                        :nudge-width="100"
                        :min-width="300"
                        offset-y>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                :loading="isDownloading"
                                v-bind="attrs"
                                :disabled="selectedLogs.length == 0"
                                depressed
                                rounded
                                v-on="on">
                                <span class="text-none"> {{ $t('downloadN', [selectedLogs.length]) }}</span>
                            </v-btn>
                        </template>
                        <v-card background-color="white">
                            <v-list>
                                <v-list-item-content>
                                    <v-list-item-title class="text-center">
                                        {{ $t('selectLogFormat') }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list>
                            <v-divider />
                            <v-list-item>
                                <v-list-item-action>
                                    <v-switch
                                        v-model="downloadFileTypes.enableJson"
                                        color="primary" />
                                </v-list-item-action>
                                <v-list-item-title>{{ $t('logFormat.json') }}</v-list-item-title>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-action>
                                    <v-switch
                                        v-model="downloadFileTypes.enableRaw"
                                        color="primary" />
                                </v-list-item-action>
                                <v-list-item-title>{{ $t('logFormat.raw') }}</v-list-item-title>
                            </v-list-item>
                            <v-card-actions background-color="white">
                                <v-spacer />
                                <v-btn
                                    class="ml-2 float-right downloadBtn"
                                    color="primary"
                                    text
                                    rounded
                                    @click="cancelSelected">
                                    <span class="text-nonel">{{ $t('cancel') }}</span>
                                </v-btn>
                                <v-btn
                                    class="ml-2 float-right downloadBtn"
                                    color="primary"
                                    :disabled="!downloadFileTypes.enableRaw && !downloadFileTypes.enableJson"
                                    rounded
                                    depressed
                                    @click="downloadSelected">
                                    <span class="text-none">{{
                                        $t('downloadN', [
                                            selectedLogs.length * (+downloadFileTypes.enableRaw + +downloadFileTypes.enableJson)
                                        ])
                                    }}</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </div>
            </div>
        </div>

        <div
            class="d-flex flex-grow-1"
            :style="{ height: `${tableWrapperHeight}px` }">
            <LogsTable
                v-model="selectedLogs"
                class="d-flex flex-column flex-grow-1"
                :fixed-header="true"
                :data="logsData"
                :options.sync="options"
                :loading="isLoading"
                :show-select="true"
                @table-height="tableHeight = $event" />
        </div>
        <LogViewer
            v-if="showLogViewer"
            :title="logViewerTitle"
            :loading="readingLog"
            :height="logViewerHeight"
            :text="logContent" />
    </div>
</template>

<script>
    import LogsTable from 'components/logs/LogsTable.vue'
    import LogViewer from 'components/logs/LogViewer.vue'
    import SelectServers from 'components/common/SelectServers'
    import observability from 'api/observability'
    import { getLogContents } from 'services/observability.js'
    import to from 'await-to-js'
    export default {
        name: 'LogsWithViewer',
        components: {
            SelectServers,
            LogsTable,
            LogViewer,
        },
        props: {
            servers: { type: Array, required: true, },
        },
        data() {
            return {
                isLoading: false,
                pollingInterval: null,
                isDownloading: false,
                options: {},
                serverContext: [],
                preselected: [],
                logTypes: [],
                dateRange: { from: 0, to: 0, },
                logsData: {},
                selectedLogs: [],
                downloadMenu: false,
                downloadFileTypes: JSON.parse(localStorage.getItem('downloadFileTypes')) || { enableJson: true, enableRaw: false, },
                showLogViewer: false,
                readingLog: false,
                logContent: '',
                bodyHeight: 0,
                tableHeight: 0,
                initParams: true,
            }
        },
        computed: {
            selectedLogTypes() {
                return this.logTypes.filter(type => type.selected)
            },
            areAllLogTypesSelected() {
                return this.logTypes.length === this.selectedLogTypes.length
            },
            enableRaw() {
                return this.downloadFileTypes.enableRaw ? 'raw' : 'json'
            },
            queryParams() {
                if (this.initParams) return null
                const { sortDesc, page, itemsPerPage, } = this.options
                return {
                    limit: itemsPerPage > 0 ? itemsPerPage : -1,
                    offset: itemsPerPage > 0 ? (page - 1) * itemsPerPage : 0,
                    fromDate: new Date(this.dateRange.from * 1000).toISOString(),
                    toDate: new Date(this.dateRange.to * 1000).toISOString(),
                    serverContext: this.serverContext,
                    orderByField: 'startTime',
                    orderByDirection: sortDesc && sortDesc[0] ? 'desc' : 'asc',
                    logType: this.selectedLogTypes.map(type => type.val),
                }
            },
            firstSelectedLog() {
                return this.$typy(this.selectedLogs, '[0]').safeObject
            },
            logViewerTitle() {
                return this.$t(`logTypes.${this.firstSelectedLog.type}`)
            },
            tableMaxHeight() {
                return this.showLogViewer ? this.bodyHeight / 2 : this.bodyHeight
            },
            tableWrapperHeight() {
                return this.tableHeight < this.tableMaxHeight ? this.tableHeight : this.tableMaxHeight
            },
            logViewerHeight() {
                return this.tableHeight < this.tableMaxHeight ? this.bodyHeight - this.tableHeight : this.tableMaxHeight
            },
        },
        watch: {
            downloadMenu: {
                handler(val) {
                    if (val) {
                        this.downloadFileTypes =
                            JSON.parse(localStorage.getItem('downloadFileTypes')) ||
                            { enableJson: true, enableRaw: false, }
                    }
                },
            },
            firstSelectedLog(v) {
                if (!v) this.showLogViewer = false
            },
            servers: {
                deep: true,
                handler() {
                    this.setServerContext()
                },
            },
        },
        methods: {
            watchQueryParams() {
                this.unwatchQueryParams = this.$watch(
                    'queryParams',
                    async (v) => {
                        if (v) {
                            this.selectedLogs = []
                            await this.fetchLogs()
                        }
                    },
                    { deep: true, }
                )
            },
            startPollingLogs(interval) {
                this.pollingInterval = setInterval(() => {
                    this.fetchLogs()
                }, interval)
            },
            stopPollingLogs() {
                clearInterval(this.pollingInterval)
            },
            setBodyHeight() {
                const { height: containerHeight, } = this.$refs.container.getBoundingClientRect()
                const { height: toolbarHeight, } = this.$refs.toolbar.getBoundingClientRect()
                this.bodyHeight = containerHeight - toolbarHeight
            },
            setParams() {
                this.setServerContext()
                this.initParams = false
            },
            setServerContext() {
                this.preselected = this.servers.map(item => item.serverDataSourceId)
                this.serverContext = this.preselected
            },
            async fetchLogTypes() {
                const [error, response] = await to(observability.get('logs/types'))
                if (error) {
                    this.$store.commit('showMessage', {
                        text: this.$t('errors.fetchLogTypes'),
                        type: 'error',
                    })
                } else {
                    this.logTypes = response?.data?.logTypes?.map(({ name, logType, }) =>
                        ({ txt: name, val: logType, selected: true, }))
                }
            },
            async fetchLogs() {
                if (!this.serverContext.length) {
                    this.logsData = {}
                    return
                }
                this.isLoading = true
                const [error, response] = await to(observability.post('logs/query', this.queryParams))
                if (error) {
                    this.$store.commit('showMessage', {
                        text: this.$t('errors.failedToRetrieveLogs'),
                        type: 'error',
                    })
                } else {
                    this.logsData = response?.data
                }
                this.isLoading = false
            },
            async cancelSelected() {
                try {
                    this.downloadMenu = false
                    this.downloadFileTypes = JSON.parse(localStorage.getItem('downloadFileTypes'))
                } catch (err) {
                    this.$store.commit('showMessage', {
                        text: this.$t('errors.unknown'),
                        type: 'error',
                    })
                }
            },
            async downloadSelected() {
                this.downloadMenu = false
                localStorage.setItem('downloadFileTypes', JSON.stringify(this.downloadFileTypes))
                if (!this.selectedLogs.length) return

                this.isDownloading = true

                const logIds = this.selectedLogs.reduce((acc, log) => `${acc}${log.id},`, '')
                const params = {
                    logIds: logIds.slice(0, -1),
                    logFormat:
                        (
                            this.downloadFileTypes &&
                            this.downloadFileTypes.enableRaw &&
                            this.downloadFileTypes.enableJson
                        ) ? ['raw', 'json'].toString() : this.enableRaw,
                }


                const [error, response] = await to(observability.get('logs/archive', params, {
                    responseType: 'blob',
                }))
                if (error) {
                    this.$store.commit('showMessage', {
                        text: this.$t('errors.failedToDownloadLogs'),
                        type: 'error',
                    })
                } else {
                    this.logsData = response?.data
                }

                const filename = response.headers['content-disposition'] ?
                    response.headers['content-disposition'].split('filename=')[1] : 'logs.zip'
                this.$help.downloadURL(response, filename)
                this.isDownloading = false
            },
            toggleLogTypes() {
                const state = !this.areAllLogTypesSelected
                this.logTypes.forEach(log => (log.selected = state))
            },
            checkLocalTimeDiff() {
                const time = this.$moment(new Date())
                const daysDiff = time.days() - time.utc().days()
                if (daysDiff === 0) return

                this.$store.commit('showMessage', {
                    text: this.$t(`info.${daysDiff < 0 ? 'behindOfUTC' : 'aheadOfUTC'}`, [time.format('DD MMM YYYY (UTC Z)')]),
                    type: 'info',
                    timeout: 10,
                })
            },
            async viewLog() {
                this.showLogViewer = true
                this.readingLog = true
                this.logContent = ''
                try {
                    const logId = this.firstSelectedLog.id
                    const logContents = await getLogContents([logId])
                    this.logContent = this.$typy(logContents, `[${logId}]`).safeString
                }
                catch (_) {
                    this.$store.commit('showMessage', {
                        text: this.$t('errors.failedToRetrieveLogs'),
                        type: 'error',
                    })
                }
                this.readingLog = false
            },
        },
        async created() {
            this.checkLocalTimeDiff()
            await this.fetchLogTypes()
            this.setParams()
            await this.fetchLogs()
            this.watchQueryParams()
            this.startPollingLogs(this.$config.logsFetchInterval * 1000)
        },
        beforeDestroy() {
            this.$typy(this.unwatchQueryParams).safeFunction()
            this.stopPollingLogs()
        },
    }
</script>
