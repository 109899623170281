<template>
    <div>
        <v-alert
            outlined
            type="info"
            text>
            {{ $t('info.copilotAgentCreationInfo') }}
        </v-alert>
    </div>
</template>

<script>
    export default {
        name: 'CopilotAgentModalInfo',
        mounted() {
            this.$emit('update:isValid', true)
        },
    }
</script>
